<template>
  <div class="top-bar-page activity-page gift-page">
    <top-bar :title="i18n.t('activity.title')" class="black" parentId=".activity-page" right-icon="help" @back="back"></top-bar>

    <div class="gift-main">
      <div class="top-banner">
        
        <div class="flex-center-between">
          <div class="info">
            <div >{{i18n.t('activity.desc1')}}</div>
            <div class="normal">{{i18n.t('activity.desc2')}}</div>
          </div>
          <div class="icon">
            <img :src="activityUrl" alt="">
          </div>
        </div>
      </div>

      <div class="gift-list">
        <div class="list" v-for="(item, i) in activityList" :key="i">
          <div class="title flex-center-start clear">
            <span>{{i18n.t('activity.label1')}} {{item.tradeMax}} USDT {{i18n.t('activity.label2')}}</span>
            <span class="yellow">{{item.money}} USDT</span>
          </div>
          <div class="flex-start-between gift-detail clear">
            <div >
              <div class="percent-info">{{i18n.t('activity.label3')}}</div>
              <div class="percent-line flex-center">
                <div class="percent-bg" :style="`width: ${item.trade / item.tradeMax * 100}%;`"></div>
                <div class="label">{{item.trade}}/{{item.tradeMax}} USDT</div>
              </div>
            </div>
            <div>
              <Button :class="item.isGet ? 'black' : ''" :disabled="item.trade < item.tradeMax">{{item.isGet ?  i18n.t('activity.label4') : i18n.t('activity.label5')}}</Button>
            </div>
          </div>
        </div>

        </div>
      </div>
  </div>
</template>
<script>
import topBar from '@/components/topBar'
import { Button } from 'vant'
export default {
  components: {
    Button,
    topBar
  },
  data() {
    return {
      noticeList: [],
      activityUrl:require('../../assets/imgs/icon-activity2.png'),
      showMore: false,
      activityList: [
        {
          money: 20,
          tradeMax: 500,
          trade: 500,
          isGet: true
        },
        {
          money: 188,
          tradeMax: 5000,
          trade: 5000
        },
        {
          money: 188,
          tradeMax: 5000,
          trade: 2000
        },
        {
          money: 188,
          tradeMax: 5000,
          trade: 0
        },
      ]
    }
  },
  mounted() {
  },
  methods: {
    init() {
    
    },
    back() {
      this.$router.go(-1)
    },
  }
}
</script>