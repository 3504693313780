<template>
	<div class="recharge-page  top-bar-page transfer-page">
		<top-bar :title="i18n.t('transfer.title')" class="black" parentId=".transfer-page" right-icon="log" @back="back"></top-bar>

		<div class="recharge-main">

			<div class="flex-center-between transfer-box" @click="changeFromAccount">
				<div>
					<span class="label">{{i18n.t('transfer.label1')}}</span>
					<span class="name">{{fromAccount.name }}</span>
				</div>
				<div>
					<img :src="downUrl" alt="">
				</div>
			</div>
			<div class="flex-center-between transfer-box" @click="changeAccount">
				<div>
					<span class="label">{{i18n.t('transfer.label2')}}</span>
					<span class="name">{{toAccount.name }}</span>
				</div>
				<div>
					<img :src="downUrl" alt="">
				</div>
			</div>

			<!-- <div class="form-label">{{i18n.t('transfer.label3')}}</div>

			<div class="recharge-list">
				<div class="list flex-center" @click="chooseRecharge">
					<div class="icon">
						<img :src="`/upload/${bitInfo.Logo}`" alt="">
					</div>
					<div class="name flex-1">
						<span>{{bitInfo.Name}}</span>
						<span class="desc">{{bitInfo.desc}}</span>
					</div>
					<div class="check">
						<img :src="rightUrl" alt="">
					</div>
				</div>
			</div> -->

			<div class="form-label">{{i18n.t('transfer.label4')}}</div>

			<div class="form-input flex-center-between">
				<input type="number" inputmode="decimal" v-model="form.money" :placeholder="i18n.t('transfer.labelPlaceholder')">
				<div class="flex-center adot">
					<span class="btn" @click="setAll">{{i18n.t('transfer.label5')}}</span>
					<span>USDT</span>
				</div>
			</div>

			<div class="flex-center-between desc-line">
				<div>{{i18n.t('transfer.label6')}}</div>
				<div class="count">{{parseFloat(userMoney).toFixed(4)}} USDT</div>
			</div>

		

			<div class="bottom-btn flex-center">
				<Button  class="big" @click="submit">{{i18n.t('transfer.btn')}}</Button>
			</div>
		</div>


     <!--币种-->
    <Popup v-model:show="showRecharge" position="bottom" lock-scroll class="rate-popup">
			<div class="rate-modal">
				<div class="title flex-center-between">
					<div>{{i18n.t('transfer.label7')}}</div>
					<img :src="closeUrl" alt="" @click="cancel">
				</div>
				<div class="recharge-list more">
          <div class="list flex-center" v-for="(item, i) in bitList" :key="i" @click="changeRecharge(i)">
            <div class="icon">
              <img :src="`/upload/${item.Logo}`" alt="">
            </div>
            <div class="name flex-1">
              <span>{{item.Name}}</span>
              <span class="desc">{{item.desc}}</span>
            </div>
            <div class="check">
              <img :src="bitIndex == i ? radioUrl2 : radioUrl" alt="">
            </div>
          </div>
				</div>
				<div class="modal-btn ">
					<Button :disabled="bitIndex === -1" @click="submitChange">{{i18n.t('transfer.btn')}}</Button>
				</div>

			</div>
		</Popup>


		<!--选择账户 到-->
		<Popup v-model:show="showToAccount" position="bottom" lock-scroll class="rate-popup">
			<div class="rate-modal">
				<div class="title flex-center-between">
					<div>{{i18n.t('transfer.label8')}}</div>
					<img :src="closeUrl" alt="" @click="cancel">
				</div>
				<div class="recharge-list ">
          <div class="list flex-center-between" :class="toAccount.name == item.name ? 'active' : ''" v-for="(item, i) in accountList" :key="i" @click="chooseAccount(item)">
						<div>{{item.name}}</div>
						<div v-if="toAccount.name == item.name">
							<img :src="checkUrl" alt="">
						</div>
					</div>
				</div>

			</div>
		</Popup>

		<!--选择账户 从-->
		<Popup v-model:show="showFromAccount" position="bottom" lock-scroll class="rate-popup">
			<div class="rate-modal">
				<div class="title flex-center-between">
					<div>{{i18n.t('transfer.label8')}}</div>
					<img :src="closeUrl" alt="" @click="cancel">
				</div>
				<div class="recharge-list ">
          <div class="list big flex-center-between" :class="fromAccount.name == item.name ? 'active' : ''" v-for="(item, i) in accountList" :key="i" @click="chooseFromAccount(item, i)">
						<div>
							<div>{{item.name}}</div>
							<div class="mt-5">{{i18n.t('transfer.label6')}} {{item.count}} USDT</div>
						</div>
						<div v-if="fromAccount.name == item.name">
							<img :src="checkUrl" alt="">
						</div>
					</div>
				</div>

			</div>
		</Popup>


	</div>
</template>
<script>
import topBar from '@/components/topBar'
import { Popup, Button } from 'vant'
import {formatNumber} from '@/utils/common'
import { getCoinList } from '@/api/index'
import { wallet, transfer } from '@/api/assest'
export default {
	components: {
    Popup,
    Button,
		topBar
  },
  data() {
		return {
			form: {
				money: ''
			},
			userMoney: 0,
			closeUrl: require('../../assets/imgs/icon-close.png'),
			backUrl: require('../../assets/imgs/icon-back.png'),
			rightUrl: require('../../assets/imgs/icon-arrow-right.png'),
			downUrl: require('../../assets/imgs/icon-arrow-down.png'),
			logUrl: require('../../assets/imgs/icon-log.png'),
			radioUrl: require('../../assets/imgs/icon-radio.png'),
      radioUrl2: require('../../assets/imgs/icon-radio2.png'),
			checkUrl: require('../../assets/imgs/icon-checked2.png'),
			showRecharge: false,
      bitIndex: 0,
			bitInfo: {},
			accountList: [
				{
					name: this.i18n.t('transfer.account1'),
					key: 'Capital',
					count: 0
				},
				{
					name: this.i18n.t('transfer.account2'),
					key: 'Spot',
					count: 0
				},
				{
					name: this.i18n.t('transfer.account3'),
					key: 'Contract',
					count: 0
				},
				{
					name: this.i18n.t('transfer.account4'),
					key: 'Option',
					count: 0
				},
				{
					name: this.i18n.t('transfer.account5'),
					key: 'Finance',
					count: 0
				},
			],
			fromAccount: {},
			fromIndex: 0,
			toAccount: {},
			showToAccount: false,
			showFromAccount: false,
		}
	},
	computed: {
		bitList() {
      return this.$store.state.bitList
    }
	},
  mounted() {
		this.fromAccount = this.accountList[0]
		this.toAccount = this.accountList[1]
		this.init()
  },
	methods: {
		init() {
			if (this.bitList.length == 0) {
        getCoinList().then(res => {
          let bitList = res.data.Items
          this.$store.state.bitList = [].concat(bitList)
					this.bitInfo =  bitList[0]
					this.getWallet()
        })
      } else {
				this.bitInfo = this.bitList[0]
				this.getWallet()
			}
    },
		getWallet() {
			let form = new FormData()
      form.append('name', "usdt")
      form.append('current', 1)
      form.append('pageSize', 10)
      wallet(form).then(res => {
				let data = res.data.Items[0]
				if (data) {
					this.accountList[0].count = formatNumber(data.CapitalUse)
					this.accountList[1].count = formatNumber(data.SpotUse)
					this.accountList[2].count = formatNumber(data.ContractUse)
					this.accountList[3].count = formatNumber(data.OptionUse)
					this.accountList[4].count = formatNumber(data.FinanceUse)

					this.userMoney = parseFloat(this.accountList[this.fromIndex].count)
				}
      })
		},
		back() {
			this.$router.go(-1)
		},
		toLog() {
			this.$router.push({
				name: 'assestLog',
				query: {
					type: 'transfer'
				}
			})
		},
		chooseRecharge() {
      this.showRecharge = true
    },
    changeRecharge(i) {
      this.bitIndex = i
    },
		cancel() {
			this.showRecharge = false
			this.showFromAccount = false
			this.showToAccount = false
		},
		submitChange() {
			this.bitInfo = this.bitList[this.bitIndex]
			this.showRecharge = false
			//查询金额
			this.getWallet()
		},
		changeFromAccount() {
			this.showFromAccount = true
		},
		changeAccount() {
			this.showToAccount = true
		},
		chooseAccount(data) {
			this.toAccount = data
			setTimeout(() => {
				this.showToAccount = false
			}, 100)
		},
		chooseFromAccount(data, i) {
			this.fromAccount = data
			this.fromIndex = i
			this.userMoney = parseFloat(this.accountList[i].count)
			setTimeout(() => {
				this.showFromAccount = false
			}, 100)
		},
		setAll() {
			this.form.money = this.userMoney
		},
		submit() {
			if (!this.form.money) {
				this.$store.state.warningMsg = this.i18n.t('transfer.labelPlaceholder')
				return
			}

			let form = new FormData()
			form.append('From', this.fromAccount.key)
			form.append('To', this.toAccount.key)
			form.append('Amount', this.form.money)
			form.append('CurrencyId', this.bitInfo.ID)
			transfer(form).then(res => {
				if (res.code == 0) {
					this.$store.state.successMsg = this.i18n.t('transfer.success')
					this.form.money = ''
					this.getWallet()
				} else {
					this.$store.state.errorMsg = res.msg
				}
			})
		}
	}
}
</script>