<template>
  <div class="assest-page">
    <div class="title fixed">{{i18n.t('assest.title')}}</div>

    <div class="statistics-box">
      <div class="flex-center-start label">
        <span>{{i18n.t('assest.label1')}}</span>
        <img :src="showMoney ? eyeUrl2 : eyeUrl" alt="" @click="changeMoneyType">
      </div>

      <div class="flex-end-start money-info" >
        <div>{{moneyStr}}</div>
        <!-- <div class="flex-center money-type"> -->
        <div class="flex-center money-type" @click="changeBit">
          <span>{{bitName}}</span>
          <img :src="downUrl" alt="">
        </div>
      </div>

      <div class="desc">≈ {{showMoney ? parseFloat(bitMoney).toFixed(4) : '****'}} BTC</div>
    </div>

    <div class="tab-list flex-center">
      <!-- <div class="tab flex-column-center" @click="toPath('recharge')"> -->
      <div class="tab flex-column-center" @click="toRecharge">
        <img :src="rechargeUrl" alt="">
        <span>{{i18n.t('assest.label2')}}</span>
      </div>
      <div class="tab flex-column-center" @click="toWithdraw">
        <img :src="withdrawUrl" alt="">
        <span>{{i18n.t('assest.label3')}}</span>
      </div>
      <div class="tab flex-column-center" @click="toPath('transfer')">
        <img :src="transferUrl" alt="">
        <span>{{i18n.t('assest.label4')}}</span>
      </div>
    </div>

    <div class="sub-title">{{i18n.t('assest.label5')}}</div>

    <div class="menu-list">
      <div class="menu flex-center-between" v-for="(item, i) in accountList" :key="i" @click="toAccount(item)">
        <div>
          <div class="label">{{item.name}}</div>
          <div class="count">
            <span class="big">{{item.count}}</span>
            <span>{{bitName}}</span>
          </div>
        </div>
        <div>
          <img :src="rightUrl" alt="">
        </div>
      </div>
    </div>

    <bitPage v-if="showBitPage" @back="cancel" :activeName="bitName" @change="changeBitName"></bitPage>

    <!--充值类型-->
    <Popup v-model:show="showRecharge" position="bottom" lock-scroll class="rate-popup">
			<div class="rate-modal">
				<div class="title flex-center-between">
					<div>{{i18n.t('recharge.label5')}}</div>
          <img :src="closeUrl" alt="" @click="cancel">
				</div>
				<div class="recharge-list ">
          <div class="list flex-center" v-for="(item, i) in rechargeList" :key="i" @click="changeRecharge(item, i)">
            <div class="icon">
              <img :src="item.icon" alt="">
            </div>
            <div class="name flex-1">
              <span>{{item.name}}</span>
              <span class="desc">{{item.desc}}</span>
            </div>
            <div class="check">
              <img :src="rechargeIndex == i ? radioUrl2 : radioUrl" alt="">
            </div>
          </div>
          <div class="btn">
            <Button :disabled="rechargeIndex === -1" @click="toRechargePage">{{i18n.t('recharge.btn4')}}</Button>
          </div>
				</div>

			</div>
		</Popup>

    <Popup v-model:show="showEnsureModal" position="center" lock-scroll class="ensure-popup">
			<div class="ensure-modal flex-column-center">
				<div class="icon">
					<img :src="warningUrl" alt="">
				</div>
				<div class="title">{{i18n.t('setting.desc7')}}</div>
				<div class="flex-center">
					<Button class="gray" @click="cancel">{{i18n.t('setting.btn2')}}</Button>
					<Button @click="ensureAuth">{{i18n.t('setting.btn')}}</Button>
				</div>
			</div>
		</Popup>

    <Popup v-model:show="showModal" position="bottom" lock-scroll class="rate-popup">
			<div class="rate-modal">
				<div class="title flex-center-between clear" >
					<div>{{i18n.t('setting.label6')}}</div>
          <img :src="closeUrl" alt="" @click="cancel">
				</div>

        <div class="auth-list">
          <div class="auth-title">{{i18n.t('setting.label7')}}</div>
          <div class="flex-center-between">
            <div class="auth" @click="changeAuth(1)">
              <div class="bg flex-center">
                <img :src="cardUrl" alt="">
              </div>
              <div class="label" :class="authIndex == 1 ? 'active' : ''">
                <span>{{i18n.t('setting.label8')}}</span>
                <span class="" v-show="authIndex == 1">({{i18n.t('setting.label10')}})</span>
              </div>
            </div>
            <div class="auth" @click="changeAuth(2)">
              <div class="bg flex-center">
                <img :src="hzUrl" alt="">
              </div>
              <div class="label" :class="authIndex == 2 ? 'active' : ''">
                <span>{{i18n.t('setting.label9')}}</span>
                <span class="" v-show="authIndex == 2">({{i18n.t('setting.label10')}})</span>
              </div>
            </div>
          </div>
          <Button  @click="toAuth">{{i18n.t('setting.btn')}}</Button>
        </div>

			</div>
		</Popup>
  </div>
</template>
<script>
import { Popup, Button } from 'vant'
import bitPage from './bit'
import { checkAuth } from '@/api/setting'
import { myAssets } from '@/api/assest'
import { userInfo } from '@/api/user'
import { setAssets } from '@/utils/auth'
export default {
  components: {
    bitPage,
    Popup,
    Button
  },
  data() {
    return {
      closeUrl: require('../../assets/imgs/icon-close.png'),
      eyeUrl: require('../../assets/imgs/icon-eye.png'),
      eyeUrl2: require('../../assets/imgs/icon-eye2.png'),
      downUrl: require('../../assets/imgs/icon-arrow-down.png'),
      rightUrl: require('../../assets/imgs/icon-arrow-right.png'),
      rechargeUrl: require('../../assets/imgs/icon-recharge2.png'),
      withdrawUrl: require('../../assets/imgs/icon-withdraw.png'),
      transferUrl: require('../../assets/imgs/icon-transfer3.png'),
      radioUrl: require('../../assets/imgs/icon-radio.png'),
      radioUrl2: require('../../assets/imgs/icon-radio2.png'),
      rechargeIcon: require('../../assets/imgs/bit1.png'),
      rechargeIcon2: require('../../assets/imgs/bit2.png'),
      rechargeIcon3: require('../../assets/imgs/bit3.png'),
      warningUrl: require('../../assets/imgs/icon-warning2.png'),
      cardUrl: require('../../assets/imgs/auth-card.png'),
      hzUrl: require('../../assets/imgs/auth-hz.png'),
      showMoney: true,
      userMoney: '0.00',
      bitMoney: '0.0000',
      bitName: 'USDT',
      bitId: '1',
      showBitPage: false,
      showRecharge: false,
      showEnsureModal: false,
      showModal: false,
      rechargeIndex: -1,
      authIndex: 1,
      rechargeName: '',
      assetsData: {},
      realNameStatus: '',
      rechargeList: [
        {
					icon: require('../../assets/imgs/bit1.png'),
					name: 'TRC20',
					desc: 'Tether USDT',
					key: 'USDT-TRC20'
				},
				{
					icon: require('../../assets/imgs/bit1.png'),
					name: 'ERC20',
					desc: 'Tether USDT',
					key: 'USDT-ERC20'
				},
				{
					icon: require('../../assets/imgs/bit2.png'),
					name: 'ETH',
					desc: 'Tether USDT',
					key: 'Ethereum'
				},
				{
					icon: require('../../assets/imgs/bit3.png'),
					name: 'BTC',
					key: 'Bitcoin'
				},
				{
					icon: require('../../assets/imgs/bit4.png'),
					name: 'Bank',
					key: 'bank'
				},
      ],
      accountList: [
				{
					name: this.i18n.t('assest.tab1'),
          type: 5,
					count: 0,
				},
				{
					name: this.i18n.t('assest.tab2'),
          type: 1,
					count: 0,
				},
				{
					name: this.i18n.t('assest.tab3'),
          type: 2,
					count: 0
				},
				{
					name: this.i18n.t('assest.tab4'),
          type: 3,
					count: 0
				},
				{
					name: this.i18n.t('assest.tab5'),
          type: 4,
					count: 0
				},
			],
    }
  },
  computed: {
    moneyStr() {
      return this.showMoney ? this.userMoney : '****'
    }
  },
  mounted() {
   

    // let bitName = localStorage.getItem('bitName')
    // if (bitName) {
    //   this.bitName = bitName
    //   this.bitId = localStorage.getItem('bitId')
    // }
    this.init()
    this.initAssets()
  },
  methods: {
    initAssets() {
      myAssets({
        Unit: this.bitName
      }).then(res => {
        let data = res.data
        this.userMoney = this.resetMoney(data.Total)
        this.bitMoney = data.Btc ? data.Btc : '0.00'
        this.accountList[0].count = this.resetMoney(data.Capital)
        this.accountList[1].count = this.resetMoney(data.Spot)
        this.accountList[2].count = this.resetMoney(data.Contract)
        this.accountList[3].count = this.resetMoney(data.Option)
        this.accountList[4].count = this.resetMoney(data.Finance)
      })
    },
    resetMoney(val) {
        return parseFloat(val).toFixed(4)
      if (val) {
        let value = parseFloat(parseFloat(val).toFixed(4)) + ''
        let size = value.length - value.indexOf('.') - 1
        if (size > 2) {
          return parseFloat(val).toFixed(4)
        } else {
          return parseFloat(val).toFixed(2)
        }
      } else {
        return '0.00'
      }
    },
    init() {
      
      userInfo().then(res => {
        setAssets(res.data.Assets)
      })

      checkAuth().then(res => {
        this.realNameStatus = res.data.status
      })
    },
    changeMoneyType() {
      this.showMoney = !this.showMoney
    },
    changeBit() {
      this.showBitPage = true
    },
    cancel() {
      this.showBitPage = false
      this.showRecharge = false
      this.showEnsureModal = false
      this.showModal = false
    },
    changeBitName(data) {
      this.bitName = data.name
      this.bitId = data.key
      localStorage.setItem('bitName', this.bitName)
      localStorage.setItem('bitId', this.bitId)
      this.showBitPage = false
      this.accountList.forEach(v => {
        v.count = '0.00'
      })
      // this.userMoney = '0.00'
      this.initAssets()
    },
    toPath(path) {
      if (path) {
        this.$router.push({
          name: path
        })
      }
    },
    toWithdraw() {
      if (this.realNameStatus == '2') {
        this.toPath('withdraw')
      } else if (this.realNameStatus == '1') {
        this.$store.state.warningMsg = this.i18n.t('setting.desc8')
      } else {
        this.showEnsureModal = true
      }
    },
    toRecharge() {
      this.showRecharge = true
    },
    changeRecharge(data, i) {
      this.rechargeIndex = i
      this.rechargeName = data.name
    },
    toRechargePage() {
      if (this.rechargeName == 'Bank') {
        this.$router.push({
          name: 'kefu'
        })
      } else {
        this.$router.push({
          path: '/recharge?type=' + this.rechargeName
        })
      }
    },
    toAccount(data) {
      this.$router.push({
        path: '/account',
        query: {
          type: data.type,
        }
      })
    },
    changeAuth(i) {
      this.authIndex = i
    },
    toAuth() {
      this.$router.push({
        name: 'userAuth',
        query: {
          type: this.authIndex
        }
      })
    },
    ensureAuth() {
      this.showEnsureModal = false
      this.showModal = true
    },
  }
}
</script>