import fetch from "../utils/fetch";

// 全局配置
export function configList(data) {
  return fetch({
    url: '/api/user/config',
    method: 'post',
    data: data
  });
}
// 用户信息
export function userInfo(data) {
  return fetch({
    url: '/api/user/info',
    method: 'post',
    data: data
  });
}

// LoginAddress
export function LoginAddress(data) {
    return fetch({
        url: '/api/user/login-address',
        method: 'post',
        data: data
    });
}

// 退出登录
export function logout(data) {
  return fetch({
    url: '/api/mobile/user/logout',
    method: 'delete',
  });
}

// WelfareCenterList
export function WelfareCenterList(data) {
    return fetch({
        url: '/api/user/WelfareCenter-list',
        method: 'post',
        data: data
    });
}

// WelfareCenterReceiveAReward
export function WelfareCenterReceiveAReward(data) {
    return fetch({
        url: '/api/user/WelfareCenter-ReceiveAReward',
        method: 'post',
        data: data
    });
}


// TodayRecharge
export function TodayRecharge(data) {
    return fetch({
        url: '/api/user/today-recharge',
        method: 'post',
        data: data
    });
}