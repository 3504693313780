<template>
	<div class="setting-page">
		<div class="back" @click="back">
			<img :src="backUrl" alt="">
		</div>
		<div class="title">
			{{i18n.t('tradeSetting.title')}}
		</div>

		<div class="sub-title"> 
			{{i18n.t('tradeSetting.label1')}}
		</div>
		<div class="setting-line flex-center-between">
			<div>
				<div>{{i18n.t('tradeSetting.label2')}}</div>
				<div class="desc">({{i18n.t('tradeSetting.label3')}})</div>
			</div>
			<div>
				<van-switch v-model="checked1" active-color="#F7A600" @change="changeOrder" inactive-color="#ADB1B8"></van-switch>
			</div>
		</div>
		<div class="setting-line flex-center-between">
			<div>
				{{i18n.t('tradeSetting.label4')}}
			</div>
			<div>
				<van-switch v-model="checked2" active-color="#F7A600" @change="changeCancel" inactive-color="#ADB1B8"></van-switch>
			</div>
		</div>
	</div>
</template>
<script>
import { Switch } from 'vant'
import { confirmSetting } from '@/api/setting'
import { userInfo } from '@/api/user'
import Vue from 'vue'
Vue.use(Switch)
export default {
	components: {
		// Switch 
	},
  data() {
		return {
			backUrl: require('../../assets/imgs/icon-back.png'),
			checked1: false,
			checked2: false,
		}
	},
	mounted() {
		// this.checked1 = localStorage.getItem('isOrderEnsure') === 'true'
		// this.checked2 = localStorage.getItem('isCancelOrderEnsure') === 'true'
		this.initConfirm()
	},
	methods: {
		back() {
			this.$emit('back')
		},
		initConfirm() {
			userInfo().then(res => {
				this.checked1 = res.data.OrderConfirm == 1 ? true : false
				this.checked2 = res.data.CancelConfirm == 1 ? true : false
			})
		},
		changeOrder() {
			// localStorage.setItem('isOrderEnsure', this.checked1)
			// this.$emit('change')
			this.submit()
		},
		changeCancel() {
			// localStorage.setItem('isCancelOrderEnsure', this.checked2)
			// this.$emit('change')
			this.submit()
		},
		submit() {
			let form = new FormData()
			form.append('OrderConfirm', this.checked1 ? 1 : 2)
			form.append('CancelConfirm', this.checked2 ? 1 : 2)
			confirmSetting(form).then(res => {
				if (res.code == 0) {
					this.$store.state.successMsg = this.i18n.t('common.success')
					this.$emit('change')
				} else {
					this.$store.state.errorMsg = res.msg
				}
			})
		}
	}
}
</script>