<template>
	<div class="full">
	 <div class="rank flex-center-between" v-for="(item, i) in bitDataList" :key="i" @click="chooseBit(item)">
			<div class="icon" :class="item.Name == 'BAL' ? 'bg' : ''">
				<img :src="`/upload/${item.Logo}` || usdtUrl" alt="">
			</div>
			<div class="flex-1">
				<div class="flex-center-between name">
					<div>
						{{item.Name}}<span class="gray">/USDT</span>
					</div>
					<div>{{item.count || '--'}}</div>
				</div>
				<div class="flex-center-between desc">
					<div>{{item.Remark}}</div>
					<div class="percent" :class="!item.percent || item.percent >= 0 ? 'green' : 'red'">
						<i class="iconfont " :class="!item.percent || item.percent >= 0 ? 'icon-arrowRightTop' : 'icon-arrowRightDown'"></i>
						{{item.percent !== undefined ? `${item.percent}%` : '--'}}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { initWebSocket } from '@/utils/common'
export default {
	data() {
		return {
			usdtUrl: require('../../assets/imgs/icon-usdt.png'),
			bitListBak: [],
			bitDataList: []
		}
	},
	props: {
		dataList: Array,
		subscribe: Boolean
	},
	computed: {
    bitList() {
      return this.$store.state.bitList
    }
  },
	watch: {
		dataList(val) {
			if (val) {
				this.bitDataList = this.dataList.filter(v => {
					return v.Name != 'USDT'
				})
			}
		}
	},
	mounted() {
		this.bitDataList = this.dataList.filter(v => {
			return v.Name != 'USDT' || v.Name != "WBTC"
		})
		if (this.subscribe) {
			this.bitListBak = this.bitList
			this.initSocket()
		}
	},
	methods: {
		async initSocket(){ //初始化weosocket
      let client = await initWebSocket(-1, this.websocketonmessage, this.initSocket)
      this.webSocket = client
    },
    websocketonmessage(e){ //数据接收
      let redata = {}
      if (e.data.indexOf('Welcome') > -1) {
				console.log(1111, '订阅')
				this.webSocket.send(JSON.stringify({
          action: 'subscribe',
          topic: 'all',
          message: ''
        }))
			}
      try {
        redata = JSON.parse(e.data)
        this.setListData(redata)
      }
      catch(e) {
      }
    },   
    setListData(data) {
      let index = this.bitListBak.findIndex(v => {
        return v.Name + '-USDT' == data.tags.InstId
      })
      let valObj = data.fields
      let percent = parseFloat(((valObj.Close - valObj.Yesterday) / valObj.Yesterday * 100).toFixed(2))
      if (index > -1) {
        this.bitListBak[index] = {
          ...this.bitListBak[index],
          ...valObj,
          percent,
          count: valObj.Close,
        }
      }
      this.$store.state.bitList = [].concat(this.bitListBak)
    },
		chooseBit(data) {
			this.$emit('detail', data)
		}
	}
}
</script>