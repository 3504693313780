<template>
  <div class="finance-page">
    <div class="title">{{i18n.t('finance.title')}}</div>

    <div class="finance-info">
      <div class="flex-center-start modal-title">
        <span>{{i18n.t('finance.label1')}}</span>
        <img :src="moneyUrl" alt="">
      </div>
      <div class="desc">{{i18n.t('finance.label2')}}</div>

      <div class=" statistics">
        <div class="flex statistics-line">
          <div class="flex-1">
            <div class="count">{{statisticsData.Investment ? parseFloat(statisticsData.Investment).toFixed(4) : '0.0000'}}</div>
            <div class="label">{{i18n.t('finance.label3')}}(USD)</div>
          </div>
          <div class="flex-1">
            <div class="count">{{statisticsData.Profit ? parseFloat(statisticsData.Profit).toFixed(4) : '0.0000'}}</div>
            <div class="label">{{i18n.t('finance.label4')}}(USD)</div>
          </div>
        </div>
        <div class="flex statistics-line">
          <div class="flex-1">
            <div class="count">{{statisticsData.Position ? parseFloat(statisticsData.Position).toFixed(4) : '0.0000'}}</div>
            <div class="label">{{i18n.t('finance.label5')}}(USD)</div>
          </div>
          <div class="flex-1">
            <div class="count">{{statisticsData.TodayProfit ? parseFloat(statisticsData.TodayProfit).toFixed(4) : '0.0000'}}</div>
            <div class="label">{{i18n.t('finance.label6')}}(USD)</div>
          </div>
        </div>
      </div>
    </div>

    <div class="finance-list">
      <List
        v-if="financeDataList.length > 0"
        v-model="loading"
        :finished="finished"
        :finished-text="finishedText"
        @load="onLoad">

        <div class="list" v-for="(item, i) in financeDataList" :key="i">
          <div class="flex-center-start finance-title">
            <img :src="`/upload/${item.Currency.Logo}` || bitUrl" alt="">
            <span>{{item.Currency.Name}}</span>
          </div>
          <div class="flex">
            <div class="flex-1">
              <div class="label">{{i18n.t('finance.label7')}}</div>
              <div class="count">{{item.ProfitRatio}}%</div>
            </div>
            <div class="flex-1">
              <div class="label">{{i18n.t('finance.label8')}}</div>
              <div class="count">{{item.IsRegular == 1 ? i18n.t('finance.label8_1') : i18n.t('finance.label8_2')}}</div>
            </div>
            <div>
              <Button @click="toDetail(item)">{{i18n.t('finance.btn')}}</Button>
            </div>
          </div>
        </div>
      </List>
    </div>
  </div>
</template>
<script>
import { Button, List } from 'vant'
import { financeSetupList, financeList, financialStatistics } from '@/api/finance'
export default {
  components: {
    Button,
    List
  },
  data() {
    return {
      moneyUrl: require('../../assets/imgs/icon-money2.png'),
      bitUrl: require('../../assets/imgs/bit1.png'),
      page: {
        page: 1,
        limit: 10
      },
      isInit: false,
      loading: false,
      finished: false,
      totalPage: 1,
      finishedText: '',
      financeDataList: [], //申购产品列表
      ownerFinanceList: [], //已申购列表
      statisticsData: {}
    }
  },
  mounted() {
    this.init()
    this.initStatistics()
    // this.initFinance()
  },
  methods: {
    init(isAdd) {
      let form = new FormData()
      form.append('current', this.page.page)
      form.append('pageSize', this.page.limit)
      financeSetupList(form).then(res => {
        if (res.data.Items) {
          if (isAdd) {
            this.financeDataList = this.financeDataList.concat(res.data.Items)
          } else {
            this.financeDataList = res.data.Items
          }
          this.totalPage = res.data.Pagination.totalPage
          if (this.totalPage <= this.page.page) {
             this.finished = true;
          }
        }
        this.isInit = true
        this.loading = false
        
      })
    },
    initStatistics() {
      financialStatistics().then(res => {
        this.statisticsData = res.data
      })
    },
    initFinance() {
      let form = new FormData()
      form.append('current', 1)
      form.append('pageSize', 100)
      financeList().then(res => {
        this.ownerFinanceList = res.data.Items
      })
    },
    onLoad() {
      this.page.page += 1
      this.init(true)
    },
    toDetail(data) {
      sessionStorage.setItem('financeData', JSON.stringify(data))
      this.$router.push({
        path: '/financeDetail',
      })
    }
  }
}
</script>