<template>
	<div class="setting-page order  top-bar-page all-order">
		<top-bar :title="i18n.t('allOrder.title')" class="black" parentId=".all-order"  @back="back"></top-bar>
		<div class="order-top">
			<div class="sub-title flex"> 
				<div class="tab" v-for="(item, i) in tabList" :key="i" :class="tabIndex == i ? 'active' : ''" @click="changeTab(i)">
					{{item}}
				</div>
			</div>
			<div class="order-filter flex-center-start" v-if="tabIndex == 1">
				<div class="flex-center filter" @click="chooseOption">
					<span>{{statusName}}</span>
					<img :src="downUrl" alt="">
				</div>
				<div class="option-list" v-show="showOption">
					<div class="option-item" v-for="(item, i) in statusList" :key="i" :class="item.value == form.status ? 'active' : ''" @click="changeOption(item)">
						{{item.text}}
					</div>
				</div>
			</div>
		</div>

		<div class="order-list">
			<List
				v-if="orderList.length > 0"
				v-model="loading"
				:finished="finished"
				:finished-text="i18n.t('common.noMore')"
				@load="onLoad">

				<div class="trade-list" v-if="tabIndex == 0">
					<div class="list" v-for="(item, i) in orderList" :key="i">
						<div class="flex-center-between">
							<div>
								<div class="list-title flex-center-start">
									<span>{{item.Currency.Name}}/USDT</span>
									<span class="tab" :class="item.Trade == 'buy' ? 'green' : 'red'">{{item.Trust == 1 ? i18n.t('trade.label21') : i18n.t('trade.label22')}}-{{item.Trade == 'buy' ? i18n.t('trade.buy1') : i18n.t('trade.sell1')}}</span>
								</div>
								<div class="status" :class="item.Status == 2 ? 'red' : 'green'">{{item.Status == 0 ? i18n.t('trade.status1') : (item.Status == 1 ?  i18n.t('trade.status2') :  i18n.t('trade.status3'))}}</div>
							</div>
							<div class="cancel-btn" v-if="item.Status == 0">
								<Button @click="cancelOrder(item)">{{ i18n.t('trade.btn1')}}</Button>
							</div>
						</div>

						<div class="list-line flex-center-between">
							<div class="label">{{i18n.t('trade.th1')}}</div>
							<div>{{parseFloat(item.TrustClose).toFixed(4)}} USDT</div>
						</div>
						<div class="list-line flex-center-between">
							<div class="label">{{i18n.t('trade.th2')}}</div>
							<div>{{parseFloat(item.Close).toFixed(4)}} USDT</div>
						</div>
						<div class="list-line flex-center-between">
							<div class="label">{{i18n.t('trade.th3')}}</div>
							<div>{{parseFloat(item.CloseCount).toFixed(4)}} USDT</div>
						</div>
						<div class="list-line flex-center-between">
							<div class="label">{{i18n.t('trade.th4')}}</div>
							<div>{{parseFloat(item.TrustNum).toFixed(4)}} </div>
						</div>
						<div class="list-line flex-center-between">
							<div  class="label">{{i18n.t('trade.th5')}}</div>
							<div>{{parseFloat(item.CloseNum).toFixed(4)}} </div>
						</div>
						<div class="list-line flex-center-between">
							<div  class="label">{{i18n.t('trade.th6')}}</div>
							<div>{{item.CreatedAt}} </div>
						</div>
					</div>
				</div>

				<div class="trade-list black" v-if="tabIndex == 1">
					<div class="list" v-for="(item, i) in orderList" :key="i">
						<div class="flex-center-between">
							<div class="list-title flex-center-start">
								<span>{{item.Currency.Name}}/USDT</span>
								<span class="tab" :class="[item.Direction == 'long' ? 'green' : 'red', i18n.t('trade.label21').length > 10 ? 'small' : '']">{{item.Trust == 1 ? i18n.t('trade.label21') : i18n.t('trade.label22')}}-{{item.Direction == 'long' ? i18n.t('trade.buy2') : i18n.t('trade.sell2')}}</span>
								<span class="tab yellow" :class="i18n.t('trade.label1').length > 12 ? 'small' : ''">{{item.Full == 'full' ? i18n.t('trade.label1') : i18n.t('trade.label2')}} {{item.Multiple}}X</span>
							</div>
							<!-- <div class="status" :class="item.Status == 2 ? 'red' : 'green'">{{item.Status == 0 ? i18n.t('trade.status1') : (item.Status == 1 ? i18n.t('trade.status4') : i18n.t('trade.status5'))}}</div> -->
						</div>
			
					
						<div class="list-line flex-center-between">
							<div class="label">{{i18n.t('trade.th11')}}</div>
							<div>{{parseFloat(item.OpenNum).toFixed(4)}}</div>
						</div>
						<div class="list-line flex-center-between">
							<div class="label">{{i18n.t('trade.th9')}}</div>
							<div>{{parseFloat(item.TrustClose).toFixed(4)}}</div>
						</div>
						<div class="list-line flex-center-between">
							<div class="label">{{i18n.t('trade.th10')}}</div>
							<div>{{parseFloat(item.OpenClose).toFixed(4)}}</div>
						</div>
					
						<div class="list-line flex-center-between">
							<div class="label">{{i18n.t('trade.th12')}}</div>
							<div>{{parseFloat(item.Close).toFixed(4)}}</div>
						</div>
						<div class="list-line flex-center-between">
							<div class="label">{{i18n.t('trade.th8')}}</div>
							<div>{{parseFloat(item.Actual).toFixed(4)}}</div>
						</div>

						<div class="list-line flex-center-between">
							<div class="label">{{i18n.t('trade.th7')}}</div>
							<div class="flex-center">
								{{parseFloat(item.Margin).toFixed(4)}}
								<img :src="rightUrl" alt="">
							</div>
						</div>
						<div class="list-line flex-center-between">
							<div class="label">{{i18n.t('trade.th26')}}</div>
							<div class="flex-center">
								<span class="green">{{parseFloat(item.TakeProfit) || '--'}}</span>
								<span> / </span>
								<span class="red">{{parseFloat(item.StopLoss) || ' --'}}</span>
								<img :src="rightUrl" alt="">
							</div>
						</div>

						<div class="status-line" @click="toClearOrder(item)">
							{{i18n.t('trade.status5')}}
						</div>
						<!-- <div class="list-line flex-center-between">
							<div class="label">{{i18n.t('trade.th13')}}</div>
							<div>{{item.CloseNum}}</div>
						</div>
						<div class="list-line flex-center-between">
							<div class="label">{{i18n.t('trade.th14')}}</div>
							<div>{{item.CloseTime}}</div>
						</div> -->
						<!-- <div class="list-line flex-center-between">
							<div class="label">{{i18n.t('trade.th15')}}</div>
							<div>{{item.Commission}}</div>
						</div> -->
					</div>
				</div>

				<div class="trade-list" v-if="tabIndex == 2">
					<div class="list" v-for="(item, i) in orderList" :key="i">
						<div class="list-line flex-center-between">
							<div class="label">{{i18n.t('trade.th16')}}</div>
							<div>{{item.Currency.Name}}/USDT</div>
						</div>
						<div class="list-line flex-center-between">
							<div class="label">{{i18n.t('trade.th17')}}</div>
							<div class="label" :class="item.Status == 2 ? 'red' : 'green'">{{item.Status == 0 ? i18n.t('trade.status6') : (item.Status == 1 ? i18n.t('trade.status7') : i18n.t('trade.status8'))}}</div>
						</div>
						<div class="list-line flex-center-between">
							<div class="label">{{i18n.t('trade.th18')}}</div>
							<div class="label">{{item.CreatedAt}}</div>
						</div>
						<div class="list-line flex-center-between">
							<div class="label">{{i18n.t('trade.th19')}}</div>
							<div class="label">{{item.MarkPrice}}</div>
						</div>
						<div class="list-line flex-center-between">
							<div class="label">{{i18n.t('trade.th20')}}</div>
							<div class="label">{{item.SettlementTime}}</div>
						</div>
						<div class="list-line flex-center-between" v-if="item.Status == 1">
							<div class="label">{{i18n.t('trade.th21')}}</div>
							<div class="label">{{item.SettlementPrice}}</div>
						</div>
						<div class="list-line flex-center-between">
							<div class="label">{{i18n.t('trade.th22')}}</div>
							<div class="label" :class="item.Direction == 'rose' ? 'green' : 'red'">{{item.Direction == 'rose' ? i18n.t('trade.buy4') : i18n.t('trade.sell4')}}</div>
						</div>
						<div class="list-line flex-center-between">
							<div class="label">{{i18n.t('trade.th23')}}</div>
							<div class="label">{{item.Settlement}}S</div>
						</div>
						<div class="list-line flex-center-between">
							<div class="label">{{i18n.t('trade.th24')}}</div>
							<div class="label">{{item.Amount}} USDT</div>
						</div>
						<div class="list-line flex-center-between" v-if="item.Status == 1">
							<div class="label">{{i18n.t('trade.th25')}}</div>
							<div class="label" :class="item.Profit > 0 ? 'green' : 'red'">{{item.Profit}}</div>
						</div>
					</div>
				</div>
			</List>


			<div class="order-empty flex-column-center" v-if="orderList.length == 0 && isInit">
				<img :src="emptyUrl" alt="">
				<span>{{i18n.t('common.empty')}}</span>
			</div>
		</div>
		<!--平仓-->
		<Popup v-model:show="showClearPage" position="bottom" lock-scroll class="rate-popup">
			<clear-page :bitInfo="bitInfo" :orderData="orderdetailData" @cancel="cancel" @submit="submitClear"></clear-page>
		</Popup>
	</div>
</template>
<script>
import { optionOrderList, spotList, contractList, cancelSpotOrder, contractLiquidation } from '@/api/trade'
import topBar from '@/components/topBar'
import { List, Button, Popup } from 'vant'
import clearPage from './clear'
export default {
	components: {
		topBar,
		List,
		Button,
		Popup,
		clearPage
	},
	props: {
		bitInfo: Object
	},
  data() {
		return {
			form: {
				status: ''
			},
			tabIndex: 0,
			downUrl: require('../../assets/imgs/icon-arrow-down.png'),
			backUrl: require('../../assets/imgs/icon-back.png'),
			emptyUrl: require('../../assets/imgs/order-empty.png'),
			rightUrl: require('../../assets/imgs/icon-arrow-right.png'),
			tabList: [this.i18n.t('allOrder.tab1'), this.i18n.t('allOrder.tab2'), this.i18n.t('allOrder.tab3')],
			orderList: [],
			isInit: false,
      loading: false,
      finished: false,
			page: {
				page: 1,
				pageSize: 10
			},
			totalPage: 1,
			showOption: false,
			statusName: this.i18n.t('allOrder.status1'),
			statusList: [
				{
					text: this.i18n.t('allOrder.status1'),
					value: ''
				},
				{
					text: this.i18n.t('allOrder.status2'),
					value: '0'
				},
				{
					text: this.i18n.t('allOrder.status3'),
					value: '1'
				},
				{
					text: this.i18n.t('allOrder.status4'),
					value: '2'
				},
			],
			showClearPage: false,
			orderdetailData: {}
		}
	},
	mounted() {
		this.initOrder()
	},
	methods: {
		back() {
			this.$emit('back')
		},
		changeTab(i) {
			this.tabIndex = i
			this.$emit('changeTab', i)
			this.page.page = 1
			this.orderList = []
			this.isInit = false
			this.initOrder()
		},
		onLoad() {
      this.page.page += 1
      this.initOrder(true)
    },
		initOrder(isAdd) {
			let form = new FormData()
			form.append('current', this.page.page)
			form.append('pageSize', this.page.pageSize)
			if (this.tabIndex == 0) {
				spotList(form).then(res => {
					if (res.data.Items) {
						if (isAdd) {
							this.orderList = this.orderList.concat(res.data.Items)
						} else {
							this.orderList = res.data.Items
						}
						this.totalPage = res.data.Pagination.totalPage
						if (this.totalPage <= this.page.page) {
							this.finished = true;
						}
					}
					this.isInit = true
					this.loading = false
				})
			} else if (this.tabIndex == 1) {
				form.append('status', this.form.status)
				contractList(form).then(res => {
					if (res.data.Items) {
						if (isAdd) {
							this.orderList = this.orderList.concat(res.data.Items)
						} else {
							this.orderList = res.data.Items
						}
						this.totalPage = res.data.Pagination.totalPage
						if (this.totalPage <= this.page.page) {
							this.finished = true;
						}
					}
					this.isInit = true
					this.loading = false
				})
			} else if (this.tabIndex == 2) {
				optionOrderList(form).then(res => {
					if (res.data.Items) {
						if (isAdd) {
							this.orderList = this.orderList.concat(res.data.Items)
						} else {
							this.orderList = res.data.Items
						}
						this.totalPage = res.data.Pagination.totalPage
						if (this.totalPage <= this.page.page) {
							this.finished = true;
						}
					}
					this.isInit = true
					this.loading = false
				})
			}
		},
		doCancelOrder(data) {
			let form = new FormData()
			form.append('ID', data.ID)
			cancelSpotOrder(form).then(res => {
				if (res.code == 0) {
					this.$store.state.successMsg = this.i18n.t('trade.success2')
					this.initOrder()
				} else {
					this.$store.state.errorMsg = res.msg
				}
			})
		},
		changeOption(data) {
			this.form.status = data.value
			this.statusName = data.text
			this.page.page = 1
			this.showOption = false
			this.initOrder()
		},
		chooseOption() {
			this.showOption = !this.showOption
		},
		toClearOrder(data) {
			this.orderdetailData = data
			this.showClearPage = true
		},
		cancel() {
			this.showClearPage = false
		},
		submitClear(data) {
			let form = new FormData()
			form.append('ID', this.orderdetailData.ID)
			form.append('PlanTrust', data.type == 1 ? 'market' : 'limit')
			form.append('PlanTrustNum', data.number)
			form.append('PlanTrustPrice', data.price)
			contractLiquidation(form).then(res => {
				if (res.code == 0) {
					this.$store.state.successMsg = this.i18n.t('trade.success3')
					this.showClearPage = false
					this.page.page = 1
					this.initOrder()
				} else {
					this.$store.state.errorMsg = res.msg
				}
			})
		}
	}
}
</script>