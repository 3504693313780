<template>
  <div class="setting-page order role-page">
    <top-bar :title="roleData.Title" parentId=".notice-page" @back="back"></top-bar>

    <div class="notice-list">
      <div class="list" >
        <div class="content">
          <div class="detail" v-html="roleData.Content"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import topBar from '@/components/topBar'
export default {
  components: {
    topBar
  },
  props: {
    roleData: Object
  },
  data() {
    return {
      
    }
  },
  mounted() {
  },
  methods: {
    back() {
      this.$emit('cancel')
    }
  }
}
</script>