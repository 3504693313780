<template>
  <div class="tab-bar" >
    <div class="tab" :class="{active: tabIndex == i}" v-for="(tab, i) in tabArr" :key="i" @click="changeTab(tab, i)">
      <div class="icon">
        <img :src="homeIcon" alt="" v-if="tabIndex !== 0 && i == 0">
        <img :src="homeActiveIcon" alt="" v-if="i == 0 && tabIndex == 0">
        
        <img :src="marketIcon" alt="" v-if="tabIndex !== 1 && i == 1">
        <img :src="marketActiveIcon" alt="" v-if="i == 1 && tabIndex == 1">

        <img :src="tradeIcon" alt="" v-if="tabIndex !== 2 && i == 2">
        <img :src="tradeActiveIcon" alt="" v-if="i == 2 && tabIndex == 2">

        <img :src="financeIcon" alt="" v-if="tabIndex !== 3 && i == 3">
        <img :src="financeActiveIcon" alt="" v-if="i == 3 && tabIndex == 3">

        <img :src="assestIcon" alt="" v-if="tabIndex !== 4 && i == 4">
        <img :src="assestActiveIcon" alt="" v-if="i == 4 && tabIndex == 4">
      </div>
      <div class="title">{{i18n.t(`common.${tab.title}`)}}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'tabbar',
  props:{

  },
  data() {
    return {
      homeIcon: require('../../assets/imgs/tab/index.png'),
      homeActiveIcon: require('../../assets/imgs/tab/index_select.png'),
      marketIcon: require('../../assets/imgs/tab/market.png'),
      marketActiveIcon: require('../../assets/imgs/tab/market_select.png'),
      tradeIcon: require('../../assets/imgs/tab/trade.png'),
      tradeActiveIcon: require('../../assets/imgs/tab/trade_select.png'),
      financeIcon: require('../../assets/imgs/tab/finance.png'),
      financeActiveIcon: require('../../assets/imgs/tab/finance_select.png'),
      assestIcon: require('../../assets/imgs/tab/capital.png'),
      assestActiveIcon: require('../../assets/imgs/tab/capital_select.png'),
      tabIndex: 0,
      defaultIcon: '',
      tabRoute: ['index', 'market', 'trade', 'finance', 'assest'],
      tabArr: [
        {title: 'tab1', icon: 'home', path: '/index'},
        {title: 'tab2', icon: 'market', path: '/market'},
        {title: 'tab3', icon: 'trade', path: '/trade'},
        {title: 'tab4', icon: 'finance', path: '/finance'},
        {title: 'tab5', icon: 'assest', path: '/assest'},
      ]
    }
  },
  watch: {
    $route(val) {
      let name = val.name
      if (this.tabRoute.indexOf(name) > -1) {
        this.init()
      } else {
        this.tabIndex = -1
      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      let name = this.$route.name
      switch(name) {
        case 'index': this.tabIndex = 0; break;
        case 'market': this.tabIndex = 1; break;
        case 'trade': this.tabIndex = 2; break;
        case 'finance': this.tabIndex = 3; break;
        case 'assest': this.tabIndex = 4; break;
        default: this.tabIndex = -1; break;
      }
    },
    changeTab(tab, i) {
      this.tabIndex = i
      this.$router.push({
        path: tab.path
      })
    }
  }
}
</script>