<template>
  <div class="login-page top-bar-page">
    <top-bar :title="i18n.t('editPassword.title')" class="black" parentId=".about-page"  @back="back"></top-bar>


    <div class="login-form">
      
      <div class="flex-center-start warning-line clear">
        <img :src="warningUrl" alt="">
        <span>{{i18n.t('editPassword.desc')}}</span>
      </div>

      <div class="form-item flex-center" >
        <div class="icon">
          <img :src="passwordUrl" alt="">
        </div>
        <div class="input"> 
          <input :type="showOldPassword ? 'text' : 'password'" v-model="form.oldPassword" :placeholder="i18n.t('editPassword.labelPlaceholder1')">
        </div>
        <div class="adot" @click="changeShowOldPassword">
          <img :src="showOldPassword ? eyeUrl2 : eyeUrl" alt="" >
        </div>
      </div>
    

      <div class="form-item flex-center" id="passwordDom">
        <div class="icon">
          <img :src="passwordUrl" alt="">
        </div>
        <div class="input"> 
          <input :type="showPassword ? 'text' : 'password'" @input="changePassword" v-model="form.password" :placeholder="i18n.t('editPassword.labelPlaceholder2')">
        </div>
        <div class="adot" @click="changeShowPassword">
          <img :src="showPassword ? eyeUrl2 : eyeUrl" alt="" >
        </div>
      </div>

      <div class="password-auth" v-show="showPasswordAuth">
        <div class="auth-line flex-center-start"> 
          <img :src="passwordAuthList.indexOf(0) > -1 ? checkedUrl2 : checkedUrl" alt="">
          <span>{{i18n.t('register.role1')}}</span>
        </div>
        <div class="auth-line flex-center-start"> 
          <img :src="passwordAuthList.indexOf(1) > -1 ? checkedUrl2 : checkedUrl" alt="">
          <span>{{i18n.t('register.role2')}}</span>
        </div>
        <div class="auth-line flex-center-start"> 
          <img :src="passwordAuthList.indexOf(2) > -1 ? checkedUrl2 : checkedUrl" alt="">
          <span>{{i18n.t('register.role3')}}</span>
        </div>
        <div class="auth-line flex-center-start"> 
          <img :src="passwordAuthList.indexOf(3) > -1 ? checkedUrl2 : checkedUrl" alt="">
          <span>{{i18n.t('register.role4')}}</span>
        </div>
      </div>

      <div class="form-item flex-center" >
        <div class="icon">
          <img :src="passwordUrl" alt="">
        </div>
        <div class="input"> 
          <input :type="showPassword2 ? 'text' : 'password'"  v-model="form.password2" :placeholder="i18n.t('editPassword.labelPlaceholder3')">
        </div>
        <div class="adot" @click="changeshowPassword2">
          <img :src="showPassword2 ? eyeUrl2 : eyeUrl" alt="" >
        </div>
      </div>

    </div>

    
   

    <Button class="login-btn" @click="submit" :disabled="btnDisabled">{{i18n.t(`editPassword.btn`)}}</Button>

  </div>
</template>
<script>
  import topBar from '@/components/topBar'
  import { Button, Popup } from 'vant'
  import { editPassword } from '@/api/setting'
  export default {
    components: {
      Button,
      Popup,
      topBar
    },
    data() {
      return {
        form: {
          oldPassword: '',
          password: '',
          password2: '',
        },
        backUrl: require('../../assets/imgs/icon-back.png'),
        passwordUrl: require('../../assets/imgs/icon-password.png'),
        eyeUrl: require('../../assets/imgs/icon-eye.png'),
        eyeUrl2: require('../../assets/imgs/icon-eye2.png'),
        checkedUrl: require('../../assets/imgs/icon-check.png'),
        checkedUrl2: require('../../assets/imgs/icon-check2.png'),
        warningUrl: require('../../assets/imgs/icon-warning.png'),
        showPassword: false,
        showPassword2: false,
        showOldPassword: false,
        isDisabled: false,
        showPasswordAuth: false,
        passwordAuthList: []
      }
    },
    computed: {
      btnDisabled() {
        return !(this.form.password && this.form.password2 && this.form.oldPassword && this.passwordAuthList.length == 4)
      }
    },
    methods: {
      back() {
        this.$router.go(-1)
      },
      toLogin() {
        this.$router.push({
          name: 'login'
        })
      },
      submit() {
        if (!this.form.oldPassword) {
         this.$store.state.warningMsg = this.i18n.t('editPassword.labelPlaceholder1')
          return
        }
        if (!this.form.password) {
          this.$store.state.warningMsg = this.i18n.t('editPassword.labelPlaceholder2')
          return
        }
        if (!this.form.password2) {
          this.$store.state.warningMsg = this.i18n.t('editPassword.labelPlaceholder3')
          return
        }

        let form = new FormData()
        form.append('OldPassword', this.form.oldPassword)
        form.append('NewPassword', this.form.password)
        form.append('ConfirmPassword', this.form.password2)
        editPassword(form).then(res => {
          if (res.code == 0) {
            this.$store.state.successMsg = this.i18n.t('editPassword.success')
            this.$router.push({
              name: 'login'
            })
          } else {
            this.$store.state.errorMsg = res.msg
          }
        })
      },
      changeShowOldPassword() {
        this.showOldPassword = !this.showOldPassword
      },
      changeShowPassword() {
        this.showPassword = !this.showPassword
      },
      changeshowPassword2() {
        this.showPassword2 = !this.showPassword2
      },
      changePassword() {
        this.showPasswordAuth = true
        let password = this.form.password
        let result = []
        if (password.length >= 8 && password.length <= 30) {
          result.push(0)
        }
        if (/[a-z]/g.test(password)) {
          result.push(1)
        }
        if (/[A-Z]/g.test(password)) {
          result.push(2)
        }
        if (/[0-9]/g.test(password)) {
          result.push(3)
        }

        this.passwordAuthList = result
      }
    },
  }
</script>