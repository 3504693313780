import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from '../store'
import { getDefaultLanguage } from '../utils/common';
Vue.use(VueI18n)

// 根据语言进行选择
const defaultLanguage = getDefaultLanguage()
const messages = {
  'zh-CN': require('./lang/zh-CN.yaml'), // 简体中文语言包
  'zh-TW': require('./lang/zh-TW.yaml'), // 繁体中文语言包
  'ar': require('./lang/ar.yaml'), // 阿拉伯语
  'en': require('./lang/en.yaml'), // 英文
  'es': require('./lang/es.yaml'), // 西班牙语
  'fr': require('./lang/fr.yaml'), // 法语
  'id': require('./lang/id.yaml'), // 印尼语
  'ja': require('./lang/ja.yaml'), // 日语
  'ko': require('./lang/ko.yaml'), // 韩语
  'pt': require('./lang/pt.yaml'), // 葡萄牙语
  'ru': require('./lang/ru.yaml'), // 俄语
  'th': require('./lang/th.yaml'), // 泰语
  'he': require('./lang/he.yaml'), // 希伯来语
  'tr': require('./lang/tr.yaml'), // 土耳其语
  'hi': require('./lang/hi.yaml'), // 印地语
  'vi': require('./lang/vi.yaml'), // 越南语
  'de': require('./lang/de.yaml'), // 德语
  'it': require('./lang/it.yaml'), // 意大利语
  'kk': require('./lang/kk.yaml'), // 哈萨克斯坦语
  'ms': require('./lang/ms.yaml'), // 马来语
};
// 国际化
const i18n = new VueI18n({
  // locale: store.state.language || 'en', // set locale
  locale: defaultLanguage,  // set locale
  messages: messages,
  silentTranslationWarn: false,
})


export default i18n
