<template>
  <div class="login-page">
    <div class="login-top flex-center-between">
      <div class="back">
        <img :src="backUrl" alt="" @click="back">
      </div>
      <div class="text" @click="toLogin">{{i18n.t('resetPassword.login')}}</div>
    </div>

    <div class="title">{{i18n.t('resetPassword.title')}}</div>
    
  

    <div class="login-form">
        
      <div class="flex-center-start warning-line clear-bottom">
        <img :src="warningUrl" alt="">
        <span>{{i18n.t('resetPassword.desc')}}</span>
      </div>

      <div class="tabs">
        <div class="tab" :class="tabIndex == 1 ? 'active' : ''" @click="changeTab(1)">{{i18n.t('resetPassword.tab')}}</div>
        <div class="tab" :class="tabIndex == 2 ? 'active' : ''" @click="changeTab(2)">{{i18n.t('resetPassword.tab2')}}</div>
      </div>

      <div class="form-item  flex-center" v-show="tabIndex == 1 && step == 1" >
        <div class="icon">
          <img :src="emailUrl" alt="">
        </div>
        <div class="input"> 
          <input type="text" v-model="form.username"   :placeholder="i18n.t('resetPassword.labelPlaceholder1')">
        </div>
      </div>

      <div class="form-item  flex-center" v-if="tabIndex == 2 && step == 1">
        <div class="pre flex-center" @click="changePre">
          <img :src="phoneUrl" alt="">
          {{phonePre}}
        </div>
        <div class="flex-1">
          <div class="input clear"> 
            <input type="tel" v-model="form.phone" :placeholder="i18n.t('resetPassword.labelPlaceholder8')">
          </div>
        </div>
      </div>
    

      <div class="form-item flex-center" v-if="step == 2" >
        <div class="icon">
          <img :src="passwordUrl" alt="">
        </div>
        <div class="input"> 
          <input :type="showPassword ? 'text' : 'password'" @input="changePassword" v-model="form.password" :placeholder="i18n.t('resetPassword.labelPlaceholder2')">
        </div>
        <div class="adot" @click="changeShowPassword">
          <img :src="showPassword ? eyeUrl2 : eyeUrl" alt="" >
        </div>
      </div>
      

      <div class="password-auth" v-show="showPasswordAuth">
        <div class="auth-line flex-center-start"> 
          <img :src="passwordAuthList.indexOf(0) > -1 ? checkUrl2 : checkUrl" alt="">
          <span>{{i18n.t('register.role1')}}</span>
        </div>
        <div class="auth-line flex-center-start"> 
          <img :src="passwordAuthList.indexOf(1) > -1 ? checkUrl2 : checkUrl" alt="">
           <span>{{i18n.t('register.role2')}}</span>
        </div>
        <div class="auth-line flex-center-start"> 
          <img :src="passwordAuthList.indexOf(2) > -1 ? checkUrl2 : checkUrl" alt="">
           <span>{{i18n.t('register.role3')}}</span>
        </div>
        <div class="auth-line flex-center-start"> 
          <img :src="passwordAuthList.indexOf(3) > -1 ? checkUrl2 : checkUrl" alt="">
           <span>{{i18n.t('register.role4')}}</span>
        </div>
      </div>

      <div class="form-item flex-center" v-if="step == 2" >
        <div class="icon">
          <img :src="passwordUrl" alt="">
        </div>
        <div class="input"> 
          <input :type="showPassword2 ? 'text' : 'password'"  v-model="form.password2" :placeholder="i18n.t('resetPassword.labelPlaceholder3')">
        </div>
        <div class="adot" @click="changeshowPassword2">
          <img :src="showPassword2 ? eyeUrl2 : eyeUrl" alt="" >
        </div>
      </div>


      
    </div>

    <Button class="login-btn" @click="submit" :disabled="!btnDisabled">{{i18n.t(`resetPassword.btn${step}`)}}</Button>

		<Popup v-model:show="showCodePopup"  lock-scroll class="rate-popup">
			<div class="rate-modal register-auth">
				<div class="title flex-center-between clear">
					<div>{{i18n.t('register.auth1')}}</div>
          <img :src="closeUrl" alt="" @click="cancel">
				</div>
				<div class="desc">
          {{i18n.t('register.auth2')}} {{form.username}}
        </div>

        <div class="code-box" >
          <div class="input flex-center">
            <input ref="codeInput" type="tel" v-model="form.code" @input="checkCodeSize"  />
          </div>
          <div class="number-box flex-center-between">
            <div class="box flex-center" :class="getCodeActive(i)" v-for="i in 6" :key="i" @click="inputCode">{{getCodeStr(i)}}</div>
          </div>
        </div>

        <div class="send-info">
          <span v-if="!isDisabled" @click="resend">{{i18n.t('register.btn1')}}</span>
          <span v-if="isDisabled">{{timeCount}}</span>
        </div>

        <div class="auth-btn">
          <Button :disabled="form.code.length < 6" @click="toSet">{{i18n.t('register.btn3')}}</Button>
        </div>
			</div>
		</Popup>

    <Popup v-model:show="showPopup" position="bottom">
			<div class="country-list" >
				<div class="title flex-center-start">
          <img :src="backUrl" alt="" @click="cancel">
          <span>{{i18n.t('register.labelPlaceholder6')}}</span>
        </div>
        <div class="search-list flex-center-between">
          <div class="flex-center">
            <img :src="searchUrl" alt="">
            <input type="text" v-model="keyword" :placeholder="i18n.t('register.labelPlaceholder7')" @input="searchCountry" @change="searchCountry">
          </div>
          <div @click="cancel">{{i18n.t('register.cancel')}}</div>
        </div>
        <div class="group-list login" v-for="(item, i) in countryList" :key="i">
          <div class="list flex-center-between" :class="child.key == phonePre ? 'active' : ''" v-for="(child, index) in item.children" :key="index" @click.stop="chooseCountry(child)">
            <div class="img-box flex-center">
              <img :src="child.icon" alt="">
              <span>{{getCountryName(child.title)}}</span>
            </div>
            <div>
              {{child.key}}
            </div>
          </div>
        </div>
			</div>
    </Popup>
  </div>
</template>
<script>
  import { Button, Popup } from 'vant'
  import { resetPassword, sendCode, checkEmailCode, sendSms, checkPhoneCode } from '@/api/login'
  import { setToken, setUser, setUserId } from '@/utils/auth'
  export default {
    components: {
      Button,
      Popup
    },
    data() {
      return {
        step: 1,
        form: {
          username: '',
          phone: '',
          password: '',
          password2: '',
          code: '',
          inviteCode: ''
        },
        showCodePopup: false,
        backUrl: require('../../assets/imgs/icon-back.png'),
        closeUrl: require('../../assets/imgs/icon-close.png'),
        emailUrl: require('../../assets/imgs/icon-email2.png'),
        passwordUrl: require('../../assets/imgs/icon-password.png'),
        eyeUrl: require('../../assets/imgs/icon-eye.png'),
        eyeUrl2: require('../../assets/imgs/icon-eye2.png'),
        checkUrl: require('../../assets/imgs/icon-check.png'),
        checkUrl2: require('../../assets/imgs/icon-check2.png'),
        warningUrl: require('../../assets/imgs/icon-warning.png'),
        phoneUrl: require('../../assets/imgs/icon-phone2.png'),
        searchUrl: require('../../assets/imgs/icon-search.png'),
        showPassword: false,
        showPassword2: false,
        activeIndex: 1,
        isDisabled: false,
        sendTime: '',
        checkRole: false,
        showInvite: true,
        showPasswordAuth: false,
        passwordAuthList: [],
        phonePre: '+86',
        tabIndex: 1,
        showPopup: false,
        countryList: [],
        keyword: ''
      }
    },
    computed: {
      timeCount() {
        return this.sendTime + 's'
      },
      btnDisabled() {
        if (this.step == 1) {
          return this.form.username || this.form.phone
        } else {
          return this.form.password && this.form.password2 && this.passwordAuthList.length == 4
        }
      },
      countryListBak() {
        return this.$store.state.countryList
      }
    },
    mounted() {
      this.countryList = [].concat(this.countryListBak)
    },
    methods: {
      changeTab(index) {
        if (this.step == 1) {
          this.tabIndex = index
        } 
      },
      back() {
        this.$router.go(-1)
      },
      toLogin() {
        this.$router.push({
          name: 'login'
        })
      },
      toSet() {
        if (!this.form.code || (this.form.code + '').length != 6) {
          this.$store.state.warningMsg =  this.i18n.t('resetPassword.labelPlaceholder4')
          return
        }
        let form = new FormData()
        form.append('Code', this.form.code)
        form.append('Type', 2)
        if (this.tabIndex == 1) {
          form.append('Email', this.form.username)
          checkEmailCode(form).then(res => {
            if (res.code == 0) {
              this.step = 2
              this.showCodePopup = false
            } else {
              this.$store.state.errorMsg = res.msg
            }
          })
        } else {
          form.append('Phone', this.phonePre + this.form.phone)
          checkPhoneCode(form).then(res => {
            if (res.code == 0) {
              this.step = 2
              this.showCodePopup = false
            } else {
              this.$store.state.errorMsg = res.msg
            }
          })
        }
      },
      submit() {
      
        if (this.step == 1) {
          if (this.tabIndex == 1) {
            this.sendEmail()
          } else {
            this.sendPhoneSms()
          }
        } else {
          if (!this.form.password) {
            this.$store.state.warningMsg = this.i18n.t('resetPassword.labelPlaceholder2')
            return
          }

          let form = new FormData()
          if (this.tabIndex == 1) {
            form.append('Email', this.form.username)
          } else {
            form.append('Phone', this.phonePre + this.form.phone)
            form.append('CountryCode', this.phonePre)
          }
          form.append('Password', this.form.password)
          form.append('Code', this.form.code)
          resetPassword(form).then(res => {
            if (res.code == 0) {
              this.$store.state.successMsg = this.i18n.t('resetPassword.success')
              this.$router.push({
                name: 'login'
              })
            } else {
              this.$store.state.errorMsg = res.msg
            }
          })
        }
      },
      sendEmail() {
        if (!this.form.username) {
         this.$store.state.warningMsg = this.i18n.t('resetPassword.labelPlaceholder1')
          return
        }
        let form = new FormData()
        form.append('Email', this.form.username)
        form.append('Type', 2)
        sendCode(form).then(res => {
          if (res.code == 0) {
            this.showCodePopup = true
            this.countDown(60)
            this.$nextTick(() => {
              this.inputCode()
            }, 10)
          } else {
            this.$store.state.errorMsg = res.msg
          }
        })
      },
      sendPhoneSms() {
        if (!this.form.phone) {
         this.$store.state.warningMsg = this.i18n.t('resetPassword.labelPlaceholder8')
          return
        }
        let form = new FormData()
        form.append('Phone', this.phonePre + this.form.phone)
        form.append('Type', 2)
        sendSms(form).then(res => {
          if (res.code == 0) {
            this.showCodePopup = true
            this.countDown(60)
            this.$nextTick(() => {
              this.inputCode()
            }, 10)
          } else {
            this.$store.state.errorMsg = res.msg
          }
        })
      },
      countDown(time) {
        if (time > 0) {
          this.isDisabled = true
          time -= 1
          this.sendTime = time
          setTimeout(() => {
            this.countDown(time)
          }, 1000)
        } else {
          this.sendTime = ''
          this.isDisabled = false
        }
      },
      cancel() {
        this.showCodePopup = false
        this.showPopup = false
      },
      checkCodeSize() {
        let code = this.form.code + ''
        if (code.length > 6) {
          code = code.substring(0, 6)
          this.form.code = parseInt(code)
        }
      },
      getCodeActive(i) {
        let code = this.form.code + ''
        if (!code) {
          return i == this.activeIndex ? 'active' : ''
        }
        return i == code.length + 1 ? 'active' : ''
      },
      inputCode() {
        this.activeIndex = 1
        this.$refs.codeInput.focus()
      },
      getCodeStr(i) {
        let code = this.form.code + ''
        if (!code) {
          return ''
        }
        let str = code.substring(i - 1, i)
        return str
      },
      resend() {
        if (this.isDisabled) {
          return
        } else {
          this.sendEmail()
        }
      },
      changeRole() {
        this.checkRole = !this.checkRole
      },
      changeShowPassword() {
        this.showPassword = !this.showPassword
      },
      changeshowPassword2() {
        this.showPassword2 = !this.showPassword2
      },
      changeShowInvite() {
        this.showInvite = !this.showInvite
      },
      changePassword() {
        this.showPasswordAuth = true
        let password = this.form.password
        let result = []
        if (password.length >= 8 && password.length <= 30) {
          result.push(0)
        }
        if (/[a-z]/g.test(password)) {
          result.push(1)
        }
        if (/[A-Z]/g.test(password)) {
          result.push(2)
        }
        if (/[0-9]/g.test(password)) {
          result.push(3)
        }

        this.passwordAuthList = result
      },
      changePre() {
        this.showPopup = true
      },
      chooseCountry(data) {
        this.phonePre = data.key
        this.showPopup = false
      },
      getCountryName(name) {
        if (!name) {
          return ''
        }
        let newName = ''
        newName = name.substring(0, name.indexOf('(') - 1)
        return newName
      },
      searchCountry() {
        if (this.keyword) {
          this.countryList = this.countryListBak.map(v => {
            let children = v.children.filter(val => {
              return val.title.toLowerCase().indexOf(this.keyword.toLowerCase()) > -1
            })
            if (children.length > 0) {
              return {
                children: children
              }
            } else {
              return {
                children: []
              }
            }
          })
        } else {
          this.countryList = [].concat(this.countryListBak)
        }
      }
    },
  }
</script>