<template>
  <div class="login-page">
    <div class="login-top flex-center-between">
      <div class="back">
        <img :src="backUrl" alt="" @click="back">
      </div>
      <div class="text" @click="toRegister">{{i18n.t('login.register')}}</div>
    </div>

    <div class="title">{{i18n.t('login.title')}}</div>

    <div class="tabs">
      <div class="tab" :class="tabIndex == 1 ? 'active' : ''" @click="changeTab(1)">{{i18n.t('login.tab')}}</div>
      <div class="tab" :class="tabIndex == 2 ? 'active' : ''" @click="changeTab(2)">{{i18n.t('login.tab2')}}</div>
    </div>

    <div class="login-form">
      <div class="form-item  flex-center" v-if="tabIndex == 1">
        <div class="icon">
          <img :src="emailUrl" alt="">
        </div>
        <div class="input"> 
          <input type="text" v-model="form.username" :placeholder="i18n.t('login.labelPlaceholder1')">
        </div>
      </div>
      <div class="form-item  flex-center" v-if="tabIndex == 2">
        <div class="pre flex-center" @click="changePre">
          <img :src="phoneUrl" alt="">
          {{phonePre}}
        </div>
        <div class="flex-1">
          <div class="input clear"> 
            <input type="tel" v-model="form.phone" :placeholder="i18n.t('login.labelPlaceholder5')">
          </div>
        </div>
      </div>

      <div class="form-item flex-center">
        <div class="icon">
          <img :src="passwordUrl" alt="">
        </div>
        <div class="input"> 
          <input :type="showPassword ? 'text' : 'password'" v-model="form.password" :placeholder="i18n.t('login.labelPlaceholder2')">
        </div>
        <div class="adot" @click="changePassword">
          <img :src="eyeUrl" alt="" >
        </div>
      </div>
    </div>

    <div class="forget" @click="toPath('resetPassword')">
      {{i18n.t('login.forget')}}
    </div>

    <Button class="login-btn" @click="submit">{{i18n.t('login.btn')}}</Button>

    <Popup v-model:show="showPopup" position="bottom">
			<div class="country-list" >
				<div class="title flex-center-start">
          <img :src="backUrl" alt="" @click="cancel">
          <span>{{i18n.t('login.labelPlaceholder3')}}</span>
        </div>
        <div class="search-list flex-center-between">
          <div class="flex-center">
            <img :src="searchUrl" alt="">
            <input type="text" v-model="keyword" :placeholder="i18n.t('login.labelPlaceholder4')" @input="searchCountry" @change="searchCountry">
          </div>
          <div @click="cancel">{{i18n.t('login.cancel')}}</div>
        </div>
        <div class="group-list login" v-for="(item, i) in countryList" :key="i">
          <div class="list flex-center-between" :class="child.key == phonePre ? 'active' : ''" v-for="(child, index) in item.children" :key="index" @click.stop="chooseCountry(child)">
            <div class="img-box flex-center">
              <img :src="child.icon" alt="">
              <span>{{getCountryName(child.title)}}</span>
            </div>
            <div>
              {{child.key}}
            </div>
          </div>
        </div>
			</div>
    </Popup>
  </div>
</template>
<script>
  import { Button, Popup } from 'vant'
  import { login, phoneLogin } from '@/api/login'
  import { setToken, setUser, setUserId } from '@/utils/auth'
  export default {
    components: {
      Button,
      Popup
    },
    data() {
      return {
        form: {
          username: '',
          phone: '',
          password: ''
        },
        keyword: '',
        phonePre: '+86',
        backUrl: require('../../assets/imgs/icon-back.png'),
        emailUrl: require('../../assets/imgs/icon-email2.png'),
        passwordUrl: require('../../assets/imgs/icon-password.png'),
        phoneUrl: require('../../assets/imgs/icon-phone2.png'),
        searchUrl: require('../../assets/imgs/icon-search.png'),
        eyeUrl: require('../../assets/imgs/icon-eye.png'),
        eyeUrl2: require('../../assets/imgs/icon-eye2.png'),
        showPassword: false,
        tabIndex: 1,
        showPopup: false,
        countryList: []
      }
    },
    computed: {
      countryListBak() {
        return this.$store.state.countryList
      }
    },
    mounted() {
      this.countryList = [].concat(this.countryListBak)
    },
    methods: {
      changeTab(index) {
        this.tabIndex = index
      },
      back() {
          this.$router.push({
              name: 'index'
          })
      },
      toPath(path) {
        if (path) {
          this.$router.push({
            name: path
          })
        }
      },
      toRegister() {
        this.$router.push({
          name: 'register'
        })
      },
      changePassword() {
        this.showPassword = !this.showPassword
      },
      submit() {
        if (!this.form.username && this.tabIndex == 1) {
          this.$store.state.warningMsg = this.i18n.t('login.labelPlaceholder1')
          return
        }
        if (!this.form.phone && this.tabIndex == 2) {
          this.$store.state.warningMsg = this.i18n.t('login.labelPlaceholder5')
          return
        }
        if (!this.form.password) {
          this.$store.state.warningMsg = this.i18n.t('login.labelPlaceholder2')
          return
        }
        if (this.tabIndex == 1) {
          login({
            Email: this.form.username,
            Password: this.form.password,
          }).then(res => {
            if (res.code == 0) {
              this.$store.state.successMsg = this.i18n.t('login.success')
              setToken(res.data.token)
              setUser(this.form.username)
              setUserId(res.data.id)
              this.$router.push({
                name: 'index'
              })
            } else {
              this.$store.state.errorMsg = res.msg
            }
          })
        } else {
          phoneLogin({
            Phone: this.phonePre + this.form.phone,
            Password: this.form.password,
          }).then(res => {
            if (res.code == 0) {
              this.$store.state.successMsg = this.i18n.t('login.success')
              setToken(res.data.token)
              setUser(this.phonePre + this.form.phone)
              setUserId(res.data.id)
              this.$router.push({
                name: 'index'
              })
            } else {
              this.$store.state.errorMsg = res.msg
            }
          })
        }
      },
      cancel() {
        this.showPopup = false
      },
      changePre() {
        this.showPopup = true
      },
      chooseCountry(data) {
        this.phonePre = data.key
        this.showPopup = false
      },
      getCountryName(name) {
        if (!name) {
          return ''
        }
        let newName = ''
        newName = name.substring(0, name.indexOf('(') - 1)
        return newName
      },
      searchCountry() {
        if (this.keyword) {
          this.countryList = this.countryListBak.map(v => {
            let children = v.children.filter(val => {
              return val.title.toLowerCase().indexOf(this.keyword.toLowerCase()) > -1
            })
            if (children.length > 0) {
              return {
                children: children
              }
            } else {
              return {
                children: []
              }
            }
          })
        } else {
          this.countryList = [].concat(this.countryListBak)
        }
      }
    }
  }
</script>