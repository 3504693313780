import fetch from "../utils/fetch";

// 历史交易价格
export function tradeHistory(data) {
  return fetch({
    url: '/api/user/trades',
    method: 'post',
    data: data
  });
}
// 现货交易
export function spotBuy(data) {
  return fetch({
    url: '/api/user/spot-buy',
    method: 'post',
    data: data
  });
}
// 现货订单
export function spotList(data) {
  return fetch({
    url: '/api/user/spot-lists',
    method: 'post',
    data: data
  });
}
// 现货订单取消
export function cancelSpotOrder(data) {
  return fetch({
    url: '/api/user/spot-cancel',
    method: 'post',
    data: data
  });
}
// 期权设置
export function futuresSetting(data) {
  return fetch({
    url: '/api/user/FuturesSetting',
    method: 'post',
    data: data
  });
}
// 期权交易
export function optionOrder(data) {
  return fetch({
    url: '/api/user/option-order',
    method: 'post',
    data: data
  });
}
// 期权订单
export function optionOrderList(data) {
  return fetch({
    url: '/api/user/option-order-lists',
    method: 'post',
    data: data
  });
}
// K线图
export function kLine(data) {
  return fetch({
    url: '/api/user/k-line',
    method: 'post',
    data: data
  });
}
// 合约下单
export function contractOrder(data) {
  return fetch({
    url: '/api/user/contract-order',
    method: 'post',
    data: data
  });
}
// 合约订单
export function contractList(data) {
  return fetch({
    url: '/api/user/contract-list',
    method: 'post',
    data: data
  });
}
// 合约订单更改止盈止损
export function contractSpot(data) {
  return fetch({
    url: '/api/user/contract-stop',
    method: 'post',
    data: data
  });
}
// 合约订单平仓
export function contractLiquidation(data) {
  return fetch({
    url: '/api/user/contract-liquidation',
    method: 'post',
    data: data
  });
}
