<template>
  <div class="echart-container">
    <div class="time-line flex-center">
      <!-- <div class="tab">{{i18n.t('chart_page.time')}}</div> -->
      <div class="tab" v-for="(item, i) in showTimeList" :key="i" :class="echartsTime == item.time ? 'active' : ''" @click="changeTime(item)">
        {{item.text}}
      </div>
      <div class="tab flex-center more" @click="showMoreBox">
        <span :class="echartsTimeName ? 'high' : ''">{{echartsTimeName || i18n.t('chart_page.label2')}}</span>
        <img :src="downUrl" alt=""  :class="showMore ? 'show' : ''">
      </div>
      <div class="more-line flex-wrap" :class="showMore ? '' : 'hide'">
       
        <div class="tab" v-for="(item, i) in moreTimeList" :key="i" :class="echartsTime == item.time ? 'active' : ''" @click="changeTime(item, true)">
          {{item.text}}
        </div>
      </div> 
    </div>

    <div class="echart-dom" id="echartDom" v-if="showChart"></div>
  </div>
</template>
<script>
import * as echarts from 'echarts'
import { kLine } from '@/api/trade'
export default {
  data() {
    return {
      downUrl: require('../../assets/imgs/icon-arrow-down.png'),
      leftArrow: require('../../assets/imgs/char-line.png'),
      timeIndex: 0,
      showMore: false,
      echartsTime: 'm1',
      echartsTimeName: '',
      timeList: [
        {
          time: 'm1',
          text: this.i18n.t('chart_page.time1')
        },
        {
          time: 'm3',
          text: this.i18n.t('chart_page.time2')
        },
        {
          time: 'm5',
          text: this.i18n.t('chart_page.time3')
        },
        {
          time: 'm15',
          text: this.i18n.t('chart_page.time4')
        },
        {
          time: 'm30',
          text: this.i18n.t('chart_page.time5')
        },
        {
          time: 'h1',
          text: this.i18n.t('chart_page.time6')
        },
        {
          time: 'h2',
          text: this.i18n.t('chart_page.time7')
        },
        {
          time: 'h4',
          text: this.i18n.t('chart_page.time8')
        },
        {
          time: 'h6',
          text: this.i18n.t('chart_page.time9')
        },
        {
          time: 'h12',
          text: this.i18n.t('chart_page.time10')
        },
        {
          time: 'd1',
          text: this.i18n.t('chart_page.time11')
        },
      ],
      showTimeList: [],
      moreTimeList: [],
      xDataList: [],
      startValue: 0,
      endValue: 15,
      isMaxLeft: true,
      isMinLeft: true,
      kLineData: [],
      dataBak: [],
      echartsObj: null,
      timer: ''
    }
  },
  props: {
    id: [String, Number],
    showChart: Boolean
  },
  watch: {
    id(val) {
      if (val) {
        this.init()
      }
    },
    showChart(val) {
      setTimeout(() => {
        this.initChart()
      }, 1000);
      if (val && this.kLineData.length > 0) {
        this.initChart()
      }
    }
  },
  mounted() {
    this.showTimeList = this.timeList.slice(0, 5)
    this.moreTimeList = this.timeList.slice(5)
    if (this.id) {
      this.init()
    }
    let dom = document.getElementById('echartDom')
    if (dom) {
      dom.addEventListener('touchend', this.hideTooltip)
    }
  },
  methods: {
    init() {
      let form = new FormData()
      form.append('CurrencyId', this.id)
      form.append('Count', 100)
      form.append('TimeLine', this.echartsTime)
      kLine(form).then(res => {
          const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
          const moment = require('moment-timezone');
          const serverTimezone = this.$store.state.timeZone;
        // 进行时间格式化
        res.data.forEach(v => {
            v.CreatedAt = moment.tz(v.CreatedAt, serverTimezone).tz(localTimezone).format('YYYY-MM-DD HH:mm:ss');
            v.UpdatedAt = moment.tz(v.UpdatedAt, serverTimezone).tz(localTimezone).format('YYYY-MM-DD HH:mm:ss');
        })
        this.kLineData = res.data
        if (this.showChart) {
          this.initChart()
        }
      })
    },
    initChart() {
      // if (this.echartsObj) {
      //   return
      // }
      var echartsObj = echarts.init(document.getElementById('echartDom'));

      let data = this.kLineData.map(v => {
        return [v.Open, v.Close, v.Low, v.High]
      })
      this.xDataList = this.kLineData.map(v => {
        return v.UpdatedAt
      })
      this.dataBak = [].concat(data)

      data.reverse()
      this.dataBak = data
      this.xDataList.reverse()
      this.startValue = data.length - 20
      this.endValue = data.length - 1
      let option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            z: 10,
            crossStyle: {
              // color: '#F7A600'
            }
          },
          backgroundColor: 'rgba(0, 0, 0, .7)',
          textStyle: {
            color: '#919eae'
          },
          borderWidth: 1,
          borderColor: '#919eae',
          formatter: (params) => {
            let index = data.length - params[0].dataIndex - 1
            let day = this.kLineData[index].UpdatedAt.substring(11)
            let open = parseFloat(data[index][0])
            let close = parseFloat(data[index][1])
            let low = parseFloat(data[index][2])
            let high = parseFloat(data[index][3])
            let vol = parseFloat(parseFloat(this.kLineData[index].Volume).toFixed(4))
            let result = '<span class="chart-tooltip "><span>' + this.i18n.t('chart_page.time') + '</span>' + day + '</span>' +
                          '<span class="chart-tooltip "><span>' + this.i18n.t('chart_page.open') + '</span>' + open + '</span>' +
                          '<span class="chart-tooltip "><span>' + this.i18n.t('chart_page.high') + '</span>' + high + '</span>' +
                          '<span class="chart-tooltip "><span>' + this.i18n.t('chart_page.low') + '</span>' + low + '</span>' +
                          '<span class="chart-tooltip "><span>' + this.i18n.t('chart_page.close') + '</span>' + close + '</span>'+
                          '<span class="chart-tooltip "><span>' + this.i18n.t('chart_page.vol') + '</span>' + vol + '</span>'
            return result
          }
        },
        grid: {
          top: 40,
          left: 0,
          right: 30,
          bottom: 0,
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: this.xDataList,
          boundaryGap: false,
          splitLine: { show: false },
          axisLine: {
            onZero: false,
            show: false,
            lineStyle: {
              // color: '#202123',
              width: 1
            }
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: true,
            color: '#ADB1B8'
          },
          min: 'dataMin',
          max: 'dataMax'
        },
        yAxis: {
          position: 'right',
          scale: true,
          axisLabel: {
            show: true,
            color: '#ADB1B8'
          },
          splitLine: {
            lineStyle: {
              color: '#202123',
              width: 1
            }
          }
        },
        dataZoom: [
          {
            type: 'inside',
            filterMode: 'filter',
            startValue: this.startValue,
            endValue: this.endValue
          },
        ],
        series: this.getSerie(data)
      };
      // console.log("this.resetDataZoom")
      echartsObj.on('dataZoom', this.resetDataZoom);
      echartsObj.on('mouseup', this.hideTooltip);
      echartsObj.setOption(option)
      this.echartsObj = echartsObj
    },
    changeTime(data, isMore) {
      this.echartsTime = data.time
      if (isMore) {
        this.showMore = false
        this.echartsTimeName = data.text
      } else {
        this.echartsTimeName = ''
      }
      this.init()
    },
    showMoreBox() {
      this.showMore = !this.showMore
    },
    cancelHide() {
      clearTimeout(this.timer)
    },
    hideTooltip() {
      this.timer = setTimeout(() => {
        this.echartsObj.dispatchAction({
          type: 'hideTip',
        })
      }, 2000)
    },  
    resetDataZoom(e) {
      let start = e.batch[0].start
      let end = e.batch[0].end
      let index = this.xDataList.length - 1
      let startIndex = Math.round(start * index / 100)
      let endIndex = Math.round(end * index / 100)
      
      let maxVal = 0
      let minVal = 10000000
      let data = this.dataBak.slice(startIndex, endIndex)

      data.forEach(v => {
        if (v[3] > maxVal) {
          maxVal = v[3]
        }
        if (v[2] < minVal) {
          minVal = v[2]
        }
      })      
      
      let maxIndex = data.findIndex(v => {
        return v[3] == maxVal
      })
      let minIndex = data.findIndex(v => {
        return v[2] == minVal
      })

      maxIndex += startIndex
      minIndex += startIndex

      let isMaxLeft =  maxIndex < (startIndex + endIndex) / 2
      let isMinLeft =  minIndex < (startIndex + endIndex) / 2

      let series = null
      if (this.isMinLeft != isMinLeft || this.isMaxLeft != isMaxLeft) {
        series = this.getSerie(this.dataBak)
        this.isMinLeft = isMinLeft
        this.isMaxLeft = isMaxLeft
        let option = {
          series
        }
        this.echartsObj.setOption(option)
      }
    },
    getSerie(data) {
      return [
        {
          type: 'candlestick',
          itemStyle: {
            color: '#20B26C',
            color0: '#EF454A',
            borderColor: '#20B26C',
            borderColor0: '#EF454A',
          },
          barMaxWidth: 10,
          data: data,
          markPoint: {
            data: [
              {
                silent: true,
                name: 'highest value',
                type: 'max',
                valueDim: 'highest',
                symbol: 'image://' + this.leftArrow,
                symbolSize: [40, 1],
                symbolOffset: this.isMaxLeft ? [20, 0] : [-20, 0],
                label: {
                  color: '#fff',
                  show: true,
                  fontSize: 10,
                  position: this.isMaxLeft ? 'right' : 'left'
                }
              },
              {
                silent: true,
                name: 'lowest value',
                type: 'min',
                valueDim: 'lowest',
                symbol: 'image://' + this.leftArrow,
                symbolSize: [40, 1],
                symbolOffset: this.isMinLeft ? [20, 0] : [-20, 0],
                label: {
                  color: '#fff',
                  show: true,
                  fontSize: 10,
                  position: this.isMinLeft ? 'right' : 'left'
                }
              },
            ],
            tooltip: {
              formatter: function (param) {
                return param.name + '<br>' + (param.data.coord || '');
              }
            }
          },
        }
      ]
    }
  }
}
</script>