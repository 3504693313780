<template>
  <div class=" top-bar-page network-page">
    <top-bar :title="i18n.t('network.title')" class="black" parentId=".network-page"  @back="back"></top-bar>

    <div class="network-main">
      <div class="info-line">
        <div class="label">{{i18n.t('network.label1')}}</div>
        <div class="info">4.10.0.2023012010</div>
      </div>
      <div class="info-line">
        <div class="label">{{i18n.t('network.label2')}}</div>
        <div class="info">name/4.10.0/ios</div>
      </div>
      <div class="info-line">
        <div class="label">{{i18n.t('network.label3')}}</div>
        <div class="info">iPhone_14_pro ios 16.1.1</div>
      </div>
      <div class="info-line">
        <div class="label">{{i18n.t('network.label4')}}</div>
        <div class="info">Hongkong</div>
      </div>
      <div class="info-line">
        <div class="label">UTC</div>
        <div class="info">2023-02-03 12:50:11.1656902</div>
      </div>
      <div class="info-line">
        <div class="label">CTS</div>
        <div class="info">false</div>
      </div>
      <div class="info-line">
        <div class="label">Api http(channel)</div>
        <div class="info">
          App_channel_1
          <span class="green"> 251.0 ms</span>
        </div>
        <div class="info">
          App_channel_2
          <span class="green"> 251.0 ms</span>
        </div>
        <div class="info">
          App_channel_3
          <span class="green"> 251.0 ms</span>
        </div>
      </div>
      <div class="info-line">
        <div class="label">Api ws</div>
        <div class="info">
          App_ws1           
          <span class="space"> connecting</span>
          <span class="space"> 92 ms</span>
        </div>
        <div class="info">
          App_ws2           
          <span class="space"> connecting</span>
          <span class="space"> 92 ms</span>
        </div>
        <div class="info">
          App_ws3           
          <span class="space"> connecting</span>
          <span class="space"> 92 ms</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import topBar from '@/components/topBar'
export default {
  components: {
    topBar
  },
  data() {
    return {
      logoUrl: require('../../assets/imgs/logo.png'),
      rightUrl: require('../../assets/imgs/icon-arrow-right.png'),
      refreshUrl: require('../../assets/imgs/refresh.png'),
      wifiUrl: require('../../assets/imgs/icon-wifi.png'),
    }
  },
  computed: {
    appName() {
      return this.$store.state.appName
    }
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    toPath(path) {
      if (path) {
        this.$router.push({
          name: path
        })
      }
    }
  }
}
</script>