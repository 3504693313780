<template>
	<div class="log-detail-page top-bar-page">
    <top-bar :title="i18n.t('logDetail.title')" class="black" parentId=".log-detail-page"  @back="back"></top-bar>

    <div class="log-detail-main">
      <div class="sub-title flex-center-between">
        <div class="flex-center-start">
          <div class="label">{{detailData.Currency ? detailData.Currency.Name : ''}}/USDT</div>
          <div class="status">{{i18n.t('logDetail.label1')}}</div>
        </div>
        <div class="desc" @click="toPath('trade')">
          {{i18n.t('logDetail.label2')}}
          <img :src="rightUrl" alt="">
        </div>
      </div>

      <div class="info-line flex-center-between">
        <div class="label">{{i18n.t('logDetail.label3')}}</div>
        <div class="info">
          <span class="green">{{getTypeName(detailData.Type)}}</span>
        </div>
      </div>

      <div class="info-line flex-center-between">
        <div class="label">{{i18n.t('logDetail.label4')}}</div>
        <div class="info">{{parseFloat(detailData.Practical).toFixed(4)}} USDT</div>
      </div>
      
      <div class="info-line flex-center-between">
        <div class="label">{{i18n.t('logDetail.label5')}}</div>
        <div class="info">{{parseFloat(detailData.DebtBefore).toFixed(4)}} USDT</div>
      </div>
      <div class="info-line flex-center-between">
        <div class="label">{{i18n.t('logDetail.label6')}}</div>
        <div class="info">{{parseFloat(detailData.DebtAfter).toFixed(4)}} USDT</div>
      </div>
<!--      <div class="info-line flex-center-between">-->
<!--        <div class="label">{{i18n.t('logDetail.label7')}}</div>-->
<!--        <div class="info">{{detailData.Digest}}</div>-->
<!--      </div>-->
      <!-- <div class="info-line flex-center-between">
        <div class="label">{{i18n.t('logDetail.label8')}}</div>
        <div class="info">1.971000 USDT</div>
      </div> -->
      <div class="info-line flex-center-between">
        <div class="label">{{i18n.t('logDetail.label9')}}</div>
        <div class="info">{{detailData.CreatedAt}}</div>
      </div>
      <div class="info-line flex-center-between">
        <div class="label">{{i18n.t('logDetail.label10')}}</div>
        <div class="info">{{detailData.UpdatedAt}}</div>
      </div>
      <!-- <div class="info-line flex-center-between">
        <div class="label">{{i18n.t('logDetail.label11')}}</div>
        <div class="info flex-center">
          <span>04430080</span>
          <img class="copy-icon" :src="copyUrl" alt="" @click="copyData('04430080')">
        </div>
      </div> -->

      <!-- <div class="title">{{i18n.t('logDetail.label12')}}</div>

      <div class="info-line flex-center-between">
        <div class="label">{{i18n.t('logDetail.label13')}}</div>
        <div class="info">0.3945 USDT</div>
      </div>
      <div class="info-line flex-center-between">
        <div class="label">{{i18n.t('logDetail.label7')}}</div>
        <div class="info">5.00 ADA</div>
      </div>
      <div class="info-line flex-center-between">
        <div class="label">{{i18n.t('logDetail.label8')}}</div>
        <div class="info">1.971000 USDT</div>
      </div>
      <div class="info-line flex-center-between">
        <div class="label">{{i18n.t('logDetail.label14')}}</div>
        <div class="info">0.005 ADA</div>
      </div>
      <div class="info-line flex-center-between">
        <div class="label">{{i18n.t('logDetail.label15')}}</div>
        <div class="info">2023/02/18 15:15:18</div>
      </div>
      <div class="info-line flex-center-between">
        <div class="label">{{i18n.t('logDetail.label16')}}</div>
        <div class="info">96704768 / Taker</div>
      </div> -->

    </div>
   
	</div>
</template>
<script>
import Clipboard from 'clipboard';
import topBar from '@/components/topBar'
export default {
  components: {
    topBar
  },
  data() {
		return {
      form: {
        bit: '',
        type: ''
      },
			rightUrl: require('../../assets/imgs/icon-arrow-right.png'),
			copyUrl: require('../../assets/imgs/icon-copy.png'),
			detailData: {}
		}
	},
  computed: {
    typeList() {
      return this.$store.state.assestType
    }
  },
  mounted() {
    let data = sessionStorage.getItem('logData')
    if (data) {
      this.detailData = JSON.parse(data)
    }
    console.log(111, this.detailData, this.typeList)
  },
	methods: {
		back() {
			this.$router.go(-1)
		},
    toPath(path) {
      this.$router.push({
        name: path
      })
    },
    copyData(data) {
      const clipboard = new Clipboard('.copy-icon', {text: () => data});
      this.$store.state.successMsg = this.i18n.t('assestLog.success')
    },
    getTypeName(type) {
      let list = this.typeList.filter(v => {
        return v.key == type
      })
      if (list.length > 0) {
        return list[0].name
      } else {
        return type
      }
    }
	}
}
</script>