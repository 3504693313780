<template>
  <div class="role-page">
    <top-bar :title="i18n.t('notice.title')" parentId=".notice-page" :right-text="i18n.t('notice.label1')" @back="back" @right="readAll"></top-bar>

    <div class="notice-list">
      <div class="list" v-for="(item, i) in noticeList" :key="i">
        <div class="time">{{item.CreateAt}}</div>
        <div class="content">
          <div class="title">{{item.Title}}</div>
          <div class="detail" v-html="item.Content"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getNoticeList } from '@/api/index'
import topBar from '@/components/topBar'
export default {
  components: {
    topBar
  },
  data() {
    return {
      noticeList: []
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      getNoticeList().then(res => {
        this.noticeList = res.data.Items
      })
    },
    back() {
      this.$router.go(-1)
    },
    readAll() {

    }
  }
}
</script>