<template>
	<div class="recharge-page withdraw top-bar-page">
		<top-bar :title="i18n.t('addAddress.title')" class="black" parentId=".activity-page" right-icon="help" @back="back"></top-bar>


		<div class="form-label">{{i18n.t('addAddress.label1')}}</div>

		<div class="recharge-list">
			<div class="list flex-center" @click="chooseRecharge">
				<div class="icon">
					<img :src="rechargeInfo.icon" alt="">
				</div>
				<div class="name flex-1">
					<span>{{rechargeInfo.name}}</span>
					<span class="desc">{{rechargeInfo.desc}}</span>
				</div>
				<div class="check">
					<img :src="rightUrl" alt="">
				</div>
			</div>
		</div>

		<div class="form-label">{{i18n.t('addAddress.label2')}}</div>

		<div class="form-input">
			<input type="text" v-model="form.address" :placeholder="i18n.t('addAddress.labelPlaceholder2')">
		</div>

		<div class="form-label">{{i18n.t('addAddress.label3')}}</div>		

		<div class="form-input">
			<input type="password" v-model="form.password" :placeholder="i18n.t('addAddress.labelPlaceholder3')">
		</div>

		<div class="bottom-btn flex-center">
			<Button  class="big" @click="submit">{{i18n.t('addAddress.btn1')}}</Button>
		</div>


     <!--币种-->
    <Popup v-model:show="showRecharge" position="bottom" lock-scroll class="rate-popup">
			<div class="rate-modal">
				<div class="title flex-center-between">
					<div>{{i18n.t('addAddress.label1')}}</div>
					<img :src="closeUrl" alt="" @click="cancel">
				</div>
				<div class="recharge-list ">
          <div class="list flex-center" v-for="(item, i) in rechargeList" :key="i" @click="changeRecharge(item, i)">
            <div class="icon">
              <img :src="item.icon" alt="">
            </div>
            <div class="name flex-1">
              <span>{{item.name}}</span>
              <span class="desc">{{item.desc}}</span>
            </div>
            <div class="check">
              <img :src="rechargeIndex == i ? radioUrl2 : radioUrl" alt="">
            </div>
          </div>
          <div class="btn">
            <Button :disabled="rechargeIndex === -1" @click="submitChange">{{i18n.t('addAddress.btn2')}}</Button>
          </div>
				</div>

			</div>
		</Popup>


	</div>
</template>
<script>
import topBar from '@/components/topBar'
import { Popup, Button } from 'vant'
import { bindWithdrawAddress } from '@/api/assest'
import { addressList } from '@/api/setting'
export default {
	components: {
    Popup,
    Button,
		topBar
  },
  data() {
		return {
			form: {
				address: '',
				protocol: '',
				pasword: '',
			},
			closeUrl: require('../../assets/imgs/icon-close.png'),
			backUrl: require('../../assets/imgs/icon-back.png'),
			rightUrl: require('../../assets/imgs/icon-arrow-right.png'),
			logUrl: require('../../assets/imgs/icon-log.png'),
			radioUrl: require('../../assets/imgs/icon-radio.png'),
      radioUrl2: require('../../assets/imgs/icon-radio2.png'),
			showRecharge: false,
      rechargeIndex: 0,
      rechargeList: [
				{
					icon: require('../../assets/imgs/bit1.png'),
					name: 'TRC20',
					desc: 'Tether USDT',
					key: 'USDT-TRC20'
				},
				{
					icon: require('../../assets/imgs/bit1.png'),
					name: 'ERC20',
					desc: 'Tether USDT',
					key: 'USDT-ERC20'
				},
				{
					icon: require('../../assets/imgs/bit2.png'),
					name: 'ETH',
					desc: 'Tether USDT',
					key: 'Ethereum'
				},
				{
					icon: require('../../assets/imgs/bit3.png'),
					name: 'BTC',
					key: 'Bitcoin'
				},
			],
			rechargeInfo: {},
			rechargeInfoBak: {},
			addressData: []
		}
	},
  mounted() {
		this.init()
	
  },
	methods: {
		init() {
			addressList({
        current: 1,
        pageSize: 30
      }).then(res => {
        let data = res.data.Items
				this.rechargeList = this.rechargeList.filter(v => {
					let index = data.findIndex(val => {
						return val.Protocol == v.key
					})
					return index === -1
				})
				if (this.rechargeList.length > 0) {
					this.rechargeInfo = this.rechargeList[0]
					this.form.protocol = this.rechargeList[0].key
				}
      })
    },
		back() {
			this.$router.go(-1)
		},
		chooseRecharge() {
      this.showRecharge = true
    },
    changeRecharge(data, i) {
      this.rechargeIndex = i
			this.form.protocol = data.key
    },
		cancel() {
			this.showRecharge = false
		},
		submitChange() {
			this.rechargeInfo = this.rechargeList[this.rechargeIndex]
			this.showRecharge = false
		},
		submit() {
			if (!this.form.address) {
				this.$store.state.warningMsg = this.i18n.t('addAddress.labelPlaceholder2')
				return
			}
			if (!this.form.password) {
				this.$store.state.warningMsg =  this.i18n.t('addAddress.labelPlaceholder3')
				return
			}

			let form = new FormData()
			form.append('Address', this.form.address)
			form.append('Protocol', this.form.protocol)
			form.append('Password', this.form.password)
			bindWithdrawAddress(form).then(res => {
				if (res.code == 0) {
					this.$store.state.warningMsg = this.i18n.t('common.success')
					setTimeout(() => {
						this.back()
					}, 1500);
				} else {
				  this.$store.state.errorMsg = res.msg
				}
			})
		}
	}
}
</script>