import fetch from "../utils/fetch";
import qs from "qs";

// 币种列表
export function getCoinList(data) {
  return fetch({
    url: '/api/user/coin-lists',
    method: 'post',
    data: qs.stringify(data)
  });
}
// 最新行情
export function quoteList(data) {
  return fetch({
    url: '/api/user/quotes-lists',
    method: 'post',
    data: data
  });
}
// 轮播图
export function getBannerList(data) {
  return fetch({
    url: '/api/user/carousel-list',
    method: 'post',
    data: data
  });
}
// 消息
export function getNoticeList(data) {
  return fetch({
    url: '/api/user/notice',
    method: 'post',
    data: data
  });
}