<template>
  <div class="setting-container address-page top-bar-page ">
    <top-bar :title="i18n.t('address.title')" class="black" parentId=".address-page"  @back="back"></top-bar>
    <div class="top-main pb-30" v-if="cardList.length > 0">

      <div class="card-list" >
        <div class="card flex-start" :class="`card-${getCardType(item.Protocol)}`" v-for="(item, i) in cardList" :key="i" @click="chooseCard(item)">
          <div class="icon">
            <img :src="iconObj[item.Protocol]" alt="">
          </div>
          <div class="flex-1 w-1">
            <div class="name">{{item.Protocol}}</div>
            <div class="address">{{item.Address}}</div>
          </div>
        </div>
      </div>

     

      <div class="btn-box"  >
        <div class="submit-btn  flex-center" @click="toAdd" v-if="!hideAdd">
          <img :src="addUrl" alt="">
          <span>{{i18n.t('address.btn')}}</span>
        </div>
      </div>
    </div>

    <div class="add-card flex-column-center" v-if="cardList.length == 0" @click="toAdd">
      <img :src="addUrl" alt="">
      <span>{{i18n.t('address.btn')}}</span>
    </div>

  </div>
</template>
<script>
import { Button } from 'vant'
import { addressList } from '@/api/setting'
import topBar from '@/components/topBar'
export default {
  components: {
    topBar,
    Button
  },
  data() {
    return {
      email: 'akdfjlaskjaskjdfasdj@163.com',
      backUrl: require('../../assets/imgs/icon-back.png'),
      addUrl: require('../../assets/imgs/icon-add3.png'),
      iconObj:{
        'USDT-TRC20':  require('../../assets/imgs/bit1.png'),
        'USDT-ERC20':  require('../../assets/imgs/bit1.png'),
        'Ethereum':  require('../../assets/imgs/bit2.png'),
        'Bitcoin':  require('../../assets/imgs/bit3.png'),
      },
      cardList: [],
      pageType: '',
      rechargeList: ['USDT-TRC20', 'USDT-ERC20', 'Ethereum', 'Bitcoin'],
      hideAdd: false
    }
  },
  mounted() {
    this.pageType = this.$route.query.type
    this.init()
  },
  methods: {
    init() {
      addressList({
        current: 1,
        pageSize: 30
      }).then(res => {
        this.cardList = res.data.Items

        let otherList = this.rechargeList.filter(v => {
					let index = this.cardList.findIndex(val => {
						return val.Protocol == v
					})
					return index === -1
				})

        if (otherList.length == 0) {
          this.hideAdd = true
        }
      })
    },
    back() {
      this.$router.go(-1)
    },
    getCardType(type) {
      let index = 1
      switch(type) {
        case 'USDT-TRC20': index = 1; break;
        case 'USDT-ERC20': index = 2; break;
        case 'Ethereum': index = 3; break;
        case 'Bitcoin': index = 4; break;
      }
      return index
    },
    toAdd() {
      this.$router.push({
        name: 'addAddress'
      })
    },
    chooseCard(data) {
      if (this.pageType == 'withdraw') {
        sessionStorage.setItem('withdrawInfo', JSON.stringify(data))
        this.back()
      }
    }
  }
}
</script>