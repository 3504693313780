import fetch from "../utils/fetch";

// 登录
export function login(data) {
  return fetch({
    url: '/api/user/login',
    method: 'Post',
    data: data
  });
}
// 手机号登录
export function phoneLogin(data) {
  return fetch({
    url: '/api/user/login-phone',
    method: 'Post',
    data: data
  });
}
// 注册
export function register(data) {
  return fetch({
    url: '/api/user/reg',
    method: 'Post',
    data: data
  });
}
//发送验证码
export function sendCode(data) {
  return fetch({
    url: '/api/user/send-email',
    method: 'Post',
    data: data
  });
}
//发送短信验证码
export function sendSms(data) {
  return fetch({
    url: '/api/user/send-sms',
    method: 'Post',
    data: data
  });
}
// 检验验证码
export function checkEmailCode(data) {
  return fetch({
    url: '/api/user/check-code', 
    method: 'Post',
    data: data
  });
}
// 检验手机验证码
export function checkPhoneCode(data) {
  return fetch({
    url: '/api/user/check-code-phone', 
    method: 'Post',
    data: data
  });
}
// 忘记密码
export function resetPassword(data) {
  return fetch({
    url: '/api/user/reset-password', 
    method: 'Post',
    data: data
  });
}
// 服务条款、隐私策略
export function userArticle(data) {
  return fetch({
    url: '/api/user/article', 
    method: 'Post',
    data: data
  });
}
