<template>
  <div class="rate-modal">
    <div class="title flex-center-between">
      <div>{{i18n.t('trade.status5')}}</div>
      <img :src="closeUrl" alt="" @click="cancel">
    </div>
    <div class="win-setting">
      <div class="action-btn flex">
        <div class="btn flex-center" :class="tabIndex == '1' ? 'active' : ''" @click="changeTab('1')">{{i18n.t('trade.label21')}}</div>
        <div class="btn flex-center" :class="tabIndex == '2' ? 'active' : ''" @click="changeTab('2')">{{i18n.t('trade.label22')}}</div>
      </div>

      <div class="win-info mt-15 mb-15" >
        <div class="info-line flex-center-between">
          <div class="label">{{i18n.t('trade.th10')}}</div>
          <div>{{parseFloat(orderData.OpenClose).toFixed(4)}}</div>
        </div>
        <div class="info-line flex-center-between">
          <div class="label">{{i18n.t('trade.label21')}}</div>
          <div>{{bitInfo.count}}</div>
        </div>
      </div>


      <div class="trade-money flex-center-between" v-show="tabIndex == 2">
        <div class="left-label">{{i18n.t('trade.label41')}}</div>
        <div class="left flex-1 flex-center-between">
          <div>
            <input type="number" inputmode="decimal" v-model="clearForm.price" :placeholder="i18n.t('trade.label41')">
          </div>
          <div class="flex-center icon">
            <img :src="minusUrl" alt="" @click="minusPrice">
            <span class="space"></span>
            <img :src="addUrl2" alt="" @click="addPrice">
          </div>
        </div>
      </div>
      <div class="trade-money flex-center-between">
        <div class="left-label">{{i18n.t('trade.label16')}}</div>
        <div class="left flex-1 flex-center-between">
          <div>
            <input type="number" inputmode="decimal" v-model="clearForm.number" :placeholder="i18n.t('trade.label16')">
          </div>
        </div>
      </div>

      <div class="flex percent-line clear">
        <div class="percent flex-center" v-for="(item, i) in percentList" :key="i" :class="clearForm.percent == item ? 'active' : ''" @click="changePercent(item, 1)">{{item}}%</div>
      </div>

      

      <div class="bottom-btn">
        <Button @click="submit">{{i18n.t('trade.btn2')}}</Button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      closeUrl: require('../../assets/imgs/icon-close.png'),
			addUrl2: require('../../assets/imgs/icon-add2.png'),
			minusUrl: require('../../assets/imgs/icon-minus.png'),
      tabIndex: 1,
      clearForm: {
        price: '',
        number: '',
        percent: ''
      },
      percentList: [10, 25, 50, 75, 100],
    }
  },
  props: {
    bitInfo: Object,
    orderData: Object,
  },
  mounted() {

  },
  methods: {
    changeTab(index) {
      this.tabIndex = index
    },
    cancel() {
      this.$emit('cancel')
    },
    addPrice() {
			let newMoney = 0.01 + parseFloat(this.clearForm.price) || 0
      this.clearForm.price = parseFloat(newMoney.toFixed(4)).toFixed(4)
		},
		minusPrice() {
			let newMoney =  parseFloat(this.clearForm.price) - 0.01 || 0
      if (newMoney < 0) {
        newMoney = 0
      }
      this.clearForm.price = parseFloat(newMoney.toFixed(4)).toFixed(4)
		},
    changePercent(val) {
			this.clearForm.percent = val
      this.clearForm.number = (this.orderData.OpenNum * (val / 100)).toFixed(4)
		},
    submit() {
      if (this.tabIndex == 2 && !this.clearForm.price) {
        this.$store.state.warningMsg =  this.i18n.t('trade.error6')
				return
      }
      if (!this.clearForm.number) {
        this.$store.state.warningMsg =  this.i18n.t('trade.error3')
				return
      }
      this.$emit('submit', {
        type: this.tabIndex,
        price: this.tabIndex == 1 ? this.bitInfo.count : this.clearForm.price,
        number: this.clearForm.number,
      })
    }
  }
}
</script>