<template>
<!--  <div  class=" top-bar-page kefu-page">-->
<!--    <top-bar :title="i18n.t('common.kefu')" class="black" parentId=".kefu-page"  @back="back"></top-bar>-->
<!--  </div>-->
<!--    <iframe class="iframe-page" :src="kefuUrl" frameborder="0"></iframe>-->

</template>
<script>
import "@/utils/sdk.js"
import topBar from '@/components/topBar'
import { customerList } from '@/api/setting'
import {getUser, getUserId} from "@/utils/auth";
export default {
  components: {
    topBar
  },
  data() {
    return {
      kefuUrl: null
    }
  },
  mounted() {
    this.kefuUrl = window.localStorage.getItem("link")
    if(this.kefuUrl == null) {
      this.init()
    } else {
      this.meiqia()
    }
    // this.init()
  },
  methods: {
    runMeiqiaScript() {
      const script = document.createElement('script');
      script.async = true;
      script.charset = 'UTF-8';
      script.src = 'https://static.meiqia.com/dist/meiqia.js';
      document.head.appendChild(script);
      window._MEIQIA = window._MEIQIA || function() {
        (window._MEIQIA.a = window._MEIQIA.a || []).push(arguments);
      };
    },
    back() {
      this.$router.go(-1)
      window.$chatwoot.toggleBubbleVisibility('hide');
    },
    meiqia(){
      this.runMeiqiaScript()
      const match = this.kefuUrl.match(/eid=([^&]+)/);
      const eidValue = match ? match[1] : null;
      _MEIQIA('entId', eidValue);
      _MEIQIA('withoutBtn');
      _MEIQIA('showPanel');
      _MEIQIA('language','en')
      // 传递顾客信息
      _MEIQIA('metadata', {
        ID: getUserId(), // 美洽默认字段
        name: getUser(), // 美洽默认字段
        username: getUser(), // 美洽默认字段
      });
      let that = this
//点击关闭按钮后的回调
      _MEIQIA('beforeCloseWindow', function() {
        // alert('点击了关闭按钮');
        // 返回
        that.$router.push({
          name: 'index'
        })
      });
    },
    init(){
        //客服
        customerList().then(res => {
          let that = this
            for(var i in res.data.Items) {
                let data = res.data.Items[i]
                if(data.Type === 'meiqia') {
                  this.kefuUrl = data.Link
                  this.meiqia()
                  break
                }
            }
            // console.log(this.kefuUrl)
            // this.kefuUrl = res.data.Items.filter(item => item.type === '"meiqia"').Link
        })
    },
  }
}
</script>