<template>
  <div class="assest-page account-page top-bar-page">

    <top-bar :title="i18n.t(`account.title${pageType}`)"  parentId=".account-page"  class="black" right-icon="icon" @back="back"></top-bar>

    <div class="account-main">
      <div class="statistics-box">
        <div class="flex-center-start label">
          <span>{{i18n.t('account.label1')}}</span>
          <img :src="showMoney ? eyeUrl2 : eyeUrl" alt="" @click="changeMoneyType">
        </div>

        <div class="flex-end-start money-info" >
          <div>{{moneyStr}}</div>
          <div class="flex-center money-type" >
          <!-- <div class="flex-center money-type" @click="changeBit"> -->
            <span>{{bitName}}</span>
            <!-- <img :src="downUrl" alt=""> -->
          </div>
        </div>

        <div class="desc mt-10">≈ {{showMoney ? bitMoney : '****'}} BTC</div>

        <div class="desc mt-10" v-if="pageType != 5">
          {{pageType == 4 ? i18n.t('finance.label6') : (i18n.t(`account.label${pageType == 5 ? 5 : (pageType == 2 ? 11 : 2)}`))}}
        </div>

        <div class="desc mt-5" v-if="pageType == 2">
          <span class="count">{{profitMoney}} USDT </span>
          <span>≈ {{profitBitMoney}} BTC</span>
        </div>
        <div class="desc mt-5" v-if="pageType == 4">
          <span class="count">{{todayProfit}} USDT </span>
          <span>≈ {{todayProfitBit}} BTC</span>
        </div>
        <div class="desc mt-5" v-if="pageType == 1 || pageType == 3">
          <span class="count">0.0000 USDT </span>
          <span>≈ 0.00000 BTC</span>
        </div>
      </div>
    </div>

    <div class="tab-list flex-center">
      <div class="tab flex-1 flex-column-center" @click="toPath('recharge')">
        <img :src="rechargeUrl" alt="">
        <span>{{this.i18n.t('account.tab1')}}</span>
      </div>
      <div class="tab flex-1 flex-column-center" @click="toPath('withdraw')" v-if="pageType != 4">
        <img :src="withdrawUrl" alt="">
        <span>{{this.i18n.t('account.tab2')}}</span>
      </div>
      <div class="tab flex-1 flex-column-center" @click="toPath('transfer')" v-if="pageType != 4">
        <img :src="transferUrl" alt="">
        <span>{{this.i18n.t('account.tab3')}}</span>
      </div>
      <div class="tab flex-1 flex-column-center" @click="toPath('finance')" v-if="pageType == 4">
        <img :src="moneyUrl" alt="">
        <span>{{this.i18n.t('account.tab4')}}</span>
      </div>
    </div>

    <div class="bit-modal">
      <div class="bit-tab-list flex-center-start" v-show="pageType == 4">
        <div class="tab" v-for="(item, i) in bitTabList" :class="bitTabIndex == i ? 'active' : ''" :key="i" @click="changeBitTab(i)">{{item}}</div>
      </div>
      <div class="bit-tab-list flex-center-start" v-show="pageType == 5">
        <div class="tab" v-for="(item, i) in bitTabList2" :class="bitTabIndex == i + 1 ? 'active' : ''" :key="i" @click="changeBitTab(i + 1)">{{item}}</div>
      </div>
      <div class="flex-center-between search-lline" v-show="(pageType == 4 && bitTabIndex == 1) || pageType != 4">
        <div class="flex-center" @click="changeShowType">
          <img :src="showZero ? checkUrl : checkUrl2" alt="">
          <span>{{i18n.t('account.label3')}}</span>
        </div>
        <div>
          <Search v-model="form.keyword" :placeholder="i18n.t('account.label4')" @clear="clearSearch" @search="searchBit" @input="searchBit"></Search>
        </div>
      </div>

      <!--资金加密货币tab-->
      <div class="bit-list rankList clear" v-show="pageType == 5">
        <div class="list clear rank flex-center-between" v-for="(item, i) in bitList2" :key="i">
          <div class="icon">
            <img :src="`/upload/${item.Currency.Logo}`" alt="">
          </div>
          <div class="flex-1">
            <div class="flex-center-between name">
              <div>
                {{item.Currency.Name}}
              </div>
              <div>{{parseFloat(item[getUserKey()]).toFixed(4)}}</div>
            </div>
            <div class="flex-center-between desc">
              <div>{{item.Currency.Remark}}</div>
              <div class="percent" >
                {{(parseFloat(item[getFeeKey()]) + parseFloat(item[getUserKey()])).toFixed(4)}}
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--旧样式-->
      <!-- <div class="bit-list" v-show="(pageType == 4 || pageType == 5) && bitTabIndex == 1 ">
        <div class="list" v-for="(item, i) in bitList" :key="i">
          <div class="bit-title flex-center-start">
            <img :src="`/upload/${item.Currency.Logo}`" alt="">
            <span>{{item.Currency.Name}}</span>
            <span class="label">{{item.Currency.ChineseName}}</span>
          </div>
          <div class="flex-center-between th">
            <div>{{i18n.t('account.label5')}}</div>
            <div>{{i18n.t('account.label6')}}</div>
          </div>
          <div class="flex-center-between td">
            <div>{{item[getUserKey()]}}</div>
            <div>{{(parseFloat(item[getFeeKey()]) + parseFloat(item[getUserKey()])).toFixed(8)}}</div>
          </div>
        </div>
      </div> -->

      <!--现货tab-->
      <div class="bit-list rankList clear" v-show="bitTabIndex == 1 && pageType != 5">
          <template v-for="(item, i) in bitList">
              <template v-if="(pageType == 2 || pageType == 5) && item.Currency.Name == 'USDT'">

              </template>
              <template v-else>
                  <div  class="list clear rank flex-center-between"  :key="i">
                      <div class="icon">
                          <img :src="`/upload/${item.Currency.Logo}`" alt="">
                      </div>
                      <div class="flex-1">
                          <div class="flex-center-between name">
                              <div>
                                  {{item.Currency.Name}}
                              </div>
                              <!--合约展示字段 投资金额-->
                              <div v-if="pageType == 2">{{item.ContractUse}}</div>
                              <!--期权展示字段 投资总额-->
                              <div v-else-if="pageType == 3">{{item.OptionUse}}</div>
                              <!--其他展示字段-->
                              <div v-else>{{parseFloat(item[getUserKey()]).toFixed(4)}}</div>
                          </div>
                          <div class="flex-center-between desc">
                              <div>{{item.Currency.Remark}}</div>
                              <!--合约展示字段 盈亏金额-->
                              <div v-if="pageType == 2" class="percent" :class="item.ContractFree > 0 ? 'green' : (item.ContractFree < 0 ? 'red' : '')">{{item.ContractFree}}</div>
                              <!--期权展示字段 盈亏金额-->
                              <div v-else-if="pageType == 3" class="percent" :class="item.OptionFree > 0 ? 'green' : (item.OptionFree < 0 ? 'red' : '')">{{item.OptionFree}}</div>
                              <!--现货-->
                              <div v-else-if="pageType == 1" class="percent" >
                                  {{resetSpotMoney(item[getUserKey()], item.Currency.ID)}} USDT
                              </div>
                              <!--其他展示字段-->
                              <div v-else class="percent" >
                                  {{(parseFloat(item[getFeeKey()]) + parseFloat(item[getUserKey()])).toFixed(4)}}
                              </div>
                          </div>
                      </div>
                  </div>
              </template>

          </template>

      </div>

      <div class="bit-list" v-show="pageType == 4 && bitTabIndex == 0">
         <div class="list big" v-for="(item, i) in ownerFinanceList" :key="i">
          <div class="bit-title flex-center-start">
            <img :src="`/upload/${item.FinancialSetup.Currency.Logo}`" alt="">
            <span>{{item.FinancialSetup.Currency.Name}}</span>
            <span class="label">{{item.FinancialSetup.Currency.ChineseName}}</span>
          </div>
          <div class="flex-center-between th">
            <div>{{i18n.t('account.label7')}}</div>
            <div>{{i18n.t('account.label8')}}</div>
          </div>
          <div class="flex-center-between td">
            <div>{{item.OrderAmount}}</div>
            <div class="status" >{{(item.Status == 0 ? i18n.t('account.label8_1') : i18n.t('account.label8_2'))}}</div>
          </div>
          <div class="flex-center-between th">
            <div>{{i18n.t('account.label9')}}</div>
            <div>{{i18n.t('account.label10')}}</div>
          </div>
          <div class="flex-center-between td">
            <div>{{item.FinancialSetup.ProfitRatio}}%</div>
            <div class="label" >{{item.IsRegular == 1 ? i18n.t('account.label10_1') : i18n.t('account.label10_2')}}</div>
          </div>
        </div>
      </div>
      <!-- <div class="bit-list" v-show="pageType == 4 && bitTabIndex == 0">
         <div class="list big" v-for="(item, i) in ownerFinanceList" :key="i">
          <div class="bit-title flex-center-start">
            <img :src="`/upload/${item.Currency.Logo}`" alt="">
            <span>{{item.Currency.Name}}</span>
            <span class="label">{{item.Currency.ChineseName}}</span>
          </div>
          <div class="flex-center-between th">
            <div>{{i18n.t('account.label7')}}</div>
            <div>{{i18n.t('account.label8')}}</div>
          </div>
          <div class="flex-center-between td">
            <div>{{item.OrderAmount}}</div>
            <div class="status" >{{item.Status == -1 ? i18n.t('account.label8_3') : (item.Status == 0 ? i18n.t('account.label8_1') : i18n.t('account.label8_2'))}}</div>
          </div>
          <div class="flex-center-between th">
            <div>{{i18n.t('account.label9')}}</div>
            <div>{{i18n.t('account.label10')}}</div>
          </div>
          <div class="flex-center-between td">
            <div>{{item.ProfitRatio}}%</div>
            <div class="label" >{{item.IsRegular == 1 ? i18n.t('account.label10_1') : i18n.t('account.label10_2')}}</div>
          </div>
        </div>
      </div> -->
     
    </div>

    
    <bitPage v-show="showBitPage" @back="cancel" :activeName="bitName" @change="changeBitName"></bitPage>

   
  </div>
</template>
<script>
import topBar from '@/components/topBar'
import { Popup, Button, Search } from 'vant'
import { myAssets, wallet, allTrades, contractProfit} from '@/api/assest'
import { financeList, financialStatistics } from '@/api/finance'
import bitPage from './bit'
export default {
  components: {
    bitPage,
    Popup,
    Button,
    Search,
    topBar
  },
  data() {
    return {
      form: {
        keyword: ''
      },
      backUrl: require('../../assets/imgs/icon-back.png'),
			helpUrl: require('../../assets/imgs/icon-help2.png'),
			logUrl: require('../../assets/imgs/icon-log.png'),
      closeUrl: require('../../assets/imgs/icon-close.png'),
      eyeUrl: require('../../assets/imgs/icon-eye.png'),
      eyeUrl2: require('../../assets/imgs/icon-eye2.png'),
      downUrl: require('../../assets/imgs/icon-arrow-down.png'),
      rightUrl: require('../../assets/imgs/icon-arrow-right.png'),
      rechargeUrl: require('../../assets/imgs/icon-recharge2.png'),
      withdrawUrl: require('../../assets/imgs/icon-withdraw.png'),
      transferUrl: require('../../assets/imgs/icon-transfer3.png'),
      moneyUrl: require('../../assets/imgs/icon-money3.png'),
      checkUrl: require('../../assets/imgs/icon-uncheck.png'),
      checkUrl2: require('../../assets/imgs/icon-checked.png'),
      showMoney: true,
      userMoney: '0.00',
      bitMoney: '0.0000',
      bitName: 'USDT',
      bitId: '1',
      showBitPage: false,
      pageType: 1,
      showZero: true,
      bitList: [], //法币
      bitList2: [], //加密货币
      bitListBak: [],
      bitTabIndex: 1,
      // bitTabList: [this.i18n.t('account.tab5') ,this.i18n.t('account.tab6')],
      bitTabList: [this.i18n.t('account.tab5') ],
      bitTabList2: [this.i18n.t('account.tab8')],
      ownerFinanceList: [],
      allFinanceList: [],
      isAllOk: false,
      isOwnOk: false,
      profitMoney: '0.00',
      profitBitMoney: '0.00000',
      allTradesData: [],
      todayProfit: '0.00',
      todayProfitBit: '0.00000',
    }
  },
  computed: {
    moneyStr() {
      return this.showMoney ? parseFloat(this.userMoney).toFixed(4) : '****'
    }
  },
  mounted() {
    let type = this.$route.query.type
    this.pageType = type
    let bitName = localStorage.getItem('bitName')
    if (bitName) {
      this.bitName = bitName
      this.bitId = localStorage.getItem('bitId')
    }
   
    this.initTrade()
    this.initStatistics()
    if (type == 4) {
      this.initFinance()
      this.bitTabIndex = 0
    } 
  },
  methods: {
    initProfit() {
      contractProfit().then(res => {
        this.profitMoney = res.data.total
        if (parseFloat(this.userMoney) && parseFloat(this.profitMoney) && parseFloat(this.bitMoney)) {
          this.profitBitMoney = (parseFloat(this.profitMoney) * parseFloat(this.bitMoney) / parseFloat(this.userMoney)).toFixed(8)
        } else {
          this.profitBitMoney = '0.00000000'
        }
      })
    },
    initStatistics() {
      financialStatistics().then(res => {
        this.todayProfit = res.data.TodayProfit
        this.initBitMoney()
      })
    },
    initBitMoney() {
      if (parseFloat(this.userMoney) && parseFloat(this.todayProfit) && parseFloat(this.bitMoney)) {
        this.todayProfitBit = (parseFloat(this.todayProfit) * parseFloat(this.bitMoney) / parseFloat(this.userMoney)).toFixed(8)
      } else {
        this.todayProfitBit = '0.00000000'
      }
    },
    initTrade() {
      allTrades().then(res => {
        this.allTradesData = res.data
        this.initAssets()
      })
    },
    initAssets() {
      myAssets({
        Unit: this.bitName
      }).then(res => {
        
        let data = res.data
        switch(this.pageType + '') {
          case '1': this.userMoney = data.Spot; break;
          case '2': this.userMoney = data.Contract; break;
          case '3': this.userMoney = data.Option; break;
          case '4': this.userMoney = data.Finance; break;
          case '5': this.userMoney = data.Capital; break;
          default: break;
        }

        if (parseFloat(data.Total) && parseFloat(data.Btc)) {
          this.bitMoney = (parseFloat(data.Btc) * parseFloat(this.userMoney) / parseFloat(data.Total)).toFixed(8)
        } else {
          this.bitMoney = '0.00000000'
        }
        if (this.pageType == 2) {
          this.initProfit()
        }
        if (this.pageType == 4) {
          this.initBitMoney()
        }
      })

      let form = new FormData()
      form.append('current', 1)
      form.append('pageSize', 10)
      let newForm = new FormData()
      newForm.append('current', 1)
      newForm.append('pageSize', 500)
      wallet(form).then(res => {
        this.bitList = res.data.Items
        let filterList = ['USDT']
        this.bitList2 = res.data.Items.filter(v => {
          return filterList.indexOf(v.Currency.Name) > -1
        })
        this.bitListBak = res.data.Items

        //再次请求，获取所有数据
        wallet(newForm).then(res => {
          this.bitList = res.data.Items.filter(v => {
            return v.Currency && v.Currency.ID
          })
          this.bitListBak = res.data.Items.filter(v => {
            return v.Currency && v.Currency.ID
          })
          this.bitList2 = res.data.Items.filter(v => {
            return filterList.indexOf(v.Currency.Name) > -1
          })
        })
      })

      // allTrades().then(res => {
        
      // })

    },
    initFinance() {
      let form = new FormData()
      form.append('current', 1)
      form.append('pageSize', 100)
      financeList().then(res => {
        this.ownerFinanceList = res.data.Items
      })
      // financeList().then(res => {
      //   this.ownerFinanceList = res.data.Items
      //   this.isOwnOk = true
      //   if (this.isAllOk) {
      //     this.resetFinanceList()
      //   }
      // })
      // financeSetupList(form).then(res => {
      //   this.allFinanceList = res.data.Items
      //   this.isAllOk = true
      //   if (this.isOwnOk) {
      //     this.resetFinanceList()
      //   }
      // })
    },
    resetFinanceList() {
      this.allFinanceList = this.allFinanceList.map(v => {
        let index = this.ownerFinanceList.findIndex(val => {
          return val.FinancialSetup.Currency.ID == v.Currency.ID
        })
        if (index > -1) {
          let data = this.ownerFinanceList[index]
          v.OrderAmount = data.OrderAmount
          v.Status = data.Status
        } else {
          v.OrderAmount = '0.00'
          v.Status = -1
        }
        return v
      })
    },
    getUserKey() {
      let key = ''
      switch (this.pageType + '') {
        case '1': key = 'SpotUse'; break;
        case '2': key = 'ContractUse'; break;
        case '3': key = 'OptionUse'; break;
        case '4': key = 'FinanceUse'; break;
        case '5': key = 'CapitalUse'; break;
        default: break;
      }
      return key
    },
    getFeeKey() {
      let key = ''
      switch (this.pageType + '') {
        case '1': key = 'SpotFree'; break;
        case '2': key = 'ContractFree'; break;
        case '3': key = 'OptionFree'; break;
        case '4': key = 'FinanceFree'; break;
        case '5': key = 'CapitalFree'; break;
        default: break;
      }
      return key
    },
    back() {
			this.$router.go(-1)
		},
    toLog() {
			this.$router.push({
				name: 'assestLog',
			})
		},
    changeMoneyType() {
      this.showMoney = !this.showMoney
    },
    changeBit() {
      this.showBitPage = true
    },
    cancel() {
      this.showBitPage = false
      this.showRecharge = false
    },
    changeBitName(data) {
      this.bitName = data.name
      this.bitId = data.key
      localStorage.setItem('bitName', this.bitName)
      localStorage.setItem('bitId', this.bitId)
      this.showBitPage = false
      this.initAssets()
    },
    resetSpotMoney(count, id) {
      let index = this.allTradesData.findIndex(v => {
        return v.CurrencyId == id
      })
      if (index > -1) {
        let price = this.allTradesData[index].Price
        return parseFloat(price * count).toFixed(4)
      } else {
        return parseFloat(count).toFixed(4)
      }
    },
    toPath(path) {
      if (path) {
        this.$router.push({
          name: path
        })
      }
    },
    clearSearch() {
      this.bitList = [].concat(this.bitListBak)
    },
    searchBit() {
      this.bitList = this.bitListBak.filter(v => {
        return v.Currency.Name.toLowerCase().indexOf(this.form.keyword) > -1
      })
    },
    changeShowType() {
      this.showZero = !this.showZero
      let filterList = ['USDT']
      if (this.form.keyword) {
        this.bitList = this.bitListBak.filter(v => {
          let userKey = this.getUserKey()
          let feeKey = this.getFeeKey()
          return (parseFloat(v[userKey]) != 0 || parseFloat(v[feeKey]) != 0 || this.showZero) && v.Currency.Name.toLowerCase().indexOf(this.form.keyword) > -1
        })
        this.bitList2 = this.bitList.filter(v => {
          return filterList.indexOf(v.Currency.Name) > -1
        })
      } else {
        this.bitList = this.bitListBak.filter(v => {
          let userKey = this.getUserKey()
          let feeKey = this.getFeeKey()
          return (parseFloat(v[userKey]) != 0 || parseFloat(v[feeKey]) != 0) || this.showZero
        })
        this.bitList2 = this.bitList.filter(v => {
          return filterList.indexOf(v.Currency.Name) > -1
        })
      }
    },
    changeBitTab(i) {
      this.bitTabIndex = i
    }
  }
}
</script>