<template>
  <div class="market-page page-top">
    <div class="top-search flex-center-between">
      <div class="label">{{i18n.t('market.title')}}</div>
      <div>
        <Search v-model="form.keyword" :placeholder="i18n.t('market.btn')" @clear="clearSearch" @search="searchMarket" @input="searchMarket"></Search>
        <!-- <Field v-model="form.keyword" :placeholder="i18n.t('market.btn')" @change="clearSearch" label="" class="search-input"/> -->
      </div>
    </div>
    <div class="rankList ">
       <List
          v-if="marketList.length > 0"
          v-model="loading"
          :finished="finished"
          :finished-text="i18n.t('common.noMore')"
          @load="onLoad">
          <bit-page :dataList="marketList" @detail="toDetail"></bit-page>
       </List>
      </div>
  </div>
</template>

<script>
import { getCoinList, quoteList } from '@/api/index'
import { Button, Search, List, Field } from 'vant';
import bitPage from '@/components/bit'
import { initWebSocket } from '@/utils/common'
export default {
  name: 'about',
  components: {
    Button,
    Search,
    List,
    Field,
    bitPage
  },
  data() {
    return {
      form: {
        keyword: ''
      },
      searchUrl: require('../../assets/imgs/icon-search.png'),
      isInit: false,
      loading: false,
      finished: false,
      page: {
        page: 1,
        limit: 100,
      },
      totalPage: 1,
      marketList: [],
      bitListBak: [],
      searchListBack: [],
      isSearch: false,
      webSocket: null,
      quoteListBak: []
    }
  },
  computed: {
    isSend() {
      return this.$store.state.isSend
    },
    bitList() {
      return this.$store.state.bitList
    }
  },
  beforeDestroy() {
    this.$store.state.isSend = false
    if (this.webSocket && this.webSocket.close) {
      this.$store.state.isClose = true
      this.webSocket.close()
    }
  },
  mounted() {
    this.initHistory()
    this.initCoin()
    this.initSocket()
  },
  methods: {
    toPath(path) {
      if (path) {
        this.$router.push({
          name: path
        })
      }
    },
    initCoin() {
      //币种列表
      if (this.bitList.length == 0) {
        this.init()
      } else {
        this.marketList = [].concat(this.bitList)
        this.bitListBak = [].concat(this.bitList)
        this.isInit = true
        this.loading = false
      }
    },
    initHistory() {
      quoteList().then(res => {
        this.quoteListBak = res.data
        this.initFirstValue()
      })
    },
    initFirstValue() {
      this.quoteListBak.forEach(v => {
        let index = this.bitListBak.findIndex(val => {
          return v.Currency.ID == val.ID
        })
        if (index > -1) {
          this.bitListBak[index].count = v.Close
        }
      })
    },
    init(isAdd) {
      this.loading = true
      getCoinList({
        current: this.page.page,
        pageSize: this.page.limit,
        Status: 1,
      }).then(res => {
        if (res.data.Items) {
          this.totalPage = res.data.Pagination.totalPage
          if (this.totalPage <= this.page.page) {
            this.finished = true
          }
          if (this.totalPage < this.page.page) {
            return
          }
          let newList = res.data.Items.map(v => {
            v.percent = 0
            let index = this.quoteListBak.findIndex(val => {
              return v.ID == val.Currency.ID
            })
            if (index > -1) {
              v.count = val.Close
            }
            return v
          })
          if (isAdd) {
            this.bitListBak = this.bitListBak.concat(newList)
          } else {
            this.bitListBak = newList
          }
          this.initFirstValue()
          this.marketList = [].concat(this.bitListBak)
         
        }
        this.isInit = true
        this.loading = false
        this.$store.state.bitList = [].concat(this.bitListBak)
      })
    },
    onLoad() {
      this.page.page += 1
      this.init(true)
    },
    searchMarket() {
      if (!this.form.keyword) {
        if (this.isSearch) {
          this.clearSearch()
        }
        return
      }
      this.finished = true
      this.isSearch = true
      this.marketList = this.bitListBak.filter(v => {
        return v.Name.toLowerCase().indexOf(this.form.keyword) > -1
      })
    },
    clearSearch() {
      this.isSearch = false
      this.finished = false
      this.marketList = [].concat(this.bitListBak)
    },
    async initSocket(){ //初始化weosocket
      let client = await initWebSocket(-1, this.websocketonmessage, this.initSocket)
      this.webSocket = client
    },
    websocketonmessage(e){ //数据接收
      let redata = {}
      if (e.data.indexOf('Welcome') > -1) {
				console.log(1111, '订阅')
				this.webSocket.send(JSON.stringify({
          action: 'subscribe',
          topic: 'all',
          message: ''
        }))
			}
      try {
        redata = JSON.parse(e.data)
        this.setListData(redata)
      }
      catch(e) {
      }
    },   
    setListData(data) {
      let index = this.bitListBak.findIndex(v => {
        return v.Name + '-USDT' == data.tags.InstId
      })
      let valObj = data.fields
      let percent = parseFloat(((valObj.Close - valObj.Yesterday) / valObj.Yesterday * 100).toFixed(2))
      if (index > -1) {
        this.bitListBak[index] = {
          ...this.bitListBak[index],
          ...valObj,
          percent,
          count: valObj.Close,
        }
      }
      if (this.form.keyword) {
        this.marketList = this.bitListBak.filter(v => {
          return v.Name.toLowerCase().indexOf(this.form.keyword) > -1
        })
      } else {
        this.marketList = [].concat(this.bitListBak)
      }
      this.$store.state.bitList = [].concat(this.bitListBak)
    },
    toDetail(data) {
      this.$router.push({
        name: 'trade',
        query: {
          name: data.Name,
          id: data.ID
        }
      })
    }
  },
}
</script>
<style lang="less">

</style>
