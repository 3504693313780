<template>
  <div class="top-bar-page gift-page">
    <top-bar :title="i18n.t('gift.title')" class="black" parentId=".gift-page" right-icon="help" @back="back"></top-bar>

    <div class="gift-main">
      <div class="top-banner">
        <div class="time flex-center" v-show="false">
          <img :src="timeUrl" alt="">
          <span>00 {{i18n.t('gift.time1')}} 12 {{i18n.t('gift.time2')}} 19 {{i18n.t('gift.time3')}}</span>
        </div>
        <div class="flex-center-between">
          <div class="info">
            <div>{{i18n.t('gift.desc1')}}</div>
            <div>{{i18n.t('gift.desc2')}}</div>
          </div>
          <div class="icon">
            <img :src="moneyUrl" alt="">
          </div>
        </div>
      </div>



      <div class="gift-list">


        <div class="list" v-for="(item, i) in WelfareCenterList" :key="i">
          <div class="title flex-center-start">
            <img :src="giftUrl" alt="">
            {{i18n.t('gift.label4')}} {{item.RewardAmount}} USDT
          </div>
          <div class="flex-start-between gift-detail">
            <div >
              <div class="percent-info">{{i18n.t('gift.label1')}} = {{item.RechargeAmount}} USDT</div>
              <div class="percent-line flex-center">
                <div class="percent-bg" :style="`width: ${TodayRechargeTotal / item.RechargeAmount * 100}%;`"></div>
                <div class="label">{{parseFloat(TodayRechargeTotal).toFixed(2)}}/{{item.RechargeAmount}} USDT</div>
              </div>
            </div>
            <div>
              <Button @click="toRecharge" v-show="false">{{i18n.t('gift.btn1')}}</Button>
              <Button  @click="receiveAReward(item)" :class="item.isGet ? 'black' : ''" :disabled="item.trade < item.tradeMax">{{item.isGet ?  i18n.t('activity.label4') : i18n.t('activity.label5')}}</Button>

            </div>
          </div>
          <div class="flex-start-between gift-detail">
            <div >
              <div class="percent-info">{{i18n.t('gift.label2')}} = {{item.TransactionVolume}} USDT</div>
              <div class="percent-line  flex-center">
                <div class="percent-bg" :style="`width: ${parseFloat(todayTransactionVolume).toFixed(2) / item.TransactionVolume * 100}%;`"></div>
                <div class="label">{{parseFloat(todayTransactionVolume).toFixed(2)}}/{{item.TransactionVolume}} USDT</div>
              </div>
            </div>
            <div>
              <Button @click="toTrade" v-show="false">{{i18n.t('gift.btn2')}}</Button>
              <Button @click="receiveAReward(item)" :class="item.isGet ? 'black' : ''" :disabled="item.trade < item.tradeMax">{{item.isGet ?  i18n.t('activity.label4') : i18n.t('activity.label5')}}</Button>

            </div>
          </div>
        </div>

      </div>
    </div>

     <Popup v-model:show="showRecharge" position="bottom" lock-scroll class="rate-popup">
			<div class="rate-modal">
				<div class="title flex-center-between">
					<div>{{i18n.t('recharge.label5')}}</div>
          <img :src="closeUrl" alt="" @click="cancel">
				</div>
				<div class="recharge-list ">
          <div class="list flex-center" v-for="(item, i) in rechargeList" :key="i" @click="changeRecharge(item, i)">
            <div class="icon">
              <img :src="item.icon" alt="">
            </div>
            <div class="name flex-1">
              <span>{{item.name}}</span>
              <span class="desc">{{item.desc}}</span>
            </div>
            <div class="check">
              <img :src="rechargeIndex == i ? radioUrl2 : radioUrl" alt="">
            </div>
          </div>
          <div class="btn">
            <Button :disabled="rechargeIndex === -1" @click="toRechargePage">{{i18n.t('recharge.btn4')}}</Button>
          </div>
				</div>

			</div>
		</Popup>
  </div>
</template>
<script>
import topBar from '@/components/topBar'
import { Button, Popup } from 'vant'
import {TodayRecharge, WelfareCenterList,WelfareCenterReceiveAReward} from '@/api/user'

export default {
  components: {
    Button,
    Popup,
    topBar
  },
  data() {
    return {
      noticeList: [],
      closeUrl:require('../../assets/imgs/icon-close.png'),
      timeUrl:require('../../assets/imgs/icon-time.png'),
      moneyUrl:require('../../assets/imgs/icon-money.png'),
      giftUrl:require('../../assets/imgs/icon-gift2.png'),
      downUrl:require('../../assets/imgs/icon-arrow-down4.png'),
      radioUrl: require('../../assets/imgs/icon-radio.png'),
      radioUrl2: require('../../assets/imgs/icon-radio2.png'),
      showMore: false,
      showRecharge: false,
      rechargeIndex: -1,
      WelfareCenterList: [],
      TodayRechargeTotal: 0,
      todayTransactionVolume: 0,
      giftList: [
        {
          title: this.i18n.t('gift.label4'),
          rechargeMax: 100,
          recharge: 10,
          tradeMax: 500,
          trade: 0
        },
        {
          title: this.i18n.t('gift.label5'),
          rechargeMax: 500,
          recharge: 0,
          tradeMax: 1000,
          trade: 0
        },
      ],
      giftListBak: [
        {
          title: this.i18n.t('gift.label6'),
          rechargeMax: 1000,
          recharge: 0,
          tradeMax: 2500,
          trade: 0
        },
        {
          title: this.i18n.t('gift.label7'),
          rechargeMax: 3000,
          recharge: 0,
          tradeMax: 5000,
          trade: 0
        },
        {
          title: this.i18n.t('gift.label8'),
          rechargeMax: 5000,
          recharge: 0,
          tradeMax: 10000,
          trade: 0
        },
      ],
      rechargeList: [
        {
					icon: require('../../assets/imgs/bit1.png'),
					name: 'TRC20',
					desc: 'Tether USDT',
					key: 'USDT-TRC20'
				},
				{
					icon: require('../../assets/imgs/bit1.png'),
					name: 'ERC20',
					desc: 'Tether USDT',
					key: 'USDT-ERC20'
				},
				{
					icon: require('../../assets/imgs/bit2.png'),
					name: 'ETH',
					desc: 'Tether USDT',
					key: 'Ethereum'
				},
				{
					icon: require('../../assets/imgs/bit3.png'),
					name: 'BTC',
					key: 'Bitcoin'
				},
        {
					icon: require('../../assets/imgs/bit4.png'),
					name: 'Bank',
					key: 'bank'
				},
      ],
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      WelfareCenterList().then(res => {
        if (res.code === 0) {
          this.WelfareCenterList = res.data.Items
        }
      })
      TodayRecharge().then(res => {
        console.log(res)
        if (res.code === 0) {
          this.TodayRechargeTotal = res.data.todayRechargeAmount
          this.todayTransactionVolume = res.data.todayTransactionVolume
        }
      })
    },
    back() {
      this.$router.go(-1)
    },
    // 领取奖励
    receiveAReward(item) {
      var f = new FormData()
      f.append('ID', item.ID)
      WelfareCenterReceiveAReward(f).then(res => {
        if (res.code === 0) {
          this.$toast(res.msg)
        } else {
          this.$toast(res.msg)
        }
      })
    },
    changeShowMore() {
      this.showMore = true
      this.giftList = this.giftList.concat(this.giftListBak)
    },
    toTrade() {
      this.$router.push({
        name: 'trade'
      })
    },
    toRecharge() {
      this.showRecharge = true
    },
    changeRecharge(data, i) {
      this.rechargeIndex = i
      this.rechargeName = data.name
    },
    toRechargePage() {
      if (this.rechargeName == 'Bank') {
        this.$router.push({
          name: 'kefu'
        })
      } else {
        this.$router.push({
          path: '/recharge?type=' + this.rechargeName
        })
      }
    },
    cancel() {
      this.showRecharge = false
    }
  }
}
</script>