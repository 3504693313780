import fetch from "../utils/fetch";


// 金融产品列表
export function financeList(data) {
  return fetch({
    url: '/api/user/financial-list',
    method: 'post',
    data: data
  });
}
// 金融产品申购
export function financeBuy(data) {
  return fetch({
    url: '/api/user/financial-buy',
    method: 'post',
    data: data
  });
}
// 申购列表
export function financeSetupList(data) {
  return fetch({
    url: '/api/user/financial-setup-list',
    method: 'post',
    data: data
  });
}
// 金融统计
export function financialStatistics(data) {
  return fetch({
    url: '/api/user/financial-statistics',
    method: 'post',
    data: data
  });
}
// 金融协议
export function agreementList(data) {
  return fetch({
    url: '/api/user/financial-agreement-list',
    method: 'post',
    data: data
  });
}