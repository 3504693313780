<template>
  <div class="top-bar-page help-page">
    <top-bar :title="i18n.t('help.title')" class="black" parentId=".help-page" @back="back"></top-bar>

    <div class="help-main">
      <div class="help-title">{{i18n.t('help.label1')}}</div>
      <Search v-model="keyword" :placeholder="i18n.t('help.label2')" @clear="clearSearch" @search="searchHelp" @input="searchHelp"></Search>

      <div class="help-title">{{i18n.t('help.label3')}}</div>
      <div class="help-link flex-center">
        <div class="flex-column-center link">
          <img :src="helpUrl" alt="">
          <span>{{i18n.t('help.label4')}}</span>
        </div>
        <div class="flex-column-center link">
          <img :src="helpUrl2" alt="">
          <span>{{i18n.t('help.label5')}}</span>
        </div>
      </div>

      <div class="help-title">{{i18n.t('help.label6')}}</div>
      <div class="help-list">
        <div class="list flex-center-between" v-for="(item, i) in helpDataList" :key="i" @click="changeOpen(item, i)">
          <div  class="label">{{item.Title}}</div>
          <div class="icon">
            <Icon name="arrow" color="#898B98" size="14"></Icon>
          </div>
        </div>
      </div>
    </div>
     <help-detail :detailData="helpDetailData" v-show="showModal" @cancel="cancel"></help-detail>
  </div>
</template>
<script>
import topBar from '@/components/topBar'
import { Button, Search, Icon } from 'vant'
import { helpList } from '@/api/setting'
import helpDetail from './helpDetail.vue'
export default {
  components: {
    Button,
    Search,
    Icon,
    topBar,
    helpDetail
  },
  data() {
    return {
      keyword: '',
      timeUrl:require('../../assets/imgs/icon-time.png'),
      helpUrl:require('../../assets/imgs/help1.png'),
      helpUrl2:require('../../assets/imgs/help2.png'),
      helpDataList: [],
      helpDataListBak: [],
      helpDetailData: {},
      showModal: false,
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      helpList().then(res => {
        this.helpDataList = res.data.Items
        this.helpDataListBak = res.data.Items
      })
    },
    back() {
      this.$router.go(-1)
    },
    clearSearch() {
      this.helpDataList = [].concat(this.helpDataListBak)
    },
    searchHelp() {
      this.helpDataList = this.helpDataListBak.filter(v => {
        return v.Title.indexOf(this.keyword) > -1 || v.Content.indexOf(this.keyword) > -1
      })
    },
    changeOpen(data, i) {
      this.helpDetailData = data
      this.showModal = true
    },
    cancel() {
      this.showModal = false
    }
  }
}
</script>