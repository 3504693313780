import Cookies from "js-cookie";

const TokenKey = "WebToken";
const UserKey = "WebUserName";
const UserIdKey = "WebUserId";
const AvatarKey = "avatar";
const AssetsKey = "assets";
const rechargeTypeKey = "rechargeType";

export function getToken() {
  return localStorage.getItem(TokenKey)
}
export function setToken(token) {
  localStorage.setItem(TokenKey, token);
}
export function removeToken() {
  localStorage.removeItem(TokenKey);
}

export function getUser() {
  return localStorage.getItem(UserKey);
}
export function setUser(user) {
  localStorage.setItem(UserKey, user);
}

export function getUserId() {
  return localStorage.getItem(UserIdKey)
}
export function setUserId(id) {
  localStorage.setItem(UserIdKey, id);
}
export function getUserAvatar() {
  return localStorage.getItem(AvatarKey)
}
export function setUserAvatar(avatar) {
  localStorage.setItem(AvatarKey, avatar);
}
export function getAssets() {
  return localStorage.getItem(AssetsKey)
}
export function setAssets(assets) {
  localStorage.setItem(AssetsKey, assets);
}

export function getRechargeType() {
  return localStorage.getItem(rechargeTypeKey)
}
export function setRechargeType(type) {
  localStorage.setItem(rechargeTypeKey, type);
}
export function removeRechargeType() {
  localStorage.removeItem(rechargeTypeKey);
}
