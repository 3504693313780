<template>
  <div class="setting-container top-bar-page header-page">
    <top-bar :title="i18n.t('header.title')" class="black" parentId=".header-page"  @back="back"></top-bar>
    <div class="top-main pb-30">


      <div class="header-box flex-center">
        <img :src="headerObj[headerIndex]" alt="">
      </div>
   
    </div>

    <div  class="header-list">
      <div class="tab">{{i18n.t('header.tab')}}</div>
      <div class="list-box flex-wrap">
        <div class="list" v-for="(item, i) in headerObj" :key="i" :class="headerIndex == i ? 'active' : ''" @click="changeHeader(i)">
          <img :src="item" alt="">
        </div>
      </div>

      <div class="bottom-btn">
        <Button class="yellow-btn" @click="submit">{{i18n.t('header.btn')}}</Button>
      </div>
    </div>

  </div>
</template>
<script>
import { Button } from 'vant'
import { editHeader } from '@/api/setting'
import { getUserAvatar, setUserAvatar } from '@/utils/auth'
import topBar from '@/components/topBar'
export default {
  components: {
    Button,
    topBar
  },
  data() {
    return {
      headerIndex: '',
      email: 'akdfjlaskjaskjdfasdj@163.com',
      backUrl: require('../../assets/imgs/icon-back.png'),
      addUrl: require('../../assets/imgs/icon-add3.png'),
      headerUrl: require('../../assets/imgs/header/header-1.png'),
      headerObj:{
        'header-1':  require('../../assets/imgs/header/header-1.png'),
        'header-2':  require('../../assets/imgs/header/header-2.png'),
        'header-3':  require('../../assets/imgs/header/header-3.png'),
        'header-4':  require('../../assets/imgs/header/header-4.png'),
        'header-5':  require('../../assets/imgs/header/header-5.png'),
        'header-6':  require('../../assets/imgs/header/header-6.png'),
        'header-7':  require('../../assets/imgs/header/header-7.png'),
        'header-8':  require('../../assets/imgs/header/header-8.png'),
        'header-9':  require('../../assets/imgs/header/header-9.png'),
        'header-10':  require('../../assets/imgs/header/header-10.png'),
        'header-11':  require('../../assets/imgs/header/header-11.png'),
        'header-12':  require('../../assets/imgs/header/header-12.png'),
        'header-13':  require('../../assets/imgs/header/header-13.png'),
        'header-14':  require('../../assets/imgs/header/header-14.png'),
      },
    }
  },
  mounted() {
    this.headerIndex = getUserAvatar() ? `header-${getUserAvatar()}` : 'header-1'
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    changeHeader(i) {
      this.headerIndex = i
    },
    submit() {
      let key = this.headerIndex.substring(7)
      editHeader({
        Avatar: parseInt(key)
      }).then(res => {
        if (res.code == 0) {
          this.$store.state.successMsg = this.i18n.t('header.success')
          setUserAvatar(key)
        } else {
          this.$store.state.errorMsg = res.msg
        }
      })
    }
  }
}
</script>