<template>
  <div class="top-bar-page share-page">
    <top-bar :title="i18n.t('share.title')" class="black" parentId=".share-page" right-icon="help" @back="back"></top-bar>

    <div class="flex-center icon">
      <img :src="moneyUrl" alt="">
    </div>

    <div class="share-title">{{i18n.t('share.desc1')}}</div>
    <div class="desc">{{i18n.t('share.desc2')}}</div>

    <div class="share-box">
      <div class="label" v-show="false">{{i18n.t('share.label1')}}</div>
      <div class="flex-center-between share-line" v-show="false">
        <div>
          {{shareCode}}
        </div>
        <img :src="copyUrl" alt="" class="copy-icon" @click="copyData(1)">
      </div>

      <div class="label">{{i18n.t('share.label2')}}</div>
      <div class="flex-center-between share-line">
        <div class="flex-1 w-1 link">
          {{shareLink}}
        </div>
        <img :src="copyUrl" alt="" class="copy-icon" @click="copyData(2)">
      </div>
    </div>

    <div class="bottom-btn">
      <Button @click="showModal">{{i18n.t('share.btn')}}</Button>
    </div>

    <Popup v-model:show="showShare" position="bottom" lock-scroll class="rate-popup">
			<div class="rate-modal">
				<div class="title flex-center-between">
					<div>{{i18n.t('share.label3')}}</div>
          <img :src="closeUrl" alt="" @click="cancel">
				</div>
				
        <div class="share-modal" ref="modalRef">
          <div class="modal-title">{{i18n.t('share.desc3')}}</div>
          
          <div class="share-info">
            <div class="info flex-center-between">
              <div>
                <div class="label">{{i18n.t('share.desc4')}}</div>
                <div class="code">Code: {{shareCode}}</div>
              </div>
              <div class="qrcode flex-center">
                <div ref="qrcode" id="qrcode"></div>
              </div>
            </div>
          </div>
        </div>

        <div class="bottom-link flex">
          <div class="link flex-column-center copy-icon" v-for="(item, i) in linkList" :key="i" @click="linkClick(i)">
            <img :src="item.icon" alt="">
            <span>{{item.name}}</span>
          </div>
        </div>
			</div>
		</Popup>
  </div>
</template>
<script>
import topBar from '@/components/topBar'
import Clipboard from 'clipboard';
import QRCode from 'qrcodejs2'
import html2canvas from 'html2canvas'
import { Button, Popup } from 'vant'
export default {
  components: {
    Button,
    Popup,
    topBar
  },
  data() {
    return {
      noticeList: [],
      moneyUrl: require('../../assets/imgs/icon-money.png'),
      copyUrl: require('../../assets/imgs/icon-copy2.png'),
      closeUrl: require('../../assets/imgs/icon-close.png'),
      showShare: false,
      linkList: [
        {
          icon: require('../../assets/imgs/icon-link.png'),
          name: this.i18n.t('share.label4')
        },
        {
          icon: require('../../assets/imgs/icon-download.png'),
          name: this.i18n.t('share.label5')
        },
        {
          icon: require('../../assets/imgs/kefu1.png'),
          name: 'Telegram'
        },
        {
          icon: require('../../assets/imgs/kefu2.png'),
          name: 'Twitter'
        },
        {
          icon: require('../../assets/imgs/kefu3.png'),
          name: 'Whatsapp'
        },
      ],
    }
  },
  computed: {
    shareLink() {
      return this.$store.state.promoteUrl + '?code=' + this.shareCode
    },
    shareCode() {
      return this.$store.state.shareCode
    },
    screenScale() {
      return this.$store.state.screenScale
    },
  },
  mounted() {
  },
  methods: {
    init() {
    
    },
    back() {
      this.$router.go(-1)
    },
    copyData(type) {
      let data = type == 1 ? this.shareCode : this.shareLink
      const clipboard = new Clipboard('.copy-icon', {text: () => data});
      this.$store.state.successMsg = this.i18n.t('share.success')
    },
    showModal() {
      this.showShare = true
      this.$nextTick(() => {
        document.getElementById('qrcode').innerHTML = ''
        let qrcode = new QRCode(this.$refs.qrcode, {
          text: this.shareLink,
          width: 63 * this.screenScale,
          height: 63 * this.screenScale,
          correctLevel: QRCode.CorrectLevel.H
        })
      })
    },
    cancel() {
      this.showShare = false
    },
    linkClick(i) {
      if (i == 0) {
        this.copyData(2)
      } else if (i == 1) {
        html2canvas(this.$refs.modalRef).then(canvas => {
          let a = document.createElement('a')
          a.href = canvas.toDataURL("image/png")
          a.download = `${this.i18n.t('share.img')}.png`
          var clickevent = document.createEvent('MouseEvents');
          clickevent.initEvent('click', true, false);
          a.dispatchEvent(clickevent);
        })
      }
    }
  }
}
</script>