import fetch from "../utils/fetch";


// 资产
export function myAssets(data) {
  return fetch({
    url: '/api/user/my-assets',
    method: 'post',
    data: data
  });
}
// 钱包
export function wallet(data) {
  return fetch({
    url: '/api/user/wallet-list',
    method: 'post',
    data: data
  });
}
// 充值地址
export function rechargeAddress(data) {
  return fetch({
    url: '/api/user/recharge-address',
    method: 'post',
    data: data
  });
}
// 绑定提现地址
export function bindWithdrawAddress(data) {
  return fetch({
    url: '/api/user/bind-address',
    method: 'post',
    data: data
  });
}
// 提现申请
export function withdraw(data) {
  return fetch({
    url: '/api/user/withdraw',
    method: 'post',
    data: data
  });
}
// 划转
export function transfer(data) {
  return fetch({
    url: '/api/user/transfer',
    method: 'post',
    data: data
  });
}
// 全部币种余额
export function allTrades(data) {
  return fetch({
    url: '/api/user/all-trades',
    method: 'post',
    data: data
  });
}
// 合约未结盈亏
export function contractProfit(data) {
  return fetch({
    url: '/api/user/contract-profit',
    method: 'post',
    data: data
  });
}
// 资产类型
export function debtType(data) {
  return fetch({
    url: '/api/user/debt-type',
    method: 'post',
    data: data
  });
}
// 资产记录
export function debtLog(data) {
  return fetch({
    url: '/api/user/debt-lists',
    method: 'post',
    data: data
  });
}