
import Stomp from 'stompjs';
import store from '../store';
import { getToken } from './auth'



export function initWebSocket(id, websocketonmessage, reconnect) {
  let socketUrl = '';
  const env = process.env.NODE_ENV;
  if (env == 'development') {
    socketUrl = process.env.VUE_APP_WEBSOCKET_URL;
  } else {
    let host = window.location.host;
    let href = window.location.href;
    socketUrl = (href.indexOf('https:') == 0 ? 'wss://' : 'ws://') + host + '/api/user/ws';
  }

  let retryCount = 0; // 重试次数
  const maxRetryCount = 10; // 最大重试次数
  let retryInterval = 100; // 重试间隔时间（单位：毫秒）

  function increaseInterval() {
    retryCount++;
    retryInterval = Math.pow(2, retryCount) * 100; // 重试间隔时间指数递增
    retryInterval = Math.min(retryInterval, 60000); // 重试间隔时间最大不超过 1 分钟
  }

  return new Promise((resolve) => {
    function connect() {
      let websocket = new WebSocket(socketUrl);
      websocket.onopen = function(evt) {
        console.log('open');
        store.state.isClose = false
        store.state.isSend = false
        resolve(websocket);
        retryCount = 0; // 连接成功后重试次数清零
        retryInterval = 100; // 连接成功后重试间隔时间重置为 100 毫秒
      };
      websocket.onclose = function(evt) {
        console.log('close');
        store.state.isSend = false;
        if (reconnect && retryCount < maxRetryCount && !store.state.isClose) {
          setTimeout(() => {
            console.log(`Attempting onclose reconnect (attempt ${retryCount + 1} of ${maxRetryCount})...`);
            connect();
            increaseInterval(); // 重试间隔时间指数递增
          }, retryInterval);
        }
      };
      websocket.onmessage = function(evt) {
        websocketonmessage({
          data: evt.data,
        });
      };
      websocket.onerror = function(evt) {
        store.state.isSend = false;
        if (reconnect && retryCount < maxRetryCount) {
          setTimeout(() => {
            console.log(`now:${new Date().toLocaleTimeString()} Attempting onerror reconnect (attempt ${retryCount + 1} of ${maxRetryCount})...`);
            connect();
            increaseInterval(); // 重试间隔时间指数递增
          }, retryInterval);
        }
      };
    }
    connect();
  });
}

// 数字格式化
export function formatNumber(num) {
  return parseFloat(num).toFixed(4)
  if (num.toString().indexOf('.') !== -1) {
    const arr = num.toString().split('.');
    if (/^0+$/.test(arr[1])) {
      return arr[0] + '.00';
    } else if (arr[1].length === 1) {
      return arr[0] + '.' + arr[1] + '0';
    } else if (arr[1].length === 3) {
      return arr[0] + '.' + arr[1] + '00';
    } else if (arr[1].length > 4) {
      return arr[0] + '.' + arr[1].slice(0, 4);
    } else {
      return arr[0] + '.' + arr[1].padEnd(4, '0');
    }
  } else {
    return num + '.00';
  }
}

export function getLocalName (key) {
  const langArr = [
    {
      name: '简体中文',
      key: 'zh-CN',
    },
    {
      name: '繁体中文',
      key: 'zh-TW',
    },
    {
      name: 'English',
      key: 'en',
    },
    // 哈萨克斯坦语
    {
      name: 'Русский',
      key: 'kk',
    },
    //   西班牙语
    {
      name: 'Español',
      key: 'es',
    },
    // 法语
    {
      name: 'Français',
      key: 'fr',
    },
    // 德语
    {
      name: 'Deutsch',
      key: 'de',
    },
    // 意大利语
    {
      name: 'Italiano',
      key: 'it',
    },
    // 葡萄牙语
    {
      name: 'Português',
      key: 'pt',
    },
    // 俄语
    {
      name: 'Pусский',
      key: 'ru',
    },
    //   土耳其语
    {
      name: 'Türkçe',
      key: 'tr',
    },
    // 韩语
    {
      name: '한국어',
      key: 'ko',
    },
    // 日语
    {
      name: '日本語',
      key: 'ja',
    },
    // 越南语
    {
      name: 'Tiếng Việt',
      key: 'vi',
    },
    // 印尼语
    {
      name: 'Bahasa Indonesia',
      key: 'id',
    },
    // 马来语
    {
      name: 'Bahasa Melayu',
      key: 'ms',
    },
    // 泰语
    {
      name: 'ภาษาไทย',
      key: 'th',
    },
    // 阿拉伯语
    {
      name: 'العربية',
      key: 'ar',
    },
    // 希伯来语
    {
      name: 'עברית',
      key: 'he',
    },
    // 印地语
    {
      name: 'हिन्दी',
      key: 'hi'
    }
  ]
  const lang = langArr.find(item => item.key === key)
  console.log('localName')
  console.log(lang)
  return lang ? lang.name : ''
}

// 默认语言
export function getDefaultLanguage () {
  const defaultLanguage = store.state.language;
  const language = navigator.language
  const clientLanguage = (language != 'zh-CN' && language != 'zh-TW') ? language.substring(0, 2) : language
  const messages = [
    'zh-CN',
    'zh-TW',
    'ar',
    'en',
    'es',
    'fr',
    'id',
    'ja',
    'ko',
    'pt',
    'ru',
    'th',
    'he',
    'tr',
    'hi',
    'vi',
    'de',
    'it',
    'kk',
    'ms'
  ]
  const local = messages.indexOf(clientLanguage) > -1 ? clientLanguage : defaultLanguage
  return local
}