<template>
	<div class="log-page top-bar-page">
    <top-bar :title="i18n.t('assestLog.title')" class="" parentId=".log-page"  @back="back"></top-bar>

    <div class="filter-line flex">
      <div class="flex-center option" @click="showModal(1)">
        <span>{{bitName}}</span>
        <img :src="downUrl" alt="">
      </div>
      <div class="flex-center option" @click="showModal(2)">
        <span>{{typeName}}</span>
        <img :src="downUrl" alt="">
      </div>
      <div class="flex-center option" @click="showModal(3)">
        <span>{{accountName}}</span>
        <img :src="downUrl" alt="">
      </div>
      <div class="option-list" v-show="showBitOption">
        <div class="option-item" v-for="(item, i) in bitList" :key="i" :class="item.ID == form.bit ? 'active' : ''" @click="changeBit(item)">
          {{item.Name}}
        </div>
      </div>
      <div class="option-list" v-show="showTypeOption">
        <div class="option-item" v-for="(item, i) in typeList" :key="i" :class="item.key == form.type ? 'active' : ''" @click="changeType(item)">
          {{item.name}}
        </div>
      </div>
      <div class="option-list" v-show="showAccountOption">
        <div class="option-item" v-for="(item, i) in accountList" :key="i" :class="item.key == form.account ? 'active' : ''" @click="changeAccount(item)">
          {{item.name}}
        </div>
      </div>
    </div>

    <div class="list-main">
      <List
        v-if="dataList.length > 0"
        v-model="loading"
        :finished="finished"
        :finished-text="i18n.t('common.noMore')"
        @load="onLoad">
        <div class="list flex-center" v-for="(item, i) in dataList" :key="i" @click="toDetail(item)"> 
          <div class="flex-1 flex-center-between">
            <div>
              <div class="label">{{item.Currency.Name}}</div>
              <div class="time">{{item.CreatedAt}}</div>
            </div>
            <div>
              <div class="count">{{parseFloat(item.Practical).toFixed(4)}}</div>
              <div class="status success">{{i18n.t('assestLog.status')}}</div>
            </div>
          </div>
          <div>
            <img :src="rightUrl" alt="">
          </div>
        </div>
      </List>

      <div class="empty flex-column-center" v-if="dataList.length == 0">
        <img :src="emptyUrl" alt="">
        <span class="desc">{{i18n.t('trade.empty')}}</span>
      </div>
    </div>
   
	</div>
</template>
<script>
import { debtType, debtLog } from '@/api/assest'
import { getCoinList } from '@/api/index'
import topBar from '@/components/topBar'
import { List } from 'vant'
export default {
  components: {
    topBar,
    List
  },
  data() {
		return {
      form: {
        bit: '',
        type: '',
        account: ''
      },
      page: {
        page: 1,
        limit: 20
      },
      isInit: false,
      loading: false,
      finished: false,
      totalPage: 1,
      bitName: this.i18n.t('assestLog.option1'),
      typeName: this.i18n.t('assestLog.option2'),
      accountName: this.i18n.t('assestLog.option11'),
			backUrl: require('../../assets/imgs/icon-back.png'),
			emptyUrl: require('../../assets/imgs/order-empty.png'),
			downUrl: require('../../assets/imgs/icon-arrow-down3.png'),
			upUrl: require('../../assets/imgs/icon-arrow-up.png'),
			rightUrl: require('../../assets/imgs/icon-arrow-right.png'),
      dataList: [],
      showBitOption: false,
      showTypeOption: false,
      showAccountOption: false,
      bitList: [
        {
          Name: this.i18n.t('assestLog.option1'),
          ID: ''
        },
      ],
      typeList: [
        {
          name: this.i18n.t('assestLog.option2'),
          key: ''
        },
      ],
      accountList: [
        {
          name: this.i18n.t('assestLog.option11'),
          key: ''
        },
        {
          name: this.i18n.t('assestLog.option12'),
          key: 'Spot'
        },
        {
          name: this.i18n.t('assestLog.option13'),
          key: 'Contract'
        },
        {
          name: this.i18n.t('assestLog.option14'),
          key: 'Option'
        },
        {
          name: this.i18n.t('assestLog.option15'),
          key: 'Finance'
        },
        {
          name: this.i18n.t('assestLog.option16'),
          key: 'Capital'
        },
      ]
		}
	},
  computed: {
    bitListBak() {
      return this.$store.state.bitList
    }
  },
  mounted() {
    this.initBit()
    this.initType()
    this.init()
  },
	methods: {
    onLoad() {
      this.page.page += 1
      this.init(true)
    },
    init(isAdd) {
      let form = new FormData()
      form.append('current', this.page.page)
      form.append('pageSize', this.page.limit)
      form.append('CurrencyType', this.form.account)
      form.append('Type', this.form.type)
      form.append('CurrencyId', this.form.bit)
      debtLog(form).then(res => {
        if (res.data.Items) {
          if (isAdd) {
            this.dataList = this.dataList.concat(res.data.Items)
          } else {
            this.dataList = res.data.Items
          }
          this.totalPage = res.data.Pagination.totalPage
          if (this.totalPage <= this.page.page) {
             this.finished = true;
          }
        }
        this.isInit = true
        this.loading = false
      })
    },
    initType() {
      debtType().then(res => {
        let data = res.data
        for (let key in data) {
          let param = {
            name: data[key],
            key: key
          }
          this.typeList.push(param)
        }
        this.$store.state.assestType = this.typeList
      })
    },
    initBit() {
      if (this.bitListBak.length == 0) {
        getCoinList().then(res => {
          let bitListBak = res.data.Items.map(v => {
            v.percent = 0
            return v
          }).filter(v => {
            return v.Name != 'USDT'
          })
          this.$store.state.bitList = [].concat(bitListBak)
          this.bitList = this.bitList.concat(bitListBak)
        })
      } else {
        this.bitList = this.bitList.concat(this.bitListBak).filter(v => {
          return v.Name != 'USDT'
        })
      }
    },
		back() {
			this.$router.go(-1)
		},
    showModal(type) {
      if (type == 1) {
        this.showBitOption = !this.showBitOption
        this.showTypeOption = false
        this.showAccountOption = false
      } else if (type == 2) {
        this.showTypeOption = !this.showTypeOption
        this.showBitOption = false
        this.showAccountOption = false
      } else if (type == 3) {
        this.showAccountOption = !this.showAccountOption
        this.showBitOption = false
        this.showTypeOption = false
      }
    },
    changeBit(data) {
      this.bitName = data.Name
      this.form.bit = data.ID
      this.showBitOption = false
      this.page.page = 1
      this.init()
    },
    changeType(data) {
      this.typeName = data.name
      this.form.type = data.key
      this.showTypeOption = false
      this.page.page = 1
      this.init()
    },
    changeAccount(data) {
      this.accountName = data.name
      this.form.account = data.key
      this.showAccountOption = false
      this.page.page = 1
      this.init()
    },
    toDetail(data) {
      sessionStorage.setItem('logData', JSON.stringify(data))
      this.$router.push({
        name: 'logDetail'
      })
    }
	}
}
</script>