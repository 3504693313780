<template>
  <div class="setting-container main top-bar-page">
    <div class="flex-center-between back fixed">
      <img :src="backUrl" alt="" @click="back">
      <img :src="kefuUrl" class="big" alt="" @click="toKefu">
    </div>
    <div class="top-main clear ">


      <div class="flex-center user-info" v-if="isLogin">
        <div>
          <img :src="avatar ? headerObj[avatar] : userUrl" alt="" @click="toPath('header')">
        </div>
        <div class="flex-1 w-1">
          <div class="name">Hi,{{username}}</div>
          <div class="flex-center-start copy">
            <span>UID:{{userId}}</span>
            <img :src="copyUrl" class="copy-icon" alt="" @click="copyData">
          </div>
        </div>
      </div>

      <div class="flex-center user-info" v-if="!isLogin">
        <div>
          <img :src="userUrl" alt="">
        </div>
        <div class="flex-1 w-1">
          <div class="name">Hi,{{i18n.t('setting.label14')}}{{appName}}</div>
          <div class="flex-center-start copy">
            <span>{{i18n.t('setting.label15')}}</span>
          </div>
        </div>
      </div>

      <div class="tab-box flex-center-between" v-if="isLogin">
        <div class="tab flex-center-start" @click="toPath('gift')" style="width: 8.8rem">
          <div>
            <img :src="gitfUrl" alt="">
          </div>
          <div class="flex-1 w-1">
            <div class="label" :class="i18n.t('setting.label1').length > 15 ? 'small' : ''">{{i18n.t('setting.label1')}}</div>
            <div class="desc" :class="i18n.t('setting.label2').length > 8 ? 'small' : ''">
              {{i18n.t('setting.label2')}} 
              <span class="yellow">{{i18n.t('setting.label3')}}</span>
            </div>
          </div>
        </div>
        <div class="tab flex-center-start" @click="toPath('activity')" v-show="false">
          <div>
            <img :src="activityUrl" alt="">
          </div>
          <div class="flex-1 w-1">
            <div class="label" :class="i18n.t('setting.label4').length > 15 ? 'small' : ''">{{i18n.t('setting.label4')}}</div>
            <div class="desc" :class="i18n.t('setting.label5').length > 8 ? 'small' : ''">
              {{i18n.t('setting.label5')}}
            </div>
          </div>
        </div>
      </div>

      <div class="tab-box flex-center-between" v-if="!isLogin">
        <Button class="setting-btn gray" @click="toPath('login')">{{i18n.t('login.btn')}}</Button>
        <Button class="setting-btn yellow" @click="toPath('register')">{{i18n.t('login.register')}}</Button>
      </div>
    </div>

    <div class="menu-list">
      <div class="list flex-center" v-for="(item, i) in menuList" :key="i" :class="item.space ? 'space' : ''" @click="toPath(item.path, item.modal, item.ensure)">
        <div class="icon">
          <img :src="item.icon" alt="">
        </div>
        <div class="flex-1 name">{{item.name}}</div>
        <div class="desc flex-center">
          <span v-if="item.desc">{{item.desc}}</span>
          <img :src="rightUrl" alt="">
        </div>
      </div>

      <div class="list space flex-center" @click="logout" v-if="isLogin">
        <span class="name">{{i18n.t('setting.logout')}}</span>
      </div>
    </div>

    <Popup v-model:show="showKefu" position="bottom" lock-scroll class="rate-popup">
			<div class="rate-modal bottom">
				<div class="title flex-center-between">
					<div>{{i18n.t('setting.label12')}}</div>
          <img :src="closeUrl" alt="" @click="cancel">
				</div>
				<div class="recharge-list kefu">
          <div class="list flex-center" v-for="(item, i) in kefuList" :key="i" @click="chooseKefu(item, i)">
            <div class="icon">
              <img :src="`${item.Avatar}`" alt="">
            </div>
            <div class="name flex-1">
              <div>{{item.Name}}</div>
              <div class="status">{{i18n.t('setting.label13')}}</div>
            </div>
            <div class="check">
              <img :src="rightUrl" alt="">
            </div>
          </div>
				</div>

			</div>
		</Popup>
    
    <Popup v-model:show="showModal" position="bottom" lock-scroll class="rate-popup">
			<div class="rate-modal">
				<div class="title flex-center-between clear" >
					<div>{{i18n.t('setting.label6')}}</div>
          <img :src="closeUrl" alt="" @click="cancel">
				</div>

        <div class="auth-list">
          <div class="auth-title">{{i18n.t('setting.label7')}}</div>
          <div class="flex-center-between">
            <div class="auth" @click="changeAuth(1)">
              <div class="bg flex-center">
                <img :src="cardUrl" alt="">
              </div>
              <div class="label" :class="authIndex == 1 ? 'active' : ''">
                <span>{{i18n.t('setting.label8')}}</span>
                <span class="" v-show="authIndex == 1">({{i18n.t('setting.label10')}})</span>
              </div>
            </div>
            <div class="auth" @click="changeAuth(2)">
              <div class="bg flex-center">
                <img :src="hzUrl" alt="">
              </div>
              <div class="label" :class="authIndex == 2 ? 'active' : ''">
                <span>{{i18n.t('setting.label9')}}</span>
                <span class="" v-show="authIndex == 2">({{i18n.t('setting.label10')}})</span>
              </div>
            </div>
          </div>
          <Button  @click="toAuth">{{i18n.t('setting.btn')}}</Button>
        </div>

			</div>
		</Popup>

    <Popup v-model:show="showEnsureModal" position="center" lock-scroll class="ensure-popup">
			<div class="ensure-modal flex-column-center">
				<div class="icon">
					<img :src="warningUrl" alt="">
				</div>
				<div class="title">{{i18n.t('setting.desc7')}}</div>
				<div class="flex-center">
					<Button class="gray" @click="cancel">{{i18n.t('setting.btn2')}}</Button>
					<Button @click="ensureAuth">{{i18n.t('setting.btn')}}</Button>
				</div>
			</div>
		</Popup>
  </div>
</template>
<script>
import { getToken, removeToken, getUserAvatar, getUser, getUserId, setUser, setUserId, setUserAvatar, setRechargeType } from '@/utils/auth'
import { Popup, Button } from 'vant'
import Clipboard from 'clipboard';
import { userInfo } from '@/api/user'
import { checkAuth, customerList } from '@/api/setting'
import "@/utils/sdk.js"
export default {
  components: {
    Popup,
    Button
  },
  data() {
    return {
      backUrl: require('../../assets/imgs/icon-back.png'),
      rightUrl: require('../../assets/imgs/icon-arrow-right.png'),
      kefuUrl: require('../../assets/imgs/icon-kefu2.png'),
      userUrl: require('../../assets/imgs/icon-user.png'),
      copyUrl: require('../../assets/imgs/icon-copy.png'),
      gitfUrl: require('../../assets/imgs/icon-gift.png'),
      activityUrl: require('../../assets/imgs/icon-activity.png'),
      closeUrl: require('../../assets/imgs/icon-close.png'),
      cardUrl: require('../../assets/imgs/auth-card.png'),
      hzUrl: require('../../assets/imgs/auth-hz.png'),
      warningUrl: require('../../assets/imgs/icon-warning2.png'),
      username: '',
      userId: '',
      showModal: false,
      showKefu: false,
      authIndex: 1,
      avatar: '',
      menuList: [
        {
          icon: require('../../assets/imgs/icon-share2.png'),
          name: this.i18n.t('setting.menu1'),
          desc: this.i18n.t('setting.desc1'),
          path: 'share'
        },
        {
          icon: require('../../assets/imgs/icon-card.png'),
          name: this.i18n.t('setting.menu2'),
          desc: '',
          path: 'userAuth',
          space: true,
          modal: true
        },
        {
          icon: require('../../assets/imgs/icon-address.png'),
          name: this.i18n.t('setting.menu3'),
          path: 'address',
          ensure: true
        },
        {
          icon: require('../../assets/imgs/icon-safe.png'),
          name: this.i18n.t('setting.menu4'),
          path: 'safeSetting'
        },
        {
          icon: require('../../assets/imgs/icon-setting.png'),
          name: this.i18n.t('setting.menu5'),
          path: 'commonSetting'
        },
        {
          icon: require('../../assets/imgs/icon-notice.png'),
          name: this.i18n.t('setting.menu6'),
          space: true,
          path: 'notice'
        },
        {
          icon: require('../../assets/imgs/icon-help.png'),
          name: this.i18n.t('setting.menu7'),
          space: true,
          path: 'help'
        },
        {
          icon: require('../../assets/imgs/icon-feedback.png'),
          name: this.i18n.t('setting.menu8'),
          path: 'feedback'
        },
        {
          icon: require('../../assets/imgs/icon-about.png'),
          name: this.i18n.t('setting.menu9'),
          path: 'about'
        },
      ],
      headerObj:{
        'header-1':  require('../../assets/imgs/header/header-1.png'),
        'header-2':  require('../../assets/imgs/header/header-2.png'),
        'header-3':  require('../../assets/imgs/header/header-3.png'),
        'header-4':  require('../../assets/imgs/header/header-4.png'),
        'header-5':  require('../../assets/imgs/header/header-5.png'),
        'header-6':  require('../../assets/imgs/header/header-6.png'),
        'header-7':  require('../../assets/imgs/header/header-7.png'),
        'header-8':  require('../../assets/imgs/header/header-8.png'),
        'header-9':  require('../../assets/imgs/header/header-9.png'),
        'header-10':  require('../../assets/imgs/header/header-10.png'),
        'header-11':  require('../../assets/imgs/header/header-11.png'),
        'header-12':  require('../../assets/imgs/header/header-12.png'),
        'header-13':  require('../../assets/imgs/header/header-13.png'),
        'header-14':  require('../../assets/imgs/header/header-14.png'),
      },
      realNameStatus: '',
      showEnsureModal: false,
      kefuList: [],
      isLogin: false
    }
  },
  computed: {
    appName() {
      return this.$store.state.appName
    }
  },
  mounted() {
    if (getToken()) {
      this.init()
      this.username = getUser()
      this.userId = getUserId()
      this.avatar = `header-${getUserAvatar()}`
      this.isLogin = true
    } else { //游客
      this.isLogin = false
      this.avatar = ''
      this.menuList = this.menuList.slice(4, 9)
    }
    this.initKefu()
    
  },
  methods: {
    init() {
      //用户信息
      userInfo().then(res => {
        let data = res.data
        this.username = data.Email || data.Phone || data.Address
        this.userId = data.ID
        setUser(this.username)
        setUserId(this.userId)
        setUserAvatar(data.Avatar)
        setRechargeType(data.Assets)
        
        this.avatar = `header-${data.Avatar}`
        this.$store.state.shareCode = data.InviteCode
        //认证状态
      })

      checkAuth().then(res => {
        this.realNameStatus = res.data.status
        switch (this.realNameStatus){
          case 0:
            this.menuList[1].desc = this.i18n.t('setting.desc2')
            break;
          case 1:
            this.menuList[1].desc = this.i18n.t('setting.desc4')
            break;
          case 2:
            this.menuList[1].desc = this.i18n.t('setting.desc3')
            break;
          case 3:
            this.menuList[1].desc = this.i18n.t('setting.desc2')
            break;
        }
      })

    },
    initKefu() {
      //客服
      customerList().then(res => {
        this.kefuList = res.data.Items
      })
    },
    toPath(path, isModal, isEnsure) {
      if (isModal) {
        if (this.realNameStatus == 3 || this.realNameStatus == 0) {
          this.showModal = true
        } else {
          if (this.realNameStatus == 1) {
            this.$store.state.warningMsg = this.i18n.t('setting.desc6')
          } else {
            this.$store.state.warningMsg = this.i18n.t('setting.desc5')
          }
        }
      } else if (isEnsure) {
        if (this.realNameStatus == 3 || this.realNameStatus == 0) {
          this.showEnsureModal = true
        } else {
          if (this.realNameStatus == 2) {
            this.$router.push({
              name: path
            })
          } else {
            this.$store.state.warningMsg = this.i18n.t('setting.desc8')
          }
        }
      } else if (path) {
        this.$router.push({
          name: path
        })
      }
    },
    changeAuth(i) {
      this.authIndex = i
    },
    toAuth() {
      this.$router.push({
        name: 'userAuth',
        query: {
          type: this.authIndex
        }
      })
    },
    cancel() {
      this.showModal = false
      this.showEnsureModal = false
      this.showKefu = false
    },
    ensureAuth() {
      this.showEnsureModal = false
      this.showModal = true
    },
    back() {
      this.$router.go(-1)
    },
    logout() {
      removeToken()
      this.$router.push({
        name: 'login'
      })
    },
    copyData() {
      let data = this.userId
      const clipboard = new Clipboard('.copy-icon', {text: () => data});
      this.$store.state.successMsg = this.i18n.t('setting.success')
    },
    toKefu() {
      this.showKefu = true
    },
    chooseKefu(data) {
      this.showKefu = false
      this.to(data.Type, data.Link)
    },
    to(Type, Link) {
      let tmp = undefined
      let that = this
      if(Type == "chatwoot") {
        if(window.$chatwoot === undefined) {
          tmp = setInterval(function (){
            // that.chatwoot()
            if (window.$chatwoot !== undefined) {
              clearInterval(tmp)
              window.$chatwoot.toggle('open');
            }
          },50)
        } else {
          window.$chatwoot.toggle('open');
        }
      } else if (Type == 'meiqia') {  
        this.$router.push({
          name: 'kefu'
        })
      } else {
        switch (Type) {
          case "wechat":
            Link = "weixin://" + Link
            break;
          case "qq":
            Link = "mqq://"+ Link
            break;
          case "whatsapp":
            Link = "whatsapp://send?phone="+ Link
            break;
          case "telegram":
            Link = "tg://resolve?domain="+ Link
            break;
          case "facebook":
            Link = "fb://"+ Link
            break;
          case "twitter":
            Link = "twitter://"+ Link
            break;
          case "instagram":
            Link = "instagram://"+ Link
            break;
            case "line":
            Link = "https://line.me/R/"+ Link
            break;
        }
        this.toKefuPath(Link)
      }
    },
    toKefuPath(path) {
      let a = document.createElement('a')
      a.href = path
      a.target = '_blank'
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    },
    chatwoot(Link){
      let that = this;
      if (Link.length === 0) {
        return;
      }
      let len = Link.indexOf("/widget");
      if (len === -1) {
        return;
      }
      var BASE_URL=Link.substr(0,len);
      let tokenlen = Link.indexOf("token=");
      let token = Link.substr(tokenlen+6);
      window.chatwootSDK.run({
        websiteToken: token,
        baseUrl: BASE_URL
      });
      that.inter = setInterval(function(){
        if(document.getElementsByClassName("woot-widget-bubble")[0] !== undefined) {
          that.show = true;
          clearInterval(that.inter);
          window.$chatwoot.toggleBubbleVisibility('hide');
        }
      },50);
    },
  }
}
</script>