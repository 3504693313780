<template>
  <div class=" top-bar-page lang-page">
    <top-bar :title="i18n.t('lang.title')" class="black" parentId=".lang-page"  @back="back"></top-bar>
    
    <div class="lang-list">
      <div class="list flex-center-between" v-for="(item, i) in langArr" :key="i" @click="changeLang(item)">
        <div>
          {{item.name}}
        </div>
        <div v-show="item.key == lang">
          <img :src="checkUrl" alt="">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Button } from 'vant'
import { langList } from '@/api/setting'
import topBar from '@/components/topBar'
export default {
  components: {
    Button,
    topBar
  },
  data() {
    return {
      lang: '',
      langArr: [
        {
          name: '简体中文',
          key: 'zh-CN',
        },
        {
          name: '繁体中文',
          key: 'zh-TW',
        },
        {
          name: 'English',
          key: 'en',
        },
          // 哈萨克斯坦语
        {
          name: 'Русский',
          key: 'kk',
        },
        //   西班牙语
        {
          name: 'Español',
          key: 'es',
        },
          // 法语
        {
          name: 'Français',
          key: 'fr',
        },
          // 德语
        {
          name: 'Deutsch',
          key: 'de',
        },
          // 意大利语
        {
          name: 'Italiano',
          key: 'it',
        },
          // 葡萄牙语
        {
          name: 'Português',
          key: 'pt',
        },
          // 俄语
        {
          name: 'Pусский',
          key: 'ru',
        },
        //   土耳其语
        {
          name: 'Türkçe',
          key: 'tr',
        },
          // 韩语
        {
          name: '한국어',
          key: 'ko',
        },
          // 日语
        {
          name: '日本語',
          key: 'ja',
        },
          // 越南语
        {
          name: 'Tiếng Việt',
          key: 'vi',
        },
          // 印尼语
        {
          name: 'Bahasa Indonesia',
          key: 'id',
        },
          // 马来语
        {
          name: 'Bahasa Melayu',
          key: 'ms',
        },
          // 泰语
        {
          name: 'ภาษาไทย',
          key: 'th',
        },
          // 阿拉伯语
        {
          name: 'العربية',
          key: 'ar',
        },
          // 希伯来语
        {
          name: 'עברית',
          key: 'he',
        },
          // 印地语
        {
          name: 'हिन्दी',
          key: 'hi'
        }
      ],
      checkUrl: require('../../assets/imgs/icon-checked2.png')
    }
  },
  computed: {
    language() {
      return this.$store.state.language
    }
  },
  mounted() {
    // this.init()
    let locale = localStorage.getItem('locale')
    const defaultLanguage = getDefaultLanguage()
    this.lang = locale ? locale : defaultLanguage

    let langSettingList = this.language.split(',')
    this.langArr = this.langArr.filter(v => {
      return langSettingList.indexOf(v.key) > -1
    })

  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    init() {
      langList().then(res => {
        this.langArr = res.data.Items
      })
    },
    changeLang(data) {
      this.lang = data.key
      localStorage.setItem('locale', data.key)
      localStorage.setItem('localeName', data.name)
      this.i18n.locale = data.key
      setTimeout(() => {
        this.back()
      }, 500)
    }
  }
}
</script>