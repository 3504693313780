<template>
  <div class=" top-bar-page about-page">
    <top-bar :title="i18n.t('about.title')" class="black" parentId=".about-page"  @back="back"></top-bar>

    <div class="about-main">
      <div class="logo flex-column-center">
        <img :src="logoUrl" alt="">
        <span class="name">{{appName}}</span>
        <span class="version">Version 4.10.0</span>
      </div>
      <div class="menu-list">
        <div  class="menu flex-center" @click="toRefresh">
          <div class="icon">
            <img :src="refreshUrl" alt="">
          </div>
          <div class="flex-1 name">{{i18n.t('about.label1')}}</div>
          <div class="adot">
            <img :src="rightUrl" alt="">
          </div>
        </div>
        <div  class="menu flex-center" @click="toPath('network')">
          <div class="icon">
            <img :src="wifiUrl" alt="">
          </div>
          <div class="flex-1 name">{{i18n.t('about.label2')}}</div>
          <div class="adot">
            <img :src="rightUrl" alt="">
          </div>
        </div>
      </div>
    </div>

    <div class="bottom-msg">
      Copyright © 2022-2023 Name Here. All Rights Reserved.
    </div>
  </div>
</template>
<script>
import topBar from '@/components/topBar'
export default {
  components: {
    topBar
  },
  data() {
    return {
      logoUrl: require('../../assets/imgs/logo.png'),
      rightUrl: require('../../assets/imgs/icon-arrow-right.png'),
      refreshUrl: require('../../assets/imgs/refresh.png'),
      wifiUrl: require('../../assets/imgs/icon-wifi.png'),
    }
  },
  computed: {
    appName() {
      return this.$store.state.appName
    }
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    toPath(path) {
      if (path) {
        this.$router.push({
          name: path
        })
      }
    },
    toRefresh() {
      this.$store.state.successMsg = this.i18n.t('about.label3')
    }
  }
}
</script>