<template>
  <div class="top-bar-page feedback-page">
    <top-bar :title="i18n.t('feedback.title')" class="black" parentId=".feedback-page" @back="back"></top-bar>

    <div class="feedback-main">
      <div class="title">{{i18n.t('feedback.desc1')}}</div>
      <div  class="sub-title">{{i18n.t('feedback.desc2')}}</div>
      <div class="bg">
        <img :src="feedbackUrl" alt="">
      </div>
      <div class="desc">
        {{i18n.t('feedback.desc3')}}
      </div>

      <div class="form-title">
        {{i18n.t('feedback.desc4')}}
        <span class="red">*</span>
      </div>
      <div class="label">0 - {{i18n.t('feedback.desc5')}}</div>
      <div class="count-line flex">
        <div class="count " v-for="i in 6" :key="i" :class="feedbackValue == i - 1 ? 'active' : ''" @click="changeValue(i - 1)">{{i - 1}}</div>
      </div>
      <div class="count-line flex small">
        <div class="count" v-for="i in 5" :key="i" :class="feedbackValue == i + 5 ? 'active' : ''" @click="changeValue(i + 5)">{{i + 5}}</div>
      </div>
      <div class="label right">10 - {{i18n.t('feedback.desc6')}}</div>
      <div class="form-title">
        {{i18n.t('feedback.desc7')}}
      </div>

      <div class="content">
        <Field
          v-model="message"
          class="content-field"
          label=""
          type="textarea"
          maxlength="500"
          :placeholder="i18n.t('feedback.placeholder')"
          show-word-limit
        />
      </div>

      <div class="flex-center-start file-line">
        <div class="flex-center file-btn" @click="addFile">
          <img :src="uploadUrl" alt="">
          <span>{{i18n.t('feedback.label1')}}</span>
          <input type="file" id="fileInput" class="hide-box" @change="chooseFile">
        </div>
        <div class="name flex-1">{{fileName || i18n.t('feedback.label2')}}</div>
      </div>

      <div class="btn">
        <Button @click="submit">{{i18n.t('feedback.btn1')}}</Button>
      </div>

      <div class="desc2">
        {{i18n.t('feedback.desc8')}}
      </div>

      <div class="bottom-desc flex-column-center">
        <img :src="emptyUrl" alt="">
        <span>{{i18n.t('feedback.desc9')}}</span>
      </div>

      <div class="bottom-btn">
        <div class="label2">{{i18n.t('feedback.btn2')}}</div>
        <div class="btn">
          <Button @click="toTrade">{{i18n.t('feedback.btn2')}}</Button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import topBar from '@/components/topBar'
import { Button, Search, Icon, Field } from 'vant'
export default {
  components: {
    Button,
    Search,
    Icon,
    Field,
    topBar
  },
  data() {
    return {
      message: '',
      feedbackUrl:require('../../assets/imgs/feedback.png'),
      uploadUrl:require('../../assets/imgs/icon-upload.png'),
      emptyUrl:require('../../assets/imgs/order-empty.png'),
      feedbackValue: 10,
      fileName: ''
    }
  },
  mounted() {
  },
  methods: {
    init() {
    
    },
    back() {
      this.$router.go(-1)
    },
    changeValue(val) {
      this.feedbackValue = val
    },
    addFile() {
      document.getElementById('fileInput').click()
    },
    chooseFile(e) {
      let file = e.target.files[0]
      let name = file.name
      this.fileName = name
    },
    submit() {
      this.$store.state.successMsg = this.i18n.t('feedback.success')
      setTimeout(() => {
        this.back()
      }, 1000)
    },
    toTrade() {
      this.$router.push({
        name: 'trade'
      })
    }
  }
}
</script>