<template>
	<div class="modal-page bit-page top-bar-page">
    <top-bar :title="i18n.t('bit.title')"  parentId=".bit-page"  @back="back"></top-bar>

    <div class="modal-page-main">
      <div class="sub-modal-title">{{i18n.t('bit.label1')}}</div>

      <div class="bit-list flex-wrap">
        <div class="list flex-center" v-for="(item, i) in bitList1" :key="i" :class="bitName == item.name ? 'active' : ''" @click="changeBit(item)">{{item.name}}</div>
      </div>

      <div class="sub-modal-title">{{i18n.t('bit.label2')}}</div>

      <div class="bit-list flex-wrap">
        <div class="list flex-center" v-for="(item, i) in bitList2" :key="i" :class="bitName == item.name ? 'active' : ''" @click="changeBit(item)">{{item.name}}</div>
      </div>
    </div>
	</div>
</template>
<script>
import topBar from '@/components/topBar'
import { getCoinList } from '@/api/index'
export default {
  components: {
    topBar
  },
  data() {
		return {
			backUrl: require('../../assets/imgs/icon-back.png'),
      bitList1: [
        {
          name: 'USDT',
          key: ''
        },
        {
          name: 'BTC',
          key: ''
        },
        {
          name: 'ETH',
          key: ''
        },
      ],
      bitList2: [],
      bitName: ''
		}
	},
  props: {
    activeName: String
  },
  computed: {
    bitList() {
      return this.$store.state.bitList
    }
  },
  watch: {
    activeName(val) {
      this.bitName = val
    }
  },
  mounted() {
    this.bitName = this.activeName
    this.init()
  },
	methods: {
    init() {
      if (this.bitList.length == 0) {
        getCoinList().then(res => {
          let bitListBak = res.data.Items
          this.$store.state.bitList = [].concat(bitListBak)
          this.bitList1 = this.bitList1.map(v => {
            let index = bitListBak.findIndex(val => {
              return val.Name == v.name
            })
            if (index > -1) {
              v.key = bitListBak[index].ID
            }
            return v
          })

          this.bitList2 = bitListBak.map(v => {
            return {
              key: v.ID,
              name: v.Name
            }
          }).filter(v => {
            return this.bitList1.findIndex(val => {
              return v.name == val.name
            }) === -1
          })
        })
      } else {
        this.bitList1 = this.bitList1.map(v => {
          let index = this.bitList.findIndex(val => {
            return val.Name == v.name
          })
          if (index > -1) {
            v.key = this.bitList[index].ID
          }
          return v
        })
        
        this.bitList2 = this.bitList.map(v => {
          return {
            name: v.Name,
            key: v.ID
          }
        }).filter(v => {
          return this.bitList1.findIndex(val => {
            return v.name == val.name
          }) === -1
        })
      }
    },
		back() {
			this.$emit('back')
		},
    changeBit(data) {
      this.bitName = data.name
      setTimeout(() => {
        this.$emit('change', data)
      }, 400)
    }
	}
}
</script>