<template>
  <div class="top-bar-main flex-center-between" >
    <img :src="backUrl" alt="" @click="back">

    <div class="title small flex-1">{{title}}</div>

    <div class="flex-center">
      <span v-if="rightText" @click="rightClick">{{rightText}}</span>
      <img :src="helpUrl" alt="" v-if="rightIcon == 'help' || rightIcon == 'icon'" class="big" @click="toHelp">
      <img :src="logUrl" alt="" v-if="rightIcon == 'log' || rightIcon == 'icon'" class="big" @click="toLog">
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: String,
    rightText: String,
    rightIcon: String,
    parentId: String,
  },
  data() {
    return {
      backUrl: require('../../assets/imgs/icon-back.png'),
      helpUrl: require('../../assets/imgs/icon-help.png'),
      logUrl: require('../../assets/imgs/icon-log.png'),
      animateStop: false,
      clearStyle: false
    }
  },
  computed: {
    screenScale() {
      return this.$store.state.screenScale
    }
  },
  mounted() {
  },
  methods: {
    back() {
      this.$emit('back')
    },
    rightClick() {
      this.$emit('right')
    },
    toHelp() {
      this.$router.push({
        name: 'help'
      })
    },
    toLog() {
			this.$router.push({
				name: 'assestLog',
			})
		},
  }
}
</script>