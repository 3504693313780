<template>
  <div class="setting-container  top-bar-page safe-setting-page">
    <top-bar :title="i18n.t('safeSetting.title')" class="black" parentId=".safe-setting-page"  @back="back"></top-bar>
    <div class="top-main pb-30">

      <div class="sub-title">
        {{i18n.t('safeSetting.label1')}}
      </div>
      <div class="desc-line">{{i18n.t('safeSetting.label2')}} {{loginTime}}</div>
      <div class="desc-line flex-center-start">
        <span>{{i18n.t('safeSetting.label3')}}</span>
        <img :src="phoneUrl" alt="">
        <span>{{loginDevice}}</span>
      </div>
    
    </div>

    <div class="setting-main-line flex-center-between">
      <div class="label">{{i18n.t('safeSetting.label4')}}</div>
      <div class="desc">{{email}}</div>
    </div>
    <div class="setting-main-line flex-center-between" @click="toPath('editPassword')">
      <div  class="label">{{i18n.t('safeSetting.label5')}}</div>
      <div>
        <img :src="rightUrl" alt="">
      </div>
    </div>
    <div class="setting-main-line flex-center-between" @click="toPath('editSafePassword')">
      <div  class="label">{{i18n.t('safeSetting.label6')}}</div>
      <div>
        <img :src="rightUrl" alt="">
      </div>
    </div>
  </div>
</template>
<script>
import topBar from '@/components/topBar'
import { getUser } from '@/utils/auth'
import { userInfo } from '@/api/user'
export default {
  components: {
    topBar
  },
  data() {
    return {
      email: '',
      loginDevice: '',
      loginTime: '',
      backUrl: require('../../assets/imgs/icon-back.png'),
      phoneUrl: require('../../assets/imgs/icon-phone.png'),
      rightUrl: require('../../assets/imgs/icon-arrow-right.png'),
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      userInfo().then(res => {
        this.email = res.data.Email
        this.loginDevice = res.data.LoginDevice
        this.loginTime = res.data.LoginTime
      })
    },
    back() {
      this.$router.go(-1)
    },
    toPath(path) {
      if (path) {
        this.$router.push({
          name: path
        })
      }
    }
  }
}
</script>