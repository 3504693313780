<template>
  <div class=" top-bar-page lang-page line-page">
    <top-bar :title="i18n.t('line.title')" class="black" parentId=".line-page"  @back="back"></top-bar>
    
    <div class="lang-list">
      <div class="list flex-center-between" v-for="(item, i) in lineList" :key="i" @click="changeLine(item)">
        <div>
          {{item.name}}
        </div>
        <div v-show="item.key == line">
          <img :src="checkUrl" alt="">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Button } from 'vant'
import topBar from '@/components/topBar'
export default {
  components: {
    Button,
    topBar
  },
  data() {
    return {
      line: '',
      lineList: [
        {
          name: this.i18n.t('line.tab1'),
          key: 'tab1'
        },
        {
          name: this.i18n.t('line.tab2'),
          key: 'tab2'
        },
        {
          name: this.i18n.t('line.tab3'),
          key: 'tab3'
        },
        {
          name: this.i18n.t('line.tab4'),
          key: 'tab4'
        },
        {
          name: this.i18n.t('line.tab5'),
          key: 'tab5'
        },
        {
          name: this.i18n.t('line.tab6'),
          key: 'tab6'
        },
      ],
      checkUrl: require('../../assets/imgs/icon-checked2.png')
    }
  },
  mounted() {
    this.line = localStorage.getItem('line') || 'auto'
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    changeLine(data) {
      this.line = data.key
      localStorage.setItem('line', data.key)
      localStorage.setItem('lineName', data.name)
      setTimeout(() => {
        this.back()
      }, 500)
    }
  }
}
</script>