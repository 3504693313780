<template>
  <div class="finance-detail-page">
    <div class="top-bar flex-center-start">
      <div  class="back">
        <img :src="backUrl" alt="" @click="back">
      </div>
      <div class="title flex-center-start">
        <img :src="`/upload/${detailData.Currency.Logo}`" alt="">
        <span>{{detailData.Currency.Name}}</span>
      </div>
    </div>

    <div class="info-box flex">
      <div class="flex-1 flex-column-center">
        <div class="count">{{detailData.ProfitRatio}}%</div>
        <div class="label">{{i18n.t('financeDetail.label1')}}</div>
      </div>
      <div class="flex-1 flex-column-center">
        <div class="count">{{detailData.IsRegular == 1 ? i18n.t('financeDetail.label2_1') : i18n.t('financeDetail.label2_2')}}</div>
        <div class="label">{{i18n.t('financeDetail.label2')}}</div>
      </div>
    </div>

    <div class="finance-detail">
      <div class="detail-title">{{i18n.t('financeDetail.label3')}}</div>
      <div class="input flex-center-between">
        <input type="number" inputmode="decimal" v-model="form.number" :placeholder="i18n.t('financeDetail.placeholder')">
        <div class="flex-center adot">
          <span class="yellow" @click="setAll">{{i18n.t('financeDetail.label4')}}</span>
          <span>USDT</span>
        </div>
      </div>

      <div class="detail-line flex-center-between">
        <div class="label">{{i18n.t('financeDetail.label5')}}</div>
        <div class="value">{{parseFloat(userMoney).toFixed(4)}}</div>
      </div>
      <div class="detail-line flex-center-between">
        <div class="label">{{i18n.t('financeDetail.label6')}}</div>
        <div class="value">{{parseFloat(detailData.TotalInvestment - detailData.Investment).toFixed(4)}}</div>
      </div>

      <div class="space"></div>

      <div class="detail-line flex-center-between">
        <div class="label">{{i18n.t('financeDetail.label7')}}</div>
        <div class="value">{{moment().format('YYYY-MM-DD')}}</div>
      </div>
      <div class="detail-line flex-center-between">
        <div class="label">{{i18n.t('financeDetail.label8')}}</div>
        <div class="value">{{moment().add(1, 'days').format('YYYY-MM-DD')}}</div>
      </div>
      <div class="detail-line flex-center-between">
        <div class="label">{{i18n.t('financeDetail.label9')}}</div>
        <div class="value yellow">{{i18n.t('financeDetail.label10')}}</div>
      </div>
    </div>
    <div class="warning flex-start">
      <img :src="warningUrl" alt="">
      <div>
        <span>{{i18n.t('financeDetail.desc1')}}</span>  
        <span class="yellow"> {{moment().add(1, 'days').format('YYYY-MM-DD')}} (GMT +8) </span>
        <span>{{i18n.t('financeDetail.desc2')}}</span>
      </div>
    </div>

    <div class="bottom-btn"> 
      <div class="flex-center-start role" @click="changeRole">
        <img :src="isCheck ? checkUrl : checkUrl2" alt="">
        <span>{{i18n.t('financeDetail.role')}}</span>   
        <span @click.stop="showRole">{{i18n.t('financeDetail.role2')}}</span>   
      </div>
      <Button :disabled="!isCheck" @click="submit">{{i18n.t('financeDetail.btn')}}</Button>
    </div>

    <role-page :roleData="agreementData" v-show="showRoleModal" @cancel="cancel"></role-page>
  </div>
</template>
<script>
import { Button, Form, List } from 'vant'
import rolePage from './role'
import moment from 'moment'
import { financeBuy, agreementList } from '@/api/finance'
import { wallet } from '@/api/assest'
export default {
  components: {
    Button,
    List,
    rolePage
  },
  data() {
    return {
      form: {
        number: ''
      },
      userMoney: 0,
      backUrl: require('../../assets/imgs/icon-back.png'),
      warningUrl: require('../../assets/imgs/icon-warning.png'),
      checkUrl: require('../../assets/imgs/icon-checked.png'),
      checkUrl2: require('../../assets/imgs/icon-uncheck.png'),
      detailData: {
        Currency: {}
      },
      isCheck: false,
      agreementData: {},
      showRoleModal: false
    }
  },
  mounted() {
    this.detailData = JSON.parse(sessionStorage.getItem('financeData'))
    this.init()
    this.initAgreement()
  },
  methods: {
    moment,
    init() {
      let form = new FormData()
      // form.append('CurrencyId', this.detailData.Currency.ID)
      form.append('name', "usdt")
      form.append('current', 1)
      form.append('pageSize', 10)
      wallet(form).then(res => {
        let data = res.data.Items[0]
        if (data) {
          this.userMoney = parseFloat(data.FinanceUse)
        }
      })
    },
    initAgreement() {
      let form = new FormData()
      form.append('financial_setup_id', this.detailData.ID)
      agreementList(form).then(res => {
        this.agreementData = res.data.Items[0]
      })
    },
    back() {
      this.$router.go(-1)
    },
    changeRole() {
      this.isCheck = !this.isCheck
    },
    setAll() {
      this.form.number = this.userMoney
    },
    submit() {
      if (!this.form.number) {
        this.$store.state.warningMsg = this.i18n.t('financeDetail.placeholder')
        return
      }
      let form = new FormData()
      form.append('Amount', this.form.number)
      form.append('FinancialSetupId', this.detailData.ID)
      financeBuy(form).then(res => {
        if (res.code == 0) {
          this.$store.state.successMsg = this.i18n.t('financeDetail.success')
          setTimeout(() => {
            this.back()
          }, 1500)
        } else {
          this.$store.state.errorMsg = res.msg
        }
      })
    },
    showRole() {
      this.showRoleModal = true
    },
    cancel() {
      this.showRoleModal = false
    }
  }
}
</script>