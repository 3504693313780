<template>
  <div class="setting-container top-bar-page common-setting-page">
    <top-bar :title="i18n.t('commonSetting.title')" class="black" parentId=".common-setting-page"  @back="back"></top-bar>
 
    <div class="setting-main-line flex-center-between" v-show="isLogin">
      <div class="label">UID</div>
      <div class="desc flex-center">
        <span>{{userId}}</span>
        <img :src="copyUrl" alt="" @click="copyData" class="copy-icon">
      </div>
    </div>
    <div class="setting-main-line flex-center-between" @click="toPath('lang')">
      <div  class="label">{{i18n.t('commonSetting.label1')}}</div>
      <div class="desc flex-center">
        <span>{{langName}}</span>
        <img :src="rightUrl" alt="">
      </div>
    </div>
    <div class="setting-main-line flex-center-between clear" @click="changeTheme" v-show="false">
      <div  class="label">{{i18n.t('commonSetting.label2')}}</div>
      <div class="desc flex-center">
        <span>{{themeName}}</span>
        <img :src="rightUrl" alt="">
      </div>
    </div>
    <!-- <div class="setting-main-line flex-center-between clear" @click="changeBit">
      <div  class="label">{{i18n.t('commonSetting.label3')}}</div>
      <div class="desc flex-center">
        <span>{{bitName}}</span>
        <img :src="rightUrl" alt="">
      </div>
    </div> -->
    <div class="setting-main-line flex-center-between clear" @click="toPath('line')">
      <div  class="label">{{i18n.t('commonSetting.label4')}}</div>
      <div class="desc flex-center">
        <span>{{lineTypeName}}</span>
        <img :src="rightUrl" alt="">
      </div>
    </div>

    <div class="setting-main-line flex-center-between " v-show="isLogin">
      <div  class="label">{{i18n.t('commonSetting.label5')}}</div>
    </div>
    <div class="radio-list" v-show="isLogin">
      <div class="radio flex-center-start" v-for="(item, i) in radioList" :key="i" @click="changeRechargeType(item)">
        <img :src="rechargeType == item.value ? radioUrl2 : radioUrl" alt="">
        <span>{{item.name}}</span>
      </div>
    </div>

     <Popup v-model:show="showPopup" position="bottom" lock-scroll class="rate-popup">
			<div class="rate-modal">
				<div class="title flex-center-between " >
					<div>{{i18n.t('commonSetting.label6')}}</div>
          <img :src="closeUrl" alt="" @click="cancel">
				</div>

        <div class="auth-list">
          <div class="flex-center-between">
            <div class="auth" @click="chooseTheme(1)">
              <div class="bg big flex-center" :class="themeIndex == 1 ? 'active' : ''">
                <img :src="themeUrl" alt="">
              </div>
              <div class="label" :class="themeIndex == 1 ? 'active' : ''">
                <span>{{i18n.t('commonSetting.label7')}}</span>
                <span class="" v-show="themeIndex == 1">({{i18n.t('commonSetting.label9')}})</span>
              </div>
            </div>
            <div class="auth" @click="chooseTheme(2)">
              <div class="bg big flex-center" :class="themeIndex == 2 ? 'active' : ''">
                <img :src="themeUrl2" alt="">
              </div>
              <div class="label" :class="themeIndex == 2 ? 'active' : ''">
                <span>{{i18n.t('commonSetting.label8')}}</span>
                <span class="" v-show="themeIndex == 2">({{i18n.t('commonSetting.label9')}})</span>
              </div>
            </div>
          </div>
        </div>

			</div>
		</Popup>

    <!--选择币种-->
    <bitPage v-show="showBit" @back="cancel" :activeName="bitName" @change="chooseBit"></bitPage>

  </div>
</template>
<script>
import topBar from '@/components/topBar'
import { getUserId, getRechargeType, getToken } from '@/utils/auth'
import { saveAssets } from '@/api/setting'
import Clipboard from 'clipboard';
import { Popup, Button } from 'vant'
import bitPage from '../assest/bit'
export default {
  components: {
    Popup,
    Button,
    topBar,
    bitPage
  },
  data() {
    return {
      lang: '',
      langName: '',
      theme: '',
      themeName: '',
      bitName: '',
      lineType: '',
      lineTypeName: '',
      rechargeType: 'spot',
      backUrl: require('../../assets/imgs/icon-back.png'),
      phoneUrl: require('../../assets/imgs/icon-phone.png'),
      rightUrl: require('../../assets/imgs/icon-arrow-right.png'),
      copyUrl: require('../../assets/imgs/icon-copy.png'),
      closeUrl: require('../../assets/imgs/icon-close.png'),
      radioUrl: require('../../assets/imgs/icon-radio.png'),
      radioUrl2: require('../../assets/imgs/icon-radio2.png'),
      themeUrl: require('../../assets/imgs/theme1.png'),
      themeUrl2: require('../../assets/imgs/theme2.png'),
      radioList: [
        {
          name: this.i18n.t('commonSetting.account1'),
          value: 'spot'
        },
        {
          name: this.i18n.t('commonSetting.account2'),
          value: 'option'
        },
        {
          name: this.i18n.t('commonSetting.account3'),
          value: 'contract'
        },
        {
         name: this.i18n.t('commonSetting.account4'),
          value: 'finance'
        }
      ],
      showPopup: false,
      themeIndex: 1,
      showBit: false,
      isLogin: false
    }
  },
  computed: {
    userId() {
      return getUserId()
    }
  },
  mounted() {
    this.lang = localStorage.getItem('locale') || 'zh-CN'
    this.langName = localStorage.getItem('localeName') || this.i18n.t('commonSetting.label10')

    this.theme = localStorage.getItem('themeType') || '1'
    this.themeName = this.theme == '1' ? this.i18n.t('commonSetting.label7') : this.i18n.t('commonSetting.label8')
    this.themeIndex = this.theme

    this.lineType = localStorage.getItem('line') || 'auto'
    this.lineTypeName = this.i18n.t(`line.${localStorage.getItem('line')}`) || this.i18n.t('commonSetting.label11')

    this.bitName = localStorage.getItem('bitName') || 'USDT'

    this.rechargeType = getRechargeType() || 'spot'

    this.isLogin = getToken() ? true : false
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    toPath(path) {
      if (path) {
        this.$router.push({
          name: path
        })
      }
    },
    changeRechargeType(data) {
      this.rechargeType = data.value
      let form = new FormData()
      form.append('Assets', data.value)
      saveAssets(form).then(res => {
        if (res.code == 0) {
          this.$store.state.successMsg = this.i18n.t('common.success')
        } else {  
          this.$store.state.errorMsg = res.msg
        }
      })
    },
    copyData() {
      let data = this.userId
      const clipboard = new Clipboard('.copy-icon', {text: () => data});
      this.$store.state.successMsg = this.i18n.t('commonSetting.copy')
    },
    changeTheme() {
      this.showPopup = true
    },
    cancel() {
      this.showPopup = false
      this.showBit = false
    },
    chooseTheme(type) {
      this.themeIndex = type
      this.showPopup = false
      localStorage.setItem('themeType', type)
      this.theme = type
      this.themeName = this.theme == '1' ? this.i18n.t('commonSetting.label7') : this.i18n.t('commonSetting.label8')
    },
    changeBit() {
      this.showBit = true
    },
    chooseBit(data) {
      this.bitName = data.name
      localStorage.setItem('bitName', data.name)
      localStorage.setItem('bitId', data.key)
			this.showBit = false
    }
  }
}
</script>