<template>
  <div class="top-bar-page auth-page">
    <top-bar :title="pageType == 1 ? i18n.t('userAuth.title1') : i18n.t('userAuth.title2')" class="black" parentId=".auth-page"  @back="back"></top-bar>

    <div class="auth-main">
      <div class="auth-form">
        <div class="form-item">
          <div class="label">{{i18n.t('userAuth.label1')}}</div>
          <div class="input">
            <input type="text" :placeholder="i18n.t('userAuth.placeholder1')" v-model="form.username"> 
          </div>
        </div>
        <div class="form-item" @click="showCountry">
          <div class="label">{{i18n.t('userAuth.label2')}}</div>
          <div class="input empty flex-center-between" :class="form.countryName ? 'active' : ''">
            <span>{{form.countryName || i18n.t('userAuth.placeholder2')}}</span>
            <img :src="rightUrl" alt="">
          </div>
        </div>
        <div class="form-item">
          <div class="label">{{i18n.t('userAuth.label3')}}</div>
          <div class="input">
            <input type="tel" :placeholder="i18n.t('userAuth.placeholder3')" v-model="form.cardNo">
          </div>
        </div>

        <div class="form-item">
          <div class="label">{{i18n.t('userAuth.label4')}}</div>
          <div  class="file-box flex-center-between">
            <div class="auth">
              <div class="bg"  >
                <img :src="form.card1 ? `/upload/tmp/${form.card1}` : cardUrl" alt="" @click.stop="chooseFile(1)">
                <input type="file" class="hide-box" id="fileInput" @change="changeFile" accept="image/*"> 
              </div>
              <div class="text" :class="form.card1 ? 'active' : ''">
                {{pageType == 1 ? i18n.t('userAuth.label5') : i18n.t('userAuth.label6')}}
                <span v-if="form.card1">({{i18n.t('userAuth.label9')}})</span>
              </div>
            </div>

            <div class="auth" >
              <div class="bg">
                <img :src="form.card2 ? `/upload/tmp/${form.card2}` : cardUrl2" alt="" @click.stop="chooseFile(2)">
              </div>
              <div class="text" :class="form.card2 ? 'active' : ''">
                {{pageType == 1 ? i18n.t('userAuth.label7') : i18n.t('userAuth.label8')}}
                <span v-if="form.card2">({{i18n.t('userAuth.label9')}})</span>
              </div>
            </div>
          </div>
        </div>

        <div class="auth-desc">
          <div class="desc-title">{{i18n.t('userAuth.desc1')}}</div>
          <div class="info">{{i18n.t('userAuth.desc2')}}</div>
        </div>

        <div>
          <Button @click="submit">{{i18n.t('userAuth.btn')}}</Button>
        </div>
      </div>
    </div>

    <Popup v-model:show="showPopup" position="bottom">
			<div class="country-list" >
				<div class="title flex-center-start">
          <img :src="backUrl" alt="" @click="cancel">
          <span>{{i18n.t('userAuth.placeholder2')}}</span>
        </div>
        <div class="group-list" v-for="(item, i) in countryList" :key="i">
          <div class="country-title" :id="item.id">{{item.title}}</div>
          <div class="list flex-center-start" v-for="(child, index) in item.children" :key="index" @click.stop="chooseCountry(child)">
            <div class="img-box flex-center">
              <img :src="child.icon" alt="">
            </div>
            <span>{{getCountryName(child.title)}}</span>
          </div>
        </div>
        <div class="fast-link">
          <div class="link" v-for="(item, i) in linkList" :key="i" @click="fastJump(item)">
            {{item}}
          </div>
        </div>
			</div>
    </Popup>
  </div>
</template>
<script>
import topBar from '@/components/topBar'
import { uploadFile, userIdentity } from '@/api/setting'
import { Button, Popup } from 'vant'
export default {
  components: {
    Button,
    Popup,
    topBar
  },
  data() {
    return {
      form: {
        username: '',
        cardNo: '',
        country: '',
        countryName: '',
        card1: '',
        card2: ''
      },
      pageType: '',
      backUrl:require('../../assets/imgs/icon-back.png'),
      rightUrl:require('../../assets/imgs/icon-arrow-right.png'),
      cardUrl: require('../../assets/imgs/auth-card.png'),
      cardUrl2: require('../../assets/imgs/auth-card2.png'),
      hzUrl: require('../../assets/imgs/auth-hz.png'),
      showPopup: false,
      fileType: '',
      linkList: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V','W', 'Y', 'Z'],
    
    }
  },
  computed: {
    countryList() {
			return this.$store.state.countryList
    }
  },
  mounted() {
    this.pageType = this.$route.query.type || 1
  },
  methods: {
    init() {
    
    },
    back() {
      this.$router.go(-1)
    },
    showCountry() {
      this.showPopup = true
    },
    cancel() {
      this.showPopup = false
    },
    changeCountry(data) {
      this.form.country = data.value
      this.form.countryName = data.name
      this.showPopup = false
    },
    chooseFile(type) {
      this.fileType = type
      document.getElementById('fileInput').value = ''
      document.getElementById('fileInput').click()
      console.log(222, type, this.fileType)
    },
    changeFile(e) {
      let file = e.target.files[0]
      let form = new FormData()
      form.append('file', file)
      uploadFile(form).then(res => {
        if (res.code == 0) {
          this.$store.state.successMsg = this.i18n.t('userAuth.success1')
          this.form[`card${this.fileType}`] = res.data
        } else {
          this.$store.state.errorMsg = res.msg
        }
      })
    },
    submit() {
      if (!this.form.username) {
        this.$store.state.warningMsg = this.this.i18n.t('userAuth.placeholder1')
        return
      }
      if (!this.form.country) {
        this.$store.state.warningMsg = this.this.i18n.t('userAuth.placeholder2')
        return
      }
      if (!this.form.cardNo) {
        this.$store.state.warningMsg = this.this.i18n.t('userAuth.placeholder3')
        return
      }
      if (!this.form.card1 || !this.form.card2) {
        this.$store.state.warningMsg = this.this.i18n.t('userAuth.placeholder4')
        return
      }
      let form = new FormData()
      form.append('RealName', this.form.username)
      form.append('Country', this.form.countryName)
      form.append('CardNo', this.form.cardNo)
      form.append('CardFront', this.form.card1)
      form.append('CardBack', this.form.card2)
      form.append('CardType', this.pageType)
      userIdentity(form).then(res => {
        if (res.code == 0) {
          this.$store.state.successMsg = this.i18n.t('userAuth.success2')
          setTimeout(() => {
            this.back()
          }, 500)
        } else {
          this.$store.state.errorMsg = res.msg
        }
      })
    },
    fastJump(id) {
      let top = document.getElementById(id).offsetTop
      document.querySelector('.country-list').scrollTo({
        top: top - 45
      })
    },
    chooseCountry(data) {
      this.form.country = data.key
      this.form.countryName = this.getCountryName(data.title)
      this.showPopup = false
    },
    getCountryName(name) {
      let newName = ''
      newName = name.substring(0, name.indexOf('(') - 1)
      return newName
    }
  }
}
</script>